@import '../../../../CSS/variables.module';

.session-thumbnail-image {
    .image-container {
        width: 100%;
        max-width: 450px;
        height: 300px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        label {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            cursor: pointer;
            flex-flow: column;
            span {
                padding-top: 16px;
            }
        }
    }
}
