@import 'variables.module';

#my-account-mobile {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f5f5;
    flex: 1;
    padding-bottom: 56px;
    display: block;
}
#my-account-mobile .tabs-container {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}
#my-account-mobile .tabs-container > div {
    display: flex;
    align-items: center;
}
#my-account-mobile .tabs-container .tab {
    width: 50%;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
}
#my-account-mobile .tabs-container .tab.active {
    border-bottom: 2px solid $secondary;
}
#my-account-mobile .tabs-container h3 {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
}
#my-account-mobile .tabs-container .tab.active h3 {
    color: $secondary;
    line-height: 30px;
}
#my-account-mobile .edit-section-container {
    box-shadow: none;
    max-width: initial;
    border: 0;
    border-radius: 0;
    margin: 2px 0 0;
    padding: 0;
    background-color: transparent;
    height: calc(100% - 50px);
}
.profile-list {
    background-color: #ffffff;
}
.profile-list > div {
    display: flex;
    border-bottom: 1px solid $disabled-text;
    padding: 7px 16px;
    justify-content: space-between;
    align-items: center;
}
.profile-list > div:first-of-type {
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
}
.profile-list .user-details {
    flex: 1;
}
.profile-list .avatar,
.edit-user-information .form-wrapper .avatar {
    margin-right: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.profile-list h3 {
    font-size: 16px;
    color: $dark;
}
.profile-list .user-details p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}
.profile-list .user-details p span {
    color: $text-grey;
}
.profile-list button {
    background-color: transparent;
    border: 0;
    outline: 0;
}
.profile-list button img {
    width: 18px;
    margin-right: 0;
}
.profile-list h4 {
    text-transform: uppercase;
    margin: 0;
    padding: 13px 0;
    font-weight: 600;
}
.profile-list .selected-language {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
}
.profile-list .selected-language span {
    font-size: 16px;
    font-weight: 600;
    font-family: $fontFamily, sans-serif;
    color: $text-grey;
    text-transform: uppercase;
    padding-right: 10px;
}
.profile-list .select-language-container {
    margin: 0;
    width: 100%;
    position: relative;
}
.profile-list .languages-list-container {
    position: absolute;
    top: 40px;
    right: -14.5px;
    width: 119px;
    border: 1px solid $disabled-text;
    border-radius: 6px;
    box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
    background: $dark;
    color: #fff;
}
#select-languages-dialog .MuiDialogContent-root {
    padding: 0;
}
#select-languages-dialog ul {
    margin: 0;
    list-style: none;
    padding: 0 !important;
}
#select-languages-dialog li {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}
#select-languages-dialog li.active {
    background: $primary;
    color: #fff;
}
#my-account-mobile .gdpr-links {
    margin-top: 32px;
    padding: 0 16px;
}
#my-account-mobile .gdpr-links p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}
#my-account-mobile .gdpr-links ul {
    padding-top: 10px;
}
#my-account-mobile .gdpr-links a {
    font-size: 16px;
    font-weight: 600;
    color: $secondary;
    text-decoration: underline;
}
.dialog-mobile #form-dialog-title h2 {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    text-transform: initial;
}
.dialog-mobile p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    font-family: $fontFamily, sans-serif;
}
.dialog-mobile button {
    font-size: 16px;
    font-weight: bold;
    color: $dark;
}
.dialog-mobile button.MuiButton-containedPrimary,
.dialog-mobile button.MuiButton-containedSecondary {
    background-color: transparent;
    box-shadow: none;
    color: $primary;
}
#my-account-mobile .edit-user-information {
    position: absolute;
    top: 105px;
    height: calc(100% - 160px);
    background-color: #ffffff;
    width: 100%;
    &.smart-matching-container {
        min-height: max-content;
        .smart-matching {
            text-align: center;
            background-color: $white;
            .matching-loading {
                margin: 0 auto;
            }
            .page-title {
                background: $primary;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 8px;
                padding-bottom: 8px;
                .cancel-button {
                    position: relative;
                    top: 2px;
                    margin: 0 14px 0 0;
                }
                svg {
                    fill: $secondary;
                }
                p {
                    color: $secondary;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        .content-wrapper {
            display: flex;
            flex-direction: column;
            padding-top: 60px !important;
            // Tablet
            @media all and (max-width: 1024px) and (max-height: 1366px) {
                background-color: $white;
            }
            .reject-participation-btn {
                min-height: max-content;
                position: relative;
                align-self: flex-end;
                margin: 12px;
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    position: relative;
                    align-self: center;
                    margin: 12px 0 0;
                }
            }
            .matching-questions-container {
                min-width: 485px;
                min-height: max-content;
                height: max-content;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 12px;
                // Tablet
                @media all and (max-width: 1024px) and (max-height: 1366px) {
                    padding: 12px 86px;
                    align-items: center;
                    background-color: $white;
                }
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    padding: 16px;
                    min-width: 100%;
                }
                .MuiDivider-root {
                    min-height: max-content;
                    width: 100%;
                    margin: 0 0 24px;
                    background-color: $disabled-text;
                }
                .question-wrapper {
                    width: 100%;
                    .MuiFormControlLabel-root {
                        margin-left: -4px;
                        margin-right: 0;
                        margin-bottom: 10px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                        &[data-checked="true"] {
                            &:before,
                            &:after {
                                display: none;
                            }
                        }
                        & > span {
                            &:last-of-type {
                                margin-inline-start: 10px;
                                font-family: $fontFamily, sans-serif;
                            }
                        }
                    }
                }
                .question:last-child {
                    margin: 0;
                }
                .question {
                    width: 100%;
                    min-width: 100%;
                    min-height: max-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 0 0 24px;
                    .question-requirement {
                        min-height: max-content;
                        height: max-content;
                        margin: 0;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                        color: $grey-dark;
                    }
                    .question-title {
                        text-align: left;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        letter-spacing: 0.0015em;
                        color: $dark;
                        padding: 0 0 16px;
                    }
                    section {
                        width: 100%;
                        min-width: max-content;
                        min-height: max-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .form-control-outlined {
                            padding: 8px 20px;
                            border: 2px solid $primary;
                            border-radius: 4px;
                            legend {
                                width: max-content;
                                font-family: $fontFamily, sans-serif;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: -0.4px;
                                color: $primary;
                                padding: 0 4px;
                                text-align: left;
                            }
                            .MuiFormControlLabel-root {
                                margin-left: -4px;
                                margin-right: 0;
                            }
                        }
                    }
                }
                .matching-required-error {
                    width: max-content;
                    height: max-content;
                    min-width: max-content;
                    min-height: max-content;
                    margin: 0 auto;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: $error;
                }
                .matching-required-error.isiOS {
                    margin: 0 auto 70px;
                }
            }
        }
    }
}
#my-account-mobile .edit-user-information > div:first-of-type {
    position: relative;
    height: 100%;
    background-color: #f5f5f5;
    display: flex;
}
.MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
    form {
        padding-bottom: 0;
        .validator-input {
            div:last-of-type {
                margin-bottom: 8px;
            }
        }
    }
}
#my-account-mobile .edit-user-information .content-wrapper {
    padding-top: 68px;
    padding-bottom: 82px;
    flex: 1;
    overflow-y: auto;
}
#my-account-mobile .edit-user-information .page-title {
    background: $primary;
    color: #ffffff;
    padding: 15px 16px;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
}
#my-account-mobile .edit-user-information .page-title p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        color: $secondary;
    }
}
#my-account-mobile .edit-user-information .buttons-actions {
    position: absolute;
    bottom: 44px;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
    filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.2));
}
#my-account-mobile .edit-user-information .form-wrapper {
    margin: 12px 10px;
    padding: 0 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    .field-container {
        margin: 0;
         & > div {
             margin-bottom: 16px;
             &.Mui-error {
                 margin-bottom: 0;
             }
         }
    }
    .sdg-label {
        margin-bottom: 16px;
        margin-top: 8px;
    }
    .sdgs-wrapper,
    .chat-preference-wrapper {
        fieldset {
            legend {
                font-weight: 500;
                color: $grey-dark;
            }
        }
    }
}
.edit-user-information .content-wrapper .form-wrapper {
    form {
        padding-bottom: 0;
        .validator-input {
            div:last-of-type {
                margin-bottom: 8px;
            }
        }
    }
}
.edit-user-information .form-wrapper .image-container {
    display: flex;
    padding-top: 16px;

    .avatar {
        width: 80px;
        height: 80px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }
}
.edit-user-information .form-wrapper .upload-new-image {
    flex: 1;
    justify-content: space-around;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}
.edit-user-information .form-wrapper .upload-new-image .select-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    width: 100%;
    z-index: 2;
}
.edit-user-information .form-wrapper .upload-container {
    position: relative;
}
.upload-new-image span,
.upload-new-image button span {
    font-size: 16px;
    font-weight: bold;
    color: $dark;
    text-transform: uppercase;
}
.upload-new-image button {
    background: transparent;
    border: 0;
    padding: 0;
    font-family: $fontFamily, sans-serif;
    outline: 0;
}
.upload-new-image button:disabled span {
    color: $text-grey;
}
#my-account-mobile .edit-user-information input {
    color: $dark !important;
}
#my-account-mobile .edit-user-information label.Mui-focused {
    color: $secondary;
}
#my-account-mobile .edit-user-information div.Mui-focused fieldset {
    border-color: $secondary;
    border-width: 1px;
}
#my-account-mobile .edit-user-information {
    .registration-radio-group {
        border-radius: 3px;
        .MuiFormControlLabel-root {
             & > span {
                &:first-of-type {
                    margin-inline-end: 12px;
                }
            }
        }
    }
    fieldset {
        border-color: rgba(0, 0, 0, 0.23);
    }
    .select-validator {
        label {
            -webkit-padding-end: 40px;
            padding-inline-end: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 95%;
            color: $grey-dark;
            font-family: 'Avenir', sans-serif;
            opacity: 1;
            top: 0;
            transform: translate(12px, 20px) scale(1);
            &.filled {
                transform: translate(12px, 10px) scale(0.75);
            }
        }
        .field-container {
            margin: 0;
            & > div {
                background-color: $white;
                margin-bottom: 26px;
                border-radius: 3px;
                &:before {
                    display: none;
                }
                .MuiSelect-selectMenu {
                    border: 1px solid $grey;
                    border-radius: 3px;
                }
            }
        }
    }
}
#my-account-mobile .edit-participant-info-container {
    background-color: #ffffff;
    padding: 20px 16px;
    position: relative;
}
#my-account-mobile .edit-participant-info-container button {
    background: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 16px;
}
#my-account-mobile ul {
    padding-inline-end: 40px;
    li {
        &.sdg-field,
        &.chat-preference-field {
            span {
                font-size: 14px;
                &:first-of-type {
                    font-weight: 500;
                    color: $text-grey;
                }
            }
        }
    }
    p {
        font-weight: 500;
    }
}
#my-account-mobile {
    .smart-matching-button {
        border-bottom: none !important;
    }
    .change-password-btn,
    .smart-matching-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 16px;
        background-color: #ffffff;
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        border-top: 1px solid rgba(137, 137, 137, 0.5);
        h4 {
            margin: 0;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
        }
    }
}
#my-account-mobile .edit-user-information form {
    margin-top: 16px;
    padding-top: 16px;
}
#my-account-mobile .edit-user-information form > div:last-of-type {
    margin-bottom: 0;
}
#my-account-mobile .edit-user-information.small-padding-top .content-wrapper {
    padding-top: 50px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    #my-account-mobile .tabs-container > div {
        justify-content: center;
    }
    #my-account-mobile .tabs-container .tab {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
    #my-account-mobile .edit-user-information .form-wrapper {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .profile-list > div:first-of-type {
        cursor: initial;
    }
    .profile-list > div,
    .profile-list button {
        cursor: pointer;
    }
}
.word-breaker {
    word-break: break-all;
}
