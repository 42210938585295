@import '../../../CSS/variables.module';
.manage-notifications-container {
    max-width: 966px;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: flex-start;

    h3 {
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .notifications-container {
        box-sizing: border-box;
        width: 100%;
        background: #ffffff;
        border: 1px solid #cacbd0;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 16px;

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.0015em;
            margin: 0;
            padding: 0;
            margin-bottom: 16.5px;
        }
        .notification-list {
            padding: 17px;
            margin-bottom: 33px;
            border: 2px solid #000;
            border-radius: 5px;
            max-width: 485px;
            position: relative;

            h5.list-title {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: #000000;
                position: absolute;
                top: -34px;
                left: 11px;
                background: white;
                display: block;
                padding: 5px;
            }
            ul.checkbox-list {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 12px;
                li {
                    margin-left: 12px;

                    .MuiFormControlLabel-label.Mui-disabled {
                        color: #000;
                    }
                    .MuiFormControlLabel-root {
                        margin-right: 0;
                        line-height: 18px;
                        letter-spacing: -0.03em;
                        .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
                            line-height: 18px;
                            letter-spacing: -0.03em;
                            color: #000;
                        }
                    }
                    .MuiCheckbox-root {
                        width: 18px;
                        height: 18px;
                        margin-inline-end: 12px !important;
                        svg {
                            fill: $secondary;
                        }
                        &.Mui-checked {
                            background-color: $primary;
                            border: 1px solid $secondary;
                            svg {
                                fill: $secondary;
                            }
                            &.MuiCheckbox-colorSecondary {
                                &:hover {
                                    background-color: $primary;
                                }
                            }
                            &:hover {
                                background-color: $primary;
                            }
                        }
                        &.Mui-disabled {
                            background-color: $white;
                            border: 1px solid $grey-dark;
                            svg {
                                fill: $grey-dark;
                            }
                            &.MuiCheckbox-colorSecondary {
                                &:hover {
                                    background-color: $grey-dark;
                                }
                            }
                            &:hover {
                                background-color: $grey-dark;
                            }
                        }
                    }
                }
            }
        }
    }
}

.popper-helper {
    margin-left: -240px;
    width: 274px !important;
    font-style: normal !important;
    font-weight: 500 !important;

    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltipArrow,
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltipArrow {
        border: 1px solid #cacbd0 !important;
        border-radius: 12px !important;
        background-position: 10px 10px !important;
        padding: 16px 16px 16px 48px;
        background-position: 16px 16px !important;
        span.MuiTooltip-arrow {
            left: 237.9px !important;
        }
    }
}
.popper-helper.ar-helper {
    margin-left: 188px;
}

.MuiSnackbarContent-message {
    margin: auto !important;
}

.text-align-right {
    text-align: right !important;
}
.margin-left-auto {
    margin-left: auto !important;
}
.list-title-ar-helper {
    left: auto !important;
    right: 11px !important;
}
ul.checkbox-list.ar-helper {
    li {
        label.MuiFormControlLabel-root {
            display: flex;
            justify-content: flex-end;
            // margin-inline-end: 0 !important;
            .MuiCheckbox-root {
                order: 1;
                margin-inline-start: 12px !important;
            }
        }
    }
}
.manage-notifications-container
    .notifications-container
    .notification-list
    ul.checkbox-list.ar-helper
    li
    .MuiCheckbox-root {
    margin-inline-end: 0 !important;
}
.restyled-save-button.button-save.ar-helper {
    margin-left: auto;
    display: block;
}

//mobile
@media only screen and (max-width: 1025px) {
    .manage-notifications-container {
        width: 100vw;
        position: absolute;
        top: 104px;
        height: calc(100vh - 104px);
        background-color: white;
        .MuiSnackbar-root {
            left: 8px;
            right: 8px;
            display: flex;
            z-index: 1400;
            position: fixed;
            align-items: center;
            justify-content: center;
            z-index: 9999;
            bottom: 100px !important;
            width: fit-content;
            margin: auto;
        }
    }
    .popper-helper {
        margin-left: -14px;
        top: -16px !important;
        left: -3px !important;
        .popper-helper .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltipArrow span.MuiTooltip-arrow,
        .popper-helper
            .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltipArrow
            span.MuiTooltip-arrow {
            left: 14px !important;
        }
    }
    .popper-helper .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltipArrow span.MuiTooltip-arrow,
    .popper-helper .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltipArrow span.MuiTooltip-arrow {
        left: 14px !important;
    }
    .manage-notifications-container .notifications-container {
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 26px 19px;
    }

    .mobile-headline-manage-notifications {
        position: absolute;
        z-index: 999999999;
        top: 56px;
        background: #fbd207;
        height: 48px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .mobile-button-set-notifications {
        position: fixed;
        bottom: 0;
        z-index: 2000;
        height: 56px;
        -webkit-filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.12))
            drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.07)) drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.12))
            drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.2));
        width: 100%;
        background-color: #ffffff;
        display: flex;
        left: 0;
        border: 0;
        justify-content: end;
        padding-left: 24px;
        padding-right: 24px;
    }
    .MuiButtonBase-root.MuiButton-root.restyled-save-button.button-save.mobile-helper.MuiButton-outlined:focus:active:focus-visible:hover {
        background-color: transparent !important;
    }
    .MuiButtonBase-root.MuiButton-root.restyled-save-button.button-save.mobile-helper.MuiButton-outlined {
        padding: 0 !important;
        border: 0 !important;
        color: #000 !important;
        background-color: transparent;
        width: unset;
        min-width: auto;
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.0125em;
            text-transform: uppercase;
        }
    }
    .mr-32 {
        margin-right: 32px !important;
    }
    .cancel-notifications{position: absolute;left: 27px;}
}
