@import 'variables.module';

.live-session-dashboard-page {
    min-height: calc(100vh - 70px);
    position: relative;
    padding: 16px calc(323px + 16px) 16px 16px;
    background-color: $white-variant;
    .live-session-dashboard-container {
        display: flex;
        margin: 0 -8px;
        .column {
            padding: 0 8px;
            flex: 0 0 28.5%;
            width: 28.5%;
            &.live-session-video-streaming-container {
                flex: 0 0 43%;
                width: 43%;
                display: flex;
                flex-flow: column;
            }
        }
    }

    .session-settings {
        flex-grow: 1;
        background-color: $white;
        .can-request-microphone-switch{
            margin-right: -12px;
        }
    }

    .aspect-ratio-wrapper {
        width: 100%;
        padding-top: 48.5%; /* 2:1 Aspect Ratio */
        position: relative;
        .aspect-ratio-container {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .video-player-container {
        display: flex;
        align-items: center;
        position: relative;
        background: #000;
        height: 100%;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        .is-live-info {
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 0.7);
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            .red-circle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $red;
                animation: recording-animation 2s infinite;
            }
            p {
                color: $red;
                margin: 0;
                padding: 0;
                padding-left: 12px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
            }
        }
        .session-user-playback {
            position: relative;
            &.is-screen-sharing {
                width: 100% !important;
                height: 100% !important;
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
            }
            video {
                width: 100%;
                height: 100%;
            }
            .session-video-avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60px;
                height: 60px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                background-color: $primary;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
        }
    }

    .personal-stream-controls {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        button {
            color: #fff;
            padding: 0;
            &.is-sharing {
                color: $primary;
            }
            svg {
                width: 40px;
                height: 40px;
            }
            span {
                display: none;
            }
        }
    }

    .timeslot-stream-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #211e16;
            &.stop {
                color: $secondary;
            }
            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 1.2;
                padding-left: 10px;
            }
        }
    }
    .module .tab {
        width: 50%;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;
        height: 100%;
        padding: 0 35px;
        position: relative;
        color: $black;
        transition: all 0.3s ease-out;

        &.-active {
            background-color: $primary;

            &:after {
                opacity: 1;
            }
        }
    }
    .live-session-users {
        .tab {
            .unapproved-users {
                background-color: $secondary;
                color: white;
                border-radius: 50%;
                flex: 0 0 17px;
                width: 17px;
                height: 17px;
                line-height: 17px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-inline-start: 5px;
            }
        }
        .live-session-users-table {
            height: calc(100vh - 149px);
            background-color: #fff;
            .scrollbar .box {
                padding: 0 !important;
                margin-top: 0 !important;
            }
            .MuiAccordion-root {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fafafa 100%);
                border-bottom: 1px solid $disabled-text;
            }
            .MuiAccordionSummary-root {
                min-height: 44px;
                padding: 0 18px 0 12px;
                &.no-user-details {
                    cursor: initial;
                    .MuiButtonBase-root {
                        cursor: initial;
                    }
                }
            }

            .MuiIconButton-root {
                padding: 10px;
            }
            .MuiAccordion-root.Mui-expanded {
                margin: 0;
            }
            .MuiAccordionSummary-content {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }
                &.Mui-expanded {
                    margin: 12px 0;
                }
            }
            .MuiAccordionDetails-root {
                padding: 0 13px 10px 16px;
                display: flex;
                justify-content: space-between;
            }
            .MuiPaper-elevation1 {
                box-shadow: none;
            }
            .permission-actions-container {
                display: flex;
                button {
                    padding: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.2;
                    color: #777081;
                    span {
                        padding-left: 5px;
                    }
                    &.admit {
                        color: $primary;
                        margin-right: 16px;
                    }
                }
            }
            .switches-container {
                display: flex;
            }
            .single-action-container {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
            }
            .action-label {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $grey-dark;
                text-transform: uppercase;
            }
            button.kick {
                display: flex;
                flex-flow: column;
                color: $red;
                padding: 0;
                span {
                    color: $grey-dark;
                }
                svg {
                    min-height: 38px;
                }
            }
        }
        .no-users {
            padding: 16px;
            font-weight: 600;
        }
    }
    .MuiSwitch-root.is-sharing {
        .MuiIconButton-root {
            color: #ca2f25;
        }
        .MuiSwitch-track {
            background-color: #ca2f25;
        }
    }
    .module {
        display: flex;
        flex-direction: column;
        width: 100%;
        .module-container {
            border-radius: 8px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .module-header {
            display: flex;
            align-items: center;
            height: 48px;
            background-color: #f5f5f5;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
            border-bottom: 1px solid #cacbd0;
            span {
                padding: 0 8px;
            }
        }
        .module-body {
            background-color: $white;
        }
    }
    .module-link_block {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        color: #211e16;
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 55px;
        justify-content: space-between;
        font-family: $fontFamily, sans-serif;
        border-bottom: 1px solid #e6e6e6;
        button {
            span {
                padding-left: 10px;
                font-family: $fontFamily, sans-serif;
                font-weight: bold;
            }
        }
    }
    .button {
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: #211e16;
        outline-color: rgba(33, 30, 22, 0.2);
        background-color: transparent;
        padding: 10px 0;
        border: none;
        display: flex;
        align-items: center;
        &:disabled {
            color: #c0c0c0;
            cursor: initial;
        }
    }

    .messages-container {
        height: calc(100vh - 208px);
        min-height: 250px;
    }
    .-chat {
        .module-body {
            padding: 0 0 12px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
        }
        .chat-container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-bottom: 10px;
        }
        .message-input {
            height: initial;
            background: $white;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            position: relative;
            height: auto;
            min-height: 43px;
            display: flex;
            max-width: 100%;
            margin: 0;
            padding: 0;
            margin: 0 8px;
            align-items: center;
            input {
                height: 24px;
                line-height: 24px;
                display: block;
                flex: 1 1;
                border: none;
                outline: none;
                padding-left: 15px;
                color: var(--theme-text-color);
                font-size: 12px;
                font-family: $fontFamily, sans-serif;
            }
            input::placeholder {
                font-family: $fontFamily, sans-serif;
            }
            button {
                font-family: $fontFamily, sans-serif;
                height: 47px;
                background-color: transparent;
                border: 0;
                outline: 0;
                padding: 0 16px 0 10px;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                color: $primary;
                text-transform: uppercase;
                cursor: pointer;
                &:disabled {
                    color: #c0c0c0;
                    cursor: initial;
                }
            }
        }
        .message {
            display: flex;
            padding-bottom: 16px;
            &-container {
                padding: 8px 10px 0;
                height: 100%;
                display: flex;
                flex-flow: column;
                .empty-space-container {
                    flex: 1;
                }
            }

            &-text {
                font-weight: 500;
                font-size: 12px;
                flex-grow: 1;
                line-height: 1.2;
                letter-spacing: -0.4px;
                color: $dark;
                word-break: break-word;
                word-wrap: break-word;
                .title {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    margin: 0 0 5px;
                }
                .user-role {
                    font-weight: normal;
                }
            }
            &-time {
                min-width: 55px;
                margin-left: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.4px;
                color: $grey-dark;
            }
        }
    }

    .booth-wall-container {
        .empty-results {
            height: calc(100vh - 222px);
        }
    }

    .download-record {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: initial;
        a {
            color: white;
            font-size: 18px;
            padding: 12px 24px;
            border: 2px solid $white;
            border-radius: 12px;
        }
    }
    .session-has-ended-message {
        margin: 16px 0;
        text-align: center;
        font-size: 18px;
        display: block;
        a {
            font-weight: bold;
            color: $primary;
        }
    }
    .booth-wall-header {
        .tab {
            & > span {
                position: relative;
            }
            .users-number {
                position: absolute;
                padding: 0;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: $primary;
                font-weight: 500;
                font-size: 12px;
                margin-inline-start: 6px;
                right: -20px;
                top: 2px;
            }
            &.active {
                .users-number {
                    display: none;
                }
            }
        }
    }
    .networking-container {
        .sort-container {
            padding: 12px 0 8px;
            .button {
                padding: 10px 8px;
                font-size: 14px;
                font-weight: 600;
                margin-inline-start: 8px;
                &.active {
                    background-color: $secondary;
                    color: $white;
                    border-radius: 6px;
                    .users-number {
                        background-color: $white;
                        color: $dark;
                    }
                }
                .users-number {
                    padding: 0;
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: $primary;
                    font-weight: 500;
                    font-size: 12px;
                    margin-inline-start: 6px;
                }
            }
        }
        .custom-scroll-container {
            height: calc(100vh - 215px);
        }
    }
    .user-container {
        .user-details {
            border-bottom: 0;
        }
    }
}

.session-thumbnail-dialog {
    .MuiDialogContent-root {
        padding: 12px 100px;
    }
    .MuiDialogTitle-root {
        padding: 14px 16px;
        h2 {
            font-family: $fontFamily, sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .close-dialog-button {
                color: #777081;
                cursor: pointer;
            }
        }
    }
    .upload-label {
        width: 320px;
        height: 180px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        border: 1px solid #898989;
        color: $secondary;
        border-radius: 6px;
        cursor: pointer;
        svg {
            path {
                fill: $secondary;
            }
        }
        span {
            padding-top: 10px;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
        }
    }
    img {
        width: 320px;
        height: 180px;
        object-fit: cover;
        cursor: pointer;
    }
    .MuiDialogActions-root {
        justify-content: center;
        button {
            color: #fff;
            background-color: $primary;
            width: 155px;
            height: 40px;
            border-radius: 6px;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            &.remove {
                background-color: #d82a2a;
            }
            &:disabled {
                background-color: #777081;
            }
        }
    }
}

@keyframes recording-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.session-register {
    h3 {
        span {
            text-transform: capitalize;
        }
    }
    p {
        margin-bottom: 0;
    }
    .info-text {
        display: flex;
        svg {
            margin-inline-end: 8px;
        }
    }
    .setting-input {
        margin: 24px 0;
    }
    .agree-terms {
        padding-bottom: 10px;
        .MuiTypography-body1 {
            font-family: $fontFamily, sans-serif;
            font-size: 14px;
        }
    }
    button {
        font-weight: 600;
        font-size: 14px;
        padding: 6px 24px;
        &.Mui-disabled {
            background-color: $disabled-text;
            color: $white;
        }
        span {
            font-weight: 600;
        }
    }
}
@media only screen and (max-width: 1560px) {
    .module.live-session-users {
        .module-container {
            p.tab {
                font-size: 0.7vw;
            }
        }
    }
}
