.fs7 {
    font-size: 7px;
}
.fs8 {
    font-size: 8px;
}
.fs9 {
    font-size: 9px;
}
.fs10 {
    font-size: 10px;
}
.fs11 {
    font-size: 11px;
}
.fs12 {
    font-size: 12px;
}
.fs13 {
    font-size: 13px;
}
.fs14 {
    font-size: 14px;
}
.fs15 {
    font-size: 15px;
}
.fs16 {
    font-size: 16px;
}
.fs17 {
    font-size: 17px;
}
.fs18 {
    font-size: 18px;
}
.fs19 {
    font-size: 19px;
}
.fs20 {
    font-size: 20px;
}
.font-family {
    font-family: $fontFamily, sans-serif !important;
}
.text-center {
    text-align: center;
}
