@import 'variables.module';

.scholar {
    .largeContainer {
        max-width: 1430px;
    }
}
.largeContainer {
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
    height: calc(100vh - 70px);
    overflow: hidden;
}
.largeContainer h5 {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding-top: 20px;
    padding-bottom: 14px;
    margin: 0;
}
.left-container {
    //min-width: 437px;
    width: 436px;
    height: calc(100vh - 254px);
    max-height: 683px;
    margin-inline-end: 12px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.left-container .booth-title-wrapper,
.booth-view-header,
.booth-visitors-container > div:first-child,
.exhibitor-booth-view-page .statistics > div {
    background: $dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
    border-radius: 6px 6px 0 0;
    color: $white;
}
.left-container .booth-title-wrapper {
    max-height: 47px;
}
.left-container .booth-title-wrapper p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding-inline-end: 16px;
}
.left-container .booth-title-wrapper a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: $primary;
    text-transform: uppercase;
    text-align: right;
}
.left-container .booth-title-wrapper a:focus {
    outline: 0;
}
.booth-preview-container {
    width: 100%;
    flex: 1 1;
}
.booth-preview-container.no-exhibitors {
    height: 100%;
    opacity: 0.33;
}
.no-exhibitors-wrapper {
    height: 80%;
}
.no-content-box-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 100px;
    max-width: 1112px;
}
.no-content-box-container p {
    padding-top: 60px;
    color: $text-grey;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    max-width: 320px;
}
.no-content-box-container .direction-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(150px);
    top: -30px;
}
p.no-exhibitors-text {
    height: 20%;
    display: flex;
    margin: 0;
    justify-content: flex-end;
    align-items: center;
    font-size: 26px;
    text-transform: uppercase;
    color: $secondary;
    flex-flow: column;
}
p.no-exhibitors-text span {
    display: block;
    padding: 5px 30px;
    text-align: center;

    &:last-of-type {
        padding-bottom: 0;
    }
}
.booth-preview-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-position: center center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.single-booth-participant-view .booth-preview-container img {
    border-radius: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
.booth-manage {
    display: flex;
    flex-direction: row;
    max-height: calc(100% - 47px);
    flex: 1;

    textarea {
        scrollbar-width: thin;
        scrollbar-color: #777081 transparent;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #777081;
            border: 1px solid #FFFFFF;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
.tab-navigation {
    display: flex;
    flex-flow: column;
    //min-width: 100px;
    max-width: 105px;
    margin-right: 30px;
}
.tab-navigation button {
    flex-grow: 1;
    background: $dark;
    border: 0;
    outline: 0;
    cursor: pointer;
    margin: 0;
    border-left: 4px solid $dark;
    border-bottom: 1px solid $disabled-text;
    &:last-of-type {
        border-bottom: 0;
        border-bottom-left-radius: 8px;
    }
    @media (hover: hover) {
        &:hover {
            border-left-color: $primary;
        }
        &:focus {
            border-left-color: $blue;
            background: $blue;
            p {
                color: $black;
            }
            svg path {
                fill: $black;
            }
        }
    }
    svg path {
        fill: $primary;
    }
    p {
        color: $secondary;
    }
}
.tab-navigation button:first-of-type {
    border-top: 0;
}
.tab-navigation button.active {
    background: $primary;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding-inline-start: 2px;
    border-left-color: $primary;
    svg path {
        fill: $secondary;
    }
    p {
        color: $secondary;
    }
}
.tab-navigation button p {
    font-family: $fontFamily, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.07em;
    margin: 0;
    padding-top: 6px;
    text-transform: uppercase;
    color: $white;
}
.booth-management-container .tab-navigation {
    margin-right: 0;
}
.booth-management-container .tab-content {
    width: 337px;
    height: 100%;
    position: relative;
    flex: 1;
    .scroll-left-container {
        overflow-x: hidden;
    }
}
.company {
    .booth-management-container .booth-preview-container {
        max-height: 470px;
    }
}

.booth-management-container .booth-preview-container {
    max-height: 520px;
}
.booth-manage .manage-booth-style {
    padding-top: 0;
    .MuiFormControlLabel-root {
        &[data-checked="false"] {
            &:before {
                display: none;
            }
        }
        &[data-checked="true"] {
            &:before {
                display: none;
            }
        }
    }
}
.radio-fix {
    &::before {
        right: 3px !important;
    }
    &::after {
        right: 0 !important;
    }
}
.manage-booth-style fieldset,
.manage-booth-documents > div,
.manage-booth-video > div {
    width: 100%;
    min-width: 297px;
}
.manage-booth-documents .scrollbar .box {
    margin-bottom: 0 !important;
}
.manage-booth-video form {
    padding: 0;

    .MuiFormControl-root {
        white-space: nowrap;

        .MuiFormLabel-root:not(.Mui-focused) {
            font-size: 13.5px;
        }
    }
}
.manage-booth-style label span:last-child {
    font-family: $fontFamily, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: black;
}
.manage-booth-style label {
    position: relative;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid #ccc;
    &[data-checked="true"] {
        &:after {
            transform: none;
            top: initial;
            left: initial;
            right: 5px;
        }
    }
}
.manage-booth-style label > span span {
    color: $primary;
}
.manage-booth-style label svg {
    display: none;
}
.manage-booth-style label input {
    -webkit-appearance: none;
}
.manage-booth-banners {
    padding-top: 24px;
}
.manage-booth-banners .logo-container p,
.manage-booth-documents div p {
    text-transform: uppercase;
}
.cover-img-container {
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.cover-img-container .spinner {
    left: -0.5px;
    bottom: -0.5px;
}
.cover-img-container > img:not(.upload-icon) {
    width: 70%;
    height: 90%;
    object-fit: contain;
    object-position: center;
}
.cover-img-container .edit-label {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cover-img-container .upload-label {
    cursor: pointer;
}
.cover-img-container span {
    display: block;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $secondary;
    padding-top: 5px;
}
.cover-img-container-portrait {
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.cover-img-container-portrait .spinner {
    left: -0.5px;
    bottom: -0.5px;
}
.cover-img-container-portrait > img:not(.upload-icon) {
    width: 70%;
    height: 90%;
    object-fit: contain;
    object-position: center;
}
.cover-img-container-portrait .edit-label {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cover-img-container-portrait .upload-label {
    cursor: pointer;
}
.cover-img-container-portrait span {
    display: block;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $secondary;
    padding-top: 5px;
}
.manage-booth-banners p,
.manage-partner-logo p,
.manage-exhibitor-logo p,
.manage-lobby-logo p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 14px;
}
.manage-booth-banners p > span {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
}
.manage-booth-banners p > span .no-file,
.manage-booth-banners p > span .uploaded-file {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}
.manage-booth-video .MuiFormHelperText-root.Mui-error {
    white-space: pre-line;
}
.tab-content button,
.exhibitor-representative-dialog button,
.manage-booth-video button.upload-video {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $text-grey;
    padding-inline-end: 0;
}
.manage-booth-video button.upload-video:disabled {
    color: $text-grey;
    cursor: initial;
}
.tab-content button.remove-file {
    color: $error-light;
}
.manage-booth-video button.remove-file {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}
.tab-content button.save-file,
.manage-booth-video button.upload-video {
    color: $secondary;
    text-transform: uppercase;
}
.tab-content button.save-file:disabled {
    cursor: not-allowed;
    color: $text-grey;
}
.manage-booth-style,
.manage-booth-banners,
.manage-booth-documents,
.manage-booth-links,
.manage-booth-representatives,
.manage-booth-video {
    padding: 24px 20px 0 20px;
}
.manage-booth-documents p,
.social-links-container > p,
.other-links-container > p,
.manage-booth-representatives > div > p,
.medalion-representative-wrapper,
.manage-booth-video form > p,
.manage-booth-video .logo-container p,
.manage-study-details p {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
    margin: auto;
}
.manage-booth-video .current-banner-container,
.manage-booth-video .current-logo-container {
    margin-top: 16px;
}
.manage-booth-video .logo-container {
    margin-top: 30px;
}
.manage-booth-video form > p.title,
.manage-booth-video .logo-container p.title {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}
.manage-booth-representatives > div > p:first-child,
.manage-booth-representatives .medalion-representative-wrapper {
    text-transform: uppercase;
}
.additional-info-line {
    font-size: 12px !important;
    font-weight: normal !important;
    margin-top: 0 !important;
}
.manage-booth-documents p {
    margin-bottom: 10px;
}
.manage-booth-links form {
    padding: 0;
}
.current-uploaded-documents {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
}

.manage-booth-documents .document-name {
    margin-top: 5px;
}

.current-uploaded-documents li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-inline-start: 10px;
}
.current-uploaded-documents li:last-of-type {
    margin-bottom: 0;
}
.current-uploaded-documents li.spinner-container {
    position: relative;
    height: 50px;
}
.current-uploaded-documents li a {
    color: black;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
    word-break: break-word;
    margin-right: 10px;
}
.current-uploaded-documents li button {
    text-transform: none;
}
.manage-booth-documents label {
    color: #000 !important;
}
.document-name > div {
    margin-top: 0;
}
.manage-booth-documents .edit-label {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    cursor: pointer;
}
.manage-booth-documents .edit-label span {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $primary;
    margin-inline-start: 6px;
}
.booth-manage {
    input,
    label {
        font-family: $fontFamily, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        color: $text-grey;
        &.MuiInputLabel-shrink {
            font-size: 14px;
        }
    }
    .list-style {
        list-style: none;
        margin-top: 3px;
        margin-bottom: 0;
        padding: 0;
        li {
            padding: 12px 32px 12px 16px;
            background: $light-grey;
            margin-bottom: 8px;
            border: 1px solid $disabled-text;
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            font-size: 16px;
            border-radius: 3px;

            span {
                &:first-of-type {
                    padding-bottom: 8px;
                }
            }

            a {
                color: $secondary;
                word-break: break-word;
            }

            button {
                position: absolute;
                top: 10px;
                right: 10px;
                margin: 0;

                svg {
                    path {
                        fill: $grey;
                    }
                }
            }
        }
    }
}
.booth-manage .upload-study-pdf-presentation {
    width: 100%;
    max-width: 297px;
    height: 196px;
    position: relative;
    background-color: #eee;
    border-radius: 8px;
    border: 0.5px solid $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 6px;
    cursor: pointer;
    span {
        display: block;
        font-family: $fontFamily, sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $secondary;
        padding-top: 5px;
    }
}
.new-file-upload-container .upload-document {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: $secondary;
}
.new-file-upload-container .upload-document:disabled {
    cursor: initial;
    color: $text-grey;
}
.manage-booth-documents .document-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(137, 137, 137, 0.9);
    margin-top: 2px;
}
.manage-booth-links .link-container button {
    margin-inline-start: 25px;
    margin-top: 20px;
    text-align: right;
}
.manage-booth-links .other-links-container .link-container a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
    flex: 1;
    border-bottom: 1px solid $grey;
}
.other-links-container > p {
    margin-bottom: 10px;
}
.other-links-container .other-links-list {
    margin: 0;
}
.other-links-container {
    .scrollbar {
        margin-left: auto;

        &[data-number='false'] {
            li {
                padding-left: 0;
            }
        }

        .box {
            margin-bottom: 0 !important;
        }

        li {
            padding-left: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
.manage-booth-links .other-links-container .link-container button {
    margin-top: 0;
    margin-inline-start: 15px;
    padding-inline-start: 0;
}
.manage-booth-links .link-container button span {
    text-transform: none;
}
.manage-booth-links .other-links-container ul {
    list-style-type: none;
    padding-inline-start: 0;
}
.social-links-container > div > div {
    margin: 0;
}
.manage-booth-links label + div:hover:before {
    border-bottom: 1px solid $grey !important;
}
.manage-booth-links .link-container {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
}
.manage-booth-links .link-container > div {
    flex: 1;
    margin: 0;
}
.manage-booth-links button {
    min-width: 50px;
}
.manage-booth-links button:disabled {
    cursor: initial;
}
.manage-booth-documents .link-container label,
.search-here label,
.visitors label {
    color: $text-grey !important;
}
.agree-terms label span a:hover {
    color: $secondary !important;
}
.agree-terms label input {
    cursor: pointer;
}
.manage-booth-links .link-container input,
.visitors input,
#add-representative-dialog #standard-search,
#add-speaker-dialog #standard-search {
    padding-top: 0;
    padding-bottom: 5px;
}
.manage-booth-links label + div:after,
.search-here label + div:after,
.visitors label + div:after,
.register-form-wrapper label + div:after,
#add-representative-dialog label + div:after,
#add-speaker-dialog label + div:after {
    border-bottom: 0 !important;
}
.manage-booth-links .link-container label[data-shrink='true'],
.manage-booth-documents .link-container label[data-shrink='true'],
.search-here label[data-shrink='true'],
.visitors label[data-shrink='true'],
.booth-manage #add-representative-dialog label[data-shrink='true'],
#add-speaker-dialog label[data-shrink='true'] {
    font-size: 12px;
    color: $secondary !important;
}
.manage-booth-links .social-links-container .link-container button {
    margin-bottom: 10px;
    margin-inline-start: 15px;
    padding: 0;
}
.social-links-container .link-container button span.remove-file {
    color: $error-light;
}
.social-links-container .link-container button:disabled span {
    color: $text-grey;
    cursor: initial;
}
.social-links-container .upload-document {
    font-size: 12px;
    color: $secondary;
}
.other-links-container {
    padding-top: 17px;
}
.other-links-container li button {
    color: $error-light;
}
.manage-booth-links .other-links-container .new-link-container.link-container button {
    color: $secondary;
}
.manage-booth-links .other-links-container .link-container.new-link-container button:disabled {
    color: $text-grey;
    cursor: auto;
}
.other-links-container .link-container {
    margin-bottom: 20px;
}
.other-links-container .link-container.new-link-container {
    align-items: center;
    margin-top: 20px;
}
.manage-booth-links .link-container .inputs-container {
    padding-inline-end: 10px;
    border-inline-end: 1px solid $dark;
}
.manage-booth-links .link-container .inputs-container .MuiTextField-root {
    margin-top: 4px;
}
.other-links-container .link-container.new-link-container button {
    margin-top: 23px;
    margin-inline-start: 5px;
}
.new-link-container > div {
    margin: 0;
}
.manage-booth-representatives .exhibitor-representatives-list {
    margin: 5px 0;
    padding-inline-start: 0;
    list-style-type: none;
}
.manage-booth-representatives .exhibitor-representatives-list li {
    padding-bottom: 7px;
}
.manage-booth-representatives .representative-container .info-container {
    display: flex;
    align-items: center;
}
.manage-booth-representatives .representative-container .info-container .avatar {
    margin-right: 10px;
    display: flex;
    align-self: center;
}
.representative-container .info-container .info-wrapper {
    flex: 1;
}
.info-wrapper p {
    margin: 0;
}
.info-wrapper .info-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
}
.info-wrapper p:last-of-type {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: $text-grey;
}
.manage-booth-representatives .positioning-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $dark;
    margin: 0;
    padding: 12px 0;
}
.medalion-representative-wrapper {
    padding-top: 25px;
}
.info-container button {
    padding-inline-start: 15px;
}
.info-container .edit-button {
    color: $primary;
}
.info-container .remove-button {
    color: $error-light;
}
.manage-booth-representatives .new-avatar-button {
    padding: 0;
    display: flex;
    align-items: center;
    padding-top: 7px;
    width: 100%;
    justify-content: space-between;
    cursor: initial;
}
.manage-booth-representatives .new-avatar-button span {
    cursor: pointer;
}
.manage-booth-representatives .new-avatar-button img {
    width: 36px;
    height: 36px;
}
.manage-booth-representatives .new-avatar-button > div {
    display: flex;
    align-items: center;
}
.manage-booth-representatives .new-avatar-button span {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    color: $secondary;
    margin-inline-start: 10px;
    font-weight: 600;
    font-size: 12px;
}
.add-button-wrapper .representative-container > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.add-button-wrapper .add-button {
    color: $secondary;
}
.manage-booth-video button.upload-video {
    font-weight: 600;
    font-size: 14px;
    font-family: $fontFamily, sans-serif;

    svg {
        margin-right: 11px;
        margin-bottom: -3px;
    }

    &.scholar {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 6px;

        span {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            color: $secondary;
            font-family: $fontFamily, sans-serif;
            padding-inline-start: 11px;
        }

        svg {
            margin-right: 0;
            margin-bottom: -3px;
        }
    }
}
.manage-booth-video .button-container {
    margin-top: 5px;
    text-align: right;
}
.booth-management-container {
    @media screen and (max-width: 1299px) {
        padding: 0 20px;
        .event-name-wrapper {
            max-width: 1028px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .boothOverlay {
        position: relative;
        height: 100%;
    }
}
.booth-management-container .boothOverlay div.preview {
    display: none;
}
.booth-management-container .boothOverlay div.preview p {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.booth-management-container .boothOverlay:hover div.preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}
.booth-preview-container #highlight-banner,
.booth-preview-container #highlight-files,
.booth-preview-container #highlight-study-details,
.booth-preview-container #highlight-links,
.booth-preview-container #highlight-representatives,
.booth-preview-container #highlight-preview-file,
.booth-preview-container #highlight-video {
    box-shadow: 0 0 15px 5px $blue;
    position: absolute;
}
.booth-preview-container #highlight-banner {
    width: 107px;
    height: 60px;
    left: 267px;
    bottom: 64px;
}
.booth-preview-container #highlight-files {
    width: 244px;
    height: 44px;
    left: 486px;
    bottom: 52px;
}
.booth-preview-container #highlight-preview-file {
    width: 243px;
    height: 150px;
    left: 487px;
    bottom: 118px;
}
.scholar .booth-preview-container #highlight-files {
    width: 380px;
    height: 300px;
    left: 168px;
    bottom: 145px;
    @media (max-width: 1530px) {
        width: 320px;
        height: 260px;
        left: 162px;
        bottom: 126px;
    }
    @media screen and (max-width: 1370px) {
        left: 142px;
    }
    @media (max-width: 1300px) {
        width: 250px;
        height: 200px;
        left: 100px;
        bottom: 93px;
    }
}
.scholar .booth-preview-container #highlight-study-details {
    position: absolute;
    width: 707px;
    height: 80px;
    left: 168px;
    bottom: 25px;
    @media (max-width: 1530px) {
        width: 607px;
        height: 70px;
        left: 162px;
        bottom: 26px;
    }
    @media screen and (max-width: 1370px) {
        left: 142px;
    }
    @media (max-width: 1300px) {
        width: 466px;
        height: 50px;
        left: 100px;
        bottom: 13px;
    }
}
.exhibitor-dashboard-page {
    display: flex;
    position: relative;
    .exhibitor-wrapper {
        width: calc(100vw - var(--sidebar));
        .booth-container {
            padding: 24px;
        }
    }
    &.scholar {
        .info-container {
            .user-avatar {
                background-size: contain;
            }
        }
    }
    h5 {
        text-transform: uppercase;
        font-weight: 600;
        @media screen and (min-width: 1025px) and (max-width: 1430px) {
            padding-left: 20px;
        }
    }
}
.booth-preview-container #highlight-links {
    width: 75px;
    height: 41px;
    left: 383px;
    bottom: 166px;
}
.scholar .booth-preview-container #highlight-links {
    width: 140px;
    height: 80px;
    left: 610px;
    bottom: 170px;
    @media (max-width: 1530px) {
        width: 100px;
        height: 60px;
        left: 553px;
        bottom: 150px;
    }
    @media screen and (max-width: 1370px) {
        left: 535px;
    }
    @media (max-width: 1300px) {
        width: 90px;
        height: 50px;
        left: 393px;
        bottom: 110px;
    }
}
.booth-preview-container #highlight-representatives {
    width: 332px;
    height: 283px;
    left: 57px;
    bottom: 74px;
    box-shadow: none;
}
.booth-preview-container #highlight-representatives img {
    box-shadow: none;
    border-radius: 0;
}
.scholar .booth-preview-container #highlight-presentation-representatives {
    position: absolute;
    width: 120px;
    height: 310px;
    left: 10px;
    bottom: 20px;
    img {
        border: none;
        box-shadow: none;
    }
    @media (max-width: 1530px) {
        width: 100px;
        height: 260px;
        left: 20px;
        bottom: 10px;
    }
    @media screen and (max-width: 1370px) {
        left: 5px;
    }
    @media (max-width: 1300px) {
        width: 70px;
        height: 200px;
        bottom: 10px;
    }
}
.scholar .booth-preview-container #highlight-medalion-representatives {
    position: absolute;
    width: 80px;
    height: 290px;
    left: 800px;
    bottom: 150px;
    img {
        border: none;
        box-shadow: none;
    }
    @media (max-width: 1530px) {
        width: 60px;
        height: 260px;
        left: 710px;
        bottom: 127px;
    }
    @media screen and (max-width: 1370px) {
        left: 692px;
    }
    @media (max-width: 1300px) {
        width: 50px;
        height: 190px;
        left: 518px;
        bottom: 97px;
    }
}
.booth-preview-container #highlight-video {
    width: 156px;
    height: 88px;
    left: 785px;
    bottom: 263px;
}
.scholar .booth-preview-container #highlight-video {
    width: 140px;
    height: 80px;
    left: 612px;
    bottom: 340px;
    @media (max-width: 1530px) {
        width: 100px;
        height: 60px;
        left: 553px;
        bottom: 300px;
    }
    @media screen and (max-width: 1370px) {
        left: 535px;
    }
    @media (max-width: 1300px) {
        width: 90px;
        height: 50px;
        left: 393px;
        bottom: 210px;
    }
}
.links-container,
.scholar-container {
    padding: 20px;

    // must be general css rules !!! :(
    .MuiOutlinedInput-root {
        &.Mui-focused {
            fieldset {
                border-color: $secondary;
            }
            &.Mui-error {
                fieldset {
                    border-color: #f44336;
                }
            }
        }
    }

    .MuiTextField-root {
        &.filled {
            fieldset {
                border-color: $secondary;
            }
            .Mui-error {
                fieldset {
                    border-color: #f44336;
                }
            }
            &.Mui-error {
                margin-bottom: 0;
                fieldset {
                    border-color: #f44336;
                }
                label {
                    color: #f44336 !important;
                }
            }
        }
        label {
            &.Mui-focused,
            &.MuiFormLabel-filled {
                color: $secondary !important;
            }
            &.Mui-error {
                color: #f44336 !important;
            }
        }
        input {
            padding-right: 14px;
        }
    }

    svg {
        &.empty {
            path {
                fill: $grey;
            }
        }
        &.remove {
            path {
                fill: $secondary;
            }
        }
    }

    h2 {
        margin: 0;
        font-weight: 800;
        font-size: 16px;
        text-transform: uppercase;
        color: $text-grey;
    }

    & > div {
        .MuiTextField-root {
            margin: 14px 0;
        }
    }

    .study-highlights-container {
        padding-top: 19px;
    }

    .new-author-container {
        padding-top: 21px;

        .setting-input {
            &:first-of-type {
                margin-bottom: 0;
            }
        }
    }

    .actions-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.authors,
        &.company-links {
            position: relative;
            flex-direction: column;

            & ~ div {
                &.add-button-wrapper {
                    padding: 0;
                }
            }

            h2 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                color: $text-grey;
                text-transform: none;
                padding-top: 15px;
            }
        }

        &.company-links {
            align-items: flex-start;
            p {
                padding-bottom: 15px;
            }
        }

        button,
        & > .button-action {
            display: flex;
            align-items: center;
            opacity: 1;
            transition: opacity 0.5s;
            cursor: pointer;
            min-width: initial;

            &.update-links {
                opacity: 0;
                transition: none;

                &.show {
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }
            }

            &:disabled {
                opacity: 0;
                cursor: initial;

                &.show-disabled-button {
                    opacity: 1;
                    padding: 10px 12px;
                    top: -12px;
                    right: -12px;
                    background: #f5f5f5;
                    border-radius: 6px;
                    span {
                        color: $text-grey;
                    }
                    svg {
                        fill: $grey;
                    }
                }
            }

            span {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                color: $secondary;
                font-family: $fontFamily, sans-serif;
                padding-inline-start: 11px;
                flex: 1;
            }

            svg {
                fill: $secondary;
            }
        }
    }

    .add-items-list {
        .inputs-container {
            & > div {
                &:first-of-type {
                    .MuiFormControl-root {
                        margin-bottom: 4px;
                        margin-top: 0;
                    }
                }
            }
        }
        .actions-wrapper {
            padding-bottom: 18px;
            padding-top: 18px;
        }
    }

    .representative-container {
        .user-avatar {
            width: 40px;
            height: 40px;
            margin-inline-end: 12px;
        }
        .info-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        svg {
            path {
                fill: $error;
            }
        }
    }

    .co-authors {
        padding-top: 50px;

        .exhibitor-representatives-list {
            margin: 0;
            padding-top: 26px;
        }
    }

    .authors-tab {
        &.show-border {
            &:after {
                position: absolute;
                left: 0;
                content: ' ';
                display: block;
                border-bottom: 1px solid $disabled-text;
                margin-top: 10px;
                width: 100%;
            }
            .authors {
                padding-bottom: 26px;
            }
        }
    }
}
.manage-booth-documents {
    label + .MuiInput-formControl {
        margin-top: 30px;
    }
    &.scholar {
        h2 {
            margin: 0;
            font-weight: 800;
            font-size: 16px;
            text-transform: uppercase;
            color: $text-grey;
        }

        p {
            font-weight: 500;
            font-size: 14px;
            color: $text-grey;
            padding-top: 8px;
        }

        .current-uploaded-documents {
            li {
                padding-inline-start: 0;
                justify-content: flex-start;

                span {
                    margin-inline-end: 5px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                }

                & > div {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    a {
                        color: $secondary;
                        display: block;
                        display: -webkit-box;
                        max-width: 100%;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        text-align: start;
                    }

                    svg {
                        width: 20px;
                        height: 25px;

                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
        }
        .drag-file-wrapper {
            background: #f5f5f5;
            height: 175px;
            border: 1px dashed $secondary;
            border-radius: 6px;
            cursor: pointer;

            .dropzone {
                outline: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                & > div {
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    p {
                        margin: 0;
                        padding: 0;
                        font-weight: 600;
                        font-size: 14px;
                        color: $secondary;
                        text-transform: uppercase;
                        flex: 1;
                    }
                    svg {
                        margin-inline-end: 12px;
                    }
                }
            }
        }
        .or-wrapper {
            padding: 16px 0;
            text-align: center;
            span {
                font-weight: 600;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
        .upload-study-pdf-presentation {
            max-width: initial;
            border: 0;
            background: $secondary;
            border-radius: 6px;
            position: relative;
            height: auto;
            margin: 0;
            padding: 10px 40px;
            span {
                padding: 0;
                color: #ffffff;
            }
            svg {
                position: absolute;
                left: 12px;
                path {
                    fill: #ffffff;
                }
            }
        }
    }
}
.manage-booth-video {
    .remove-file {
        svg {
            margin-right: 11px;
            margin-bottom: -1px;
        }
    }

    &.scholar-container {
        .actions-wrapper {
            h2 {
                p {
                    padding-top: 5px;
                    padding-bottom: 15px;
                }
            }
            &.authors {
                align-items: flex-start;
                p {
                    padding-top: 5px;
                    padding-bottom: 15px;
                }
            }
        }
        .logo-container {
            position: relative;
            .additional-info-line {
                margin: 0 0 0 2px;
                font-weight: 500 !important;
                color: $text-grey;
                text-transform: none;
            }
        }
        .remove-file {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-family: $fontFamily, sans-serif;
            position: absolute;
            right: 0;
            top: -11px;

            span {
                margin-inline-start: 11px;
            }

            svg {
                margin-right: 0;
                margin-bottom: 0;
                path {
                    fill: $error-light;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    @supports (-ms-ime-align: auto) {
        .current-uploaded-documents li a {
            word-break: break-all;
        }
    }
}
@media all and (max-height: 859px) {
    .booth-management-container .left-container {
        height: calc(100vh - 120px);
    }
    .booth-preview-container {
        height: calc(100vh - 150px);
    }
    .manage-booth-links {
        padding-top: 14px;
    }
    .other-links-container {
        padding-top: 14px;
    }
    .other-links-container > p {
        margin-bottom: 10px;
    }
    .other-links-container .link-container {
        margin-bottom: 15px;
    }
    .manage-booth-links .other-links-container ul {
        max-height: 152px !important;
    }
    .manage-booth-representatives {
        padding-top: 14px;
    }
    .other-links-container .scrollbar[data-responsive='true'] li {
        padding-left: 10px;
    }
}

@media all and (max-height: 700px) {
    .manage-booth-video .current-banner-container {
        height: 120px;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 1530px) {
    .scholar .booth-management-container {
        .booth-preview-container {
            max-width: 840px;
        }
        .boothOverlay {
            max-height: 450px;
        }
    }
}

@media all and (max-width: 1600px) {
    .company .booth-management-container {
        .booth-preview-container {
            max-width: 840px;
        }
        .boothOverlay {
            max-height: 390px;
        }
    }
    .booth-preview-container #highlight-banner {
        width: 82px;
        height: 50px;
        left: 226px;
        bottom: 50px;
    }
    .booth-preview-container #highlight-files {
        width: 200px;
        height: 36px;
        left: 408px;
        bottom: 43px;
    }
    .booth-preview-container #highlight-preview-file {
        width: 204px;
        height: 126px;
        left: 406px;
        bottom: 97px;
    }
    .booth-preview-container #highlight-links {
        width: 63px;
        height: 35px;
        left: 321px;
        bottom: 138px;
    }
    .booth-preview-container #highlight-representatives {
        width: 308px;
        height: 243px;
        left: 24px;
        bottom: 72px;
    }
    .booth-preview-container #highlight-video {
        width: 129px;
        height: 73px;
        left: 655px;
        bottom: 219px;
    }
}

@media screen and (max-width: 1410px) {
    .company .booth-management-container {
        .booth-preview-container {
            max-width: 730px;
        }
        .boothOverlay {
            max-height: 360px;
        }
    }
    .booth-preview-container #highlight-files {
        left: 353px;
        width: 186px;
        height: 37px;
        bottom: 38px;
    }
    .booth-preview-container #highlight-preview-file {
        width: 187px;
        height: 115px;
        left: 353px;
        bottom: 90px;
    }
    .booth-preview-container #highlight-banner {
        left: 194px;
        width: 70px;
        height: 42px;
    }
    .booth-preview-container #highlight-links {
        left: 272px;
        width: 60px;
        height: 36px;
        bottom: 125px;
    }
    .booth-preview-container #highlight-representatives {
        width: 280px;
        left: 0;
        bottom: 45px;
    }
    .booth-preview-container #highlight-video {
        left: 579px;
        width: 125px;
        bottom: 199px;
    }
}

@media all and (max-width: 1300px) {
    .scholar {
        .booth-management-container {
            .boothOverlay {
                max-height: 340px;
            }
            .booth-preview-container {
                max-width: 600px;
            }
        }
    }
}
@media all and (max-width: 1300px) {
    .company .booth-management-container {
        .booth-preview-container {
            max-width: 600px;
        }
        .boothOverlay {
            max-height: 314px;
        }
    }
    .booth-preview-container #highlight-banner {
        width: 69px;
        height: 37px;
        left: 144px;
        bottom: 44px;
    }
    .booth-preview-container #highlight-files {
        width: 161px;
        height: 31px;
        left: 290px;
        bottom: 34px;
    }
    .booth-preview-container #highlight-preview-file {
        width: 167px;
        height: 101px;
        left: 287px;
        bottom: 78px;
    }
    .booth-preview-container #highlight-links {
        width: 53px;
        height: 32px;
        left: 218px;
        bottom: 109px;
    }
    .booth-preview-container #highlight-representatives {
        width: 225px;
        height: 160px;
        left: 0;
        bottom: 77px;
    }
    .booth-preview-container #highlight-video {
        width: 110px;
        height: 63px;
        left: 486px;
        bottom: 175px;
    }
}
@media all and (max-width: 1024px) {
    .no-exhibitors-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .no-exhibitors-wrapper p {
        padding-top: 60px;
        color: $text-grey;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        max-width: 280px;
    }
}
