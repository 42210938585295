@import 'variables.module';

.dropdown-actions-chats {
    position: absolute;
    left: initial;
    right: -40px;
    top: 0;
    /*transform: rotate(180deg);*/
    flex-flow: column;
    justify-content: space-between;
}
.dropdown-actions-chats .close-dropdown {
    position: initial;
    border-radius: 0 8px 8px 0;

    &.close {
        &:hover {
            svg {
                fill: $error-light;
            }
        }
    }

    &.expand {
        &:hover {
            svg {
                fill: $primary;
            }
        }
    }
}
.close-dropdown.expand {
    margin-top: 5px;
}
.dropdown-actions-chats svg {
    fill: #ffffff;
    transform: rotate(90deg);
}
.chat-page.dropdown-component {
    height: calc(100vh - 70px);
    background-color: $white;
    overflow: visible;
    width: 323px;
    max-width: 323px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    top: 0;
    left: var(--sidebar);
    @media screen and (min-width: 1025px) {
        background-color: #ECECED;
        z-index: 10;
    }
}
.chat-page.dropdown-component.expanded {
    width: 710px;
    max-width: initial;
}
.chat-page .private-chat-name.full-name {
    display: none;
    font-weight: 600;
}
.expanded .private-chat-name {
    display: none;
}
.expanded .private-chat-name.full-name {
    display: block;
}
.chat-page.dropdown-component[data-close='true'] {
    display: none;
}
.no-active-chat .chats-container {
    padding-top: 0;
}
.chats-container {
    height: calc(100% - 49px);
    padding-top: 48px;
    position: relative;
}
.chatsPage {
    display: flex;
    height: 100%;
}
.chatsPage .chats-list-container {
    border-right: 0.5px solid rgba(137, 137, 137, 0.5);
    display: inline-flex;
    flex-flow: column;
    overflow-y: auto;
    max-width: 68px;
    flex: 1;
    background-color: $white-variant;
    @media screen and (min-width: 1025px) {
        background-color: transparent;
    }
    .box {
        padding: 0 !important;
        margin-top: 0 !important;
    }
}
.no-active-chat .chatsPage .chats-list-container {
    max-width: 100%;
    border-right: none;
}
.no-chats .chatsPage .chats-list-container {
    flex: 0 !important;
}
.no-chats .no-chats-info-container .page-title {
    background-color: transparent !important;
    justify-content: center;
}
.no-chats .no-chats-info-container .page-title p {
    color: $primary !important;
    padding: 0 !important;
}
.expanded .chatsPage .chats-list-container {
    max-width: 270px;
}
.chats-list-container[data-number-chats='0'],
.opened-chat-container[data-number-chats='0'] {
    background-color: #f5f5f5;
}
.opened-chat-container[data-number-chats='0'] {
    align-items: center;
    justify-content: center;
    position: relative;
}
.opened-chat-container[data-number-chats='0'] .page-title {
    position: absolute;
    top: 0;
}
.opened-chat-container[data-number-chats='0'] .page-title p,
.chat-page .page-title p {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
}
.opened-chat-container[data-number-chats='0'] > div {
    max-width: 229px;
    text-align: center;
}
.opened-chat-container[data-number-chats='0'] > div p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark;
    margin-top: 0;
}
.opened-chat-container {
    display: inline-flex;
    flex: 1;
    max-width: 422px;
}
.expanded .opened-chat-container {
    max-width: 480px;
}
.no-chats.expanded .opened-chat-container {
    max-width: 100%;
}
.opened-chat-container .opened-chat-wrapper {
    width: 100%;
}
.no-active-chat .opened-chat-container {
    flex: 0;
    display: none;
}
.expanded.no-active-chat .opened-chat-container {
    display: inline-flex;
}
.no-active-chat.no-chats .opened-chat-container {
    flex: 1;
    display: inline-flex;
}
.no-active-chat.expanded .opened-chat-container {
    flex: 1;
}
.no-active-chat-message {
    padding: 15px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(137, 137, 137, 0.5);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
    background-color: white;

    svg {
        cursor: pointer;
    }
}
.chat-header-container img {
    margin-left: 16px;
    cursor: pointer;
}
.chat-header-container img.mobile {
    display: none;
}
.chat-header-container img + div {
    padding-left: 5px;
}
.chat-header-container > p,
.chat-header-container .dropdown-title {
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin: 0;
    text-transform: uppercase;
}
.no-active-chat .chat-header-container .dropdown-title span {
    padding-left: 4px;
}
.chat-header-container .dropdown-title {
    display: flex;
    align-items: center;
}
.chat-header-container .dropdown-title svg {
    margin-right: 8px;
    fill: $primary;
    @media screen and (max-width: 1024px) {
        fill: $secondary;
    }
}
.no-active-chat .chat-header-container .dropdown-title img {
    margin: 0;
    width: 0;
    transition: width 0.2s;
}
.chat-header-container .dropdown-title img {
    width: 24px;
    transition: width 0.2s;
    margin: 0;
}
.chat-header-container > div {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    width: 100%;
    align-items: center;
    padding-inline-start: 8px;
}
.chat-header-container {
    button {
        padding: 0;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 48px;
        line-height: 48px;
        outline: 0;
        cursor: pointer;
        width: 86px;
        background-repeat: no-repeat;
        background-size: cover;
        &:disabled {
            cursor: initial;
        }
        div {
            width: 100%;
            height: 100%;
            position: relative;
            svg {
                width: 100%;
                height: 100%;
                filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
            }
            span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: -0.08em;
                color: $black;
                font-weight: bold;
                width: 100%;
                height: 100%;
                text-align: center;
                font-family: $fontFamily, sans-serif;
            }
        }
    }
}
.chat-header-container button,
.chat-header-container button p {
    margin: 0;
    color: $black;
    font-family: $fontFamily, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.08em;
}
.chats-list-container .all-chats {
    padding: 4px 10px;
    text-align: left;
    @media screen and (min-width: 1025px) {
        background-color: white;
    }
}
.chats-list-container .all-chats .chat-name {
    flex-grow: 1;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
}
.expanded .all-chats .chat-name,
.no-active-chat .all-chats .chat-name {
    p {

        color: black !important;
    }
    text-align: left;
    padding-top: 8px;
}
.chats-list-container .all-chats > div {
    cursor: pointer;
    padding-top: 8px;
}
.expanded .chats-list-container .all-chats > div,
.no-active-chat .chats-list-container .all-chats > div {
    display: flex;
}
.expanded .chats-list-container .all-chats > div:first-of-type,
.no-active-chat .chats-list-container .all-chats > div:first-of-type {
    padding-top: 0;
}
.expanded .chats-list-container .all-chats > div:first-of-type .user-wrapper-separator,
.no-active-chat .chats-list-container .all-chats > div:first-of-type .user-wrapper-separator {
    display: none;
}
.expanded .close-active-chat-button {
    display: none;
}
.expanded .chat-header-container .dropdown-title span {
    padding-inline-start: 0;
}
.single-chat-header .group-chat-button {
    display: flex;
    align-items: center;
}
.single-chat-header .back-button {
    margin-left: 8px;
    cursor: pointer;
    fill: $primary;
}
.create-new-chat {
    margin-left: 8px;
}
.button-wrapper button {
    padding-inline-end: 5px;
    padding-inline-start: 0;
}
.group-chat-button > .chat-details {
    flex: 1;
    text-align: center;
}
.expanded .group-chat-button > .chat-details,
.no-active-chat .group-chat-button > .chat-details {
    text-align: left;
}
.chat-details .group-chat-name {
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
}
.expanded .chat-details .group-chat-name,
.no-active-chat .chat-details .group-chat-name {
    text-align: start;
    padding-bottom: 0;
    padding-top: 8px;
    font-weight: 600;
}
.group-chat-button .time-ago,
.private-chat .time-ago {
    margin: 0;
    padding-top: 10px;
    color: $grey;
    font-size: 10px;
    display: none;
}
.expanded .group-chat-button .time-ago,
.expanded .private-chat .time-ago,
.no-active-chat .group-chat-button .time-ago,
.no-active-chat .private-chat .time-ago {
    padding-top: 8px;
    display: block;
}
.group-chat-button > .chat-details > .last-message {
    margin: 0;
    font-size: 12px;
    color: $text-grey;
    padding-top: 0;
    padding-bottom: 5px;
    font-weight: 500;
    font-family: $fontFamily, sans-serif;
    @media screen and (min-width: 1025px) {
        color: $white;
    }
}
.group-chat-button .avatar,
.private-chat .avatar {
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: 0.5px solid $white;
}
.group-chat-button .avatar {
    // to change colors
    background: linear-gradient(143.44deg, $avatarBckg1 9.77%, $avatarBckg2 83.11%);
}
.expanded .group-chat-button .avatar,
.no-active-chat .group-chat-button .avatar {
    margin-inline-end: 16px;
    margin-top: 8px;
}
.single-chat-header .group-chat-button .avatar {
    margin-left: 4px;
}
.expanded .single-chat-header .group-chat-button .avatar,
.no-active-chat .single-chat-header .group-chat-button .avatar {
    margin-top: 0;
    margin-right: 0;
}
.single-chat-header .members {
    margin-left: 8px;
    font-size: 12px;
    @media screen and (min-width: 1025px) {
        color: $white;
    }
}
.group-chat-button .avatar > span {
    font-weight: 500;
    font-size: 16px;
    max-height: 18px;
    line-height: 22px;
    color: $black;
    letter-spacing: 1px;
}
.group-chat-button .group-chat-name,
.chat-name p {
    max-width: 140px;
    margin: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @media screen and (min-width: 1025px) {
        color: $white;
        font-weight: 600;
    }
}
.group-chat-button .members-count {
    margin: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    padding: 3px 0;
    color: $text-grey;
    display: none;
    text-align: start;
}
.expanded .group-chat-button .members-count,
.no-active-chat .group-chat-button .members-count {
    display: block;
}
.time-ago {
    font-size: 8px;
}
.last-message {
    font-size: 8px;
}
.unread-total {
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 0;
}
.private-chat .avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.expanded .private-chat .avatar,
.no-active-chat .private-chat .avatar {
    margin-inline-end: 16px;
    margin-top: 8px;
}
.single-chat-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.join-banner {
        height: calc(100% - 44px) !important;
    }
}
.single-chat-container .group-chat-wrapper,
.single-chat-container .private-chat-wrapper {
    justify-content: flex-end;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.single-chat-container .group-chat-wrapper .box > div,
.single-chat-container .private-chat-wrapper .box > div {
    padding-top: 10px;
    height: 100%;
    display: flex;
}
.group-chat-wrapper > div:first-child,
.private-chat-wrapper > .scrollbar {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 0 10px;
}
.group-chat-wrapper .live-wall,
.private-chat-wrapper .live-wall {
    width: 100%;
    max-height: initial;
    margin: 0;
    display: flex;
    flex-flow: column;
    direction: rtl;
    scrollbar-width: thin;
    scrollbar-color: #777081 transparent;
    padding-inline-end: 5px;
    padding-inline-start: 10px;
}
.group-chat-wrapper .scrollbar,
.private-chat-wrapper .scrollbar {
    width: auto !important;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding-inline-start: 5px !important;
    padding-inline-end: 0 !important;

    .box {
        height: 100%;
        position: absolute;
        width: calc(100% - 5px);
        overflow: hidden !important;
    }
}
.chat-message-form {
    max-width: initial;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 1025px) {
        padding: 0 10px;
    }
}
.single-chat-container {
    .new-comment-container {
        height: initial;
        background: #ffffff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin-bottom: 23px;
        position: relative;
    }
}
.new-comment-container {
    .MuiInputBase-root {
        flex: 1;
    }
    button {
        font-family: $fontFamily, sans-serif;
        height: 47px;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0 16px 0 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: $primary;
        text-transform: uppercase;
        cursor: pointer;
    }
}
.new-comment-container .tooltip-container {
    display: none;
    position: absolute;
    right: 15px;
    bottom: 100%;
    background-color: #f5f5f5;
    padding: 8px 16px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid $disabled-text;
}
.new-comment-container .tooltip-container .tooltip {
    position: relative;
}
.new-comment-container .tooltip-container .triangle {
    width: 10px;
    height: 7px;
    position: absolute;
    bottom: -7px;
    right: -8px;
}
.new-comment-container .tooltip p {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    font-weight: 600;
    max-width: 170px;
    margin: 0;
    color: $dark;
}
.new-comment-container button:disabled {
    color: rgba(32, 137, 24, 0.5);
    cursor: initial;
}
.new-comment-container button.send-group-message:disabled svg path {
    fill: rgba(32, 137, 24, 0.5);
    cursor: initial;
}
.new-comment-container button.video-conference-button:hover + .tooltip-container {
    display: block;
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
#chat-messages-container .message {
    display: block;
    direction: ltr;
    margin-bottom: 0;

    &:last-of-type {
        margin: 0;
        padding-bottom: 10px;
    }
    a {
        color: #fbd207;
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        // line-height: 18px;
    }
    a:active {
        color: #00a3e0;
    }
    a:visited {
        color: #db9409 !important;
    }
}
#chat-messages-container .comment-header {
    align-items: initial;
    &.videocall-message {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        margin: 10px 0 20px 0;

        .videocall-message-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .videocall-message-text {
                cursor: pointer;
                text-align: center;
                font-weight: 600;
                font-size: small;
                color: $secondary;
            }

            .videocall-message-outdated {
                text-align: center;
                font-weight: 600;
                font-size: small;
                color: $dark;
                @media screen and (min-width: 1025px) {
                    color: $black;
                }
            }
        }
    }
}
#chat-messages-container .avatar-wrapper {
    min-width: 50px;
}
.messages-wrappper {
    flex: 1;
    margin-inline-end: 14px;
    text-align: start;
    display: flex;
    flex-flow: column;
}
.messages-wrappper p {
    color: $dark;
    font-weight: 500;
}

.messages-wrappper .user-details {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;
}
.avatar-wrapper {
    min-width: 46px;
}
.message .user-avatar,
.group-chat-members-container .user-avatar,
.members-list-event .user-avatar,
.info-container .user-avatar {
    width: 35px;
    height: 35px;
    border: 0.5px solid #ffffff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    margin-inline-end: 10px;
}
.message .user-avatar {
    width: 40px;
    height: 40px;
}
#chat-messages-container .messages-wrappper p:last-child {
    line-height: 14px;
    word-break: break-word;
    word-wrap: break-word;
    //max-width: 292px;
    margin-bottom: 10px;
    @media screen and (min-width: 1025px) {
        line-height: 16px;
    }
}
#chat-messages-container .messages-wrappper .conference-message {
    color: $primary;
    cursor: pointer;
}
.time-wrapper .message-text {
    padding: 0;
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
}
.message.me .avatar-wrapper {
    font-size: 0;
}
.expanded .message.me .avatar-wrapper {
    order: 3;
}
.expanded .message.me .avatar-wrapper .user-avatar {
    margin-inline-end: 0;
    margin-inline-start: 10px;
}
.expanded .message.me .messages-wrappper {
    order: 2;
    text-align: right;
    margin-inline-end: 0;
    margin-inline-start: 14px;
}
.expanded .message.me .time-wrapper {
    order: 1;
}
.message .time-wrapper {
    display: flex;
    align-items: flex-end;
}
.message .messages-wrappper p:last-child {
    text-align: start;
    font-size: 13px;
    @media screen and (min-width: 1025px) {
        font-size: 12px;
    }
}
.expanded .message.me .messages-wrappper p:last-child {
    text-align: right;
}
.single-chat-header,
.opened-chat-container[data-number-chats='0'] .page-title,
.chat-page .page-title {
    display: flex;
    width: 100%;
    background-color: $white;
    height: 48px;
    align-items: center;
    z-index: 2;
    position: absolute;
    left: 0;
    @media screen and (min-width: 1025px) {
        background-color: white;
    }
}
.single-chat-header {
    border-inline-start: 4px solid $primary;
    top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.08);
}
.single-chat-header > div:first-child {
    flex: 1;
    text-align: start;
    display: flex;
}
.single-chat-header .user-avatar {
    width: 40px;
    height: 40px;
    margin-inline-start: 4px;
}
.single-chat-header .group-chat-name {
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 117px;
    margin-inline-start: 8px;
    color: $black;
    font-size: 16px;
    font-weight: 600;
}
.expanded .single-chat-header .group-chat-name {
    max-width: 500px;
}
.single-chat-container .manage-chat,
.single-chat-container .remove-member,
.single-chat-container .leave-member,
.single-chat-container .see-members,
.members-list-event .add-member,
.members-list-event .remove-member,
.members-list-event .leave-member,
.chat-page .cancel-button,
.chat-page .create-button,
.chat-page .update-chat-name,
.edit-user-information .cancel-button,
.edit-user-information .update-button,
#booth-files-dialog .cancel-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    height: 100%;
    cursor: pointer;
}
.single-chat-container .see-members[color='see'] {
    background-color: #fff;
    margin-right: 0;
    @media screen and (min-width: 1025px) {

    }
}
.single-chat-container .see-members[color='see'],
.single-chat-container .manage-chat[color='see'] {
    p {
        color:black;
        font-weight: bold;
    }
    svg {
        fill: black;
    }
}
.single-chat-container .manage-chat p,
.single-chat-container .see-members p {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: $text-grey;
    @media screen and (min-width: 1025px) {
        color: black;
    }
}
.expanded .single-chat-container .manage-chat p,
.expanded .single-chat-container .see-members p {
    font-size: 12px;
}
.button-wrapper {
    height: 100%;
    @media screen and (min-width: 1025px) {
        svg {
            fill: black;;
        }
    }
}
.single-chat-container .chats-members-container {
    position: absolute;
    left: 0;
    width: 323px;
    background-color: #fff;
    height: calc(100% - 49px);
    @media screen and (min-width: 1025px) {
        background-color: $closeButtonBckg;
        color: $white;
    }
}
.expanded .single-chat-container .chats-members-container {
    width: 710px;
}
.single-chat-container .group-chat-members-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.single-chat-container .group-chat-members-container li {
    display: flex;
    align-items: center;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    text-align: left;
}
.chats-members-container .page-title {
    height: 39.5px;
    line-height: 39.5px;
    border-bottom: 0.5px solid rgba(137, 137, 137, 0.5);
    position: relative;
    background-color: #fff;
}
.chats-members-container .page-title h3 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: $primary;
    text-transform: uppercase;
    padding-left: 14px;
}
.chats-members-container .page-title span {
    position: absolute;
    right: 20px;
    top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: $text-grey;
}
.chats-members-container > div:last-child {
    height: 100%;
}
.group-chat-members-container li .manage-chat,
.group-chat-members-container li .leave-member,
.group-chat-members-container li .remove-member,
.members-list-event li .add-member,
.members-list-event li .leave-member,
.members-list-event li .remove-member {
    margin: 0;
    padding: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: $primary;
}
.members-list-event li .add-member {
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
    @media screen and (min-width: 1025px) {
        color: $primary;
        font-weight: 500;
    }
}
.members-list-event li .remove-member {
    text-transform: uppercase;
    font-size: 16px;
}
.group-chat-members-container {
    .remove-member {
        svg {
            @media screen and (min-width: 1025px) {
                fill: $white;
            }
        }
        p {
            margin: 0;
            color: $text-grey;
            font-weight: 500;
            text-transform: uppercase;
            @media screen and (min-width: 1025px) {
                color: $white;
            }
        }
    }
    .manage-chat {
        @media screen and (min-width: 1025px) {
            svg {
                fill: $white;
            }
        }
    }
}
.group-chat-members-container .chat-member-details,
.members-list-event .chat-member-details {
    flex: 1;
    font-size: 0;
    margin-inline-end: 15px;
    text-align: start;
}
.group-chat-members-container .chat-member-details p,
.members-list-event .chat-member-details p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}
.group-chat-members-container .chat-member-details span,
.members-list-event .chat-member-details span {
    font-weight: 500;
    font-size: 12px;
}
.live-wall .empty-space-container {
    flex: 1;
}
.chatsPage .chat-page {
    position: absolute;
    right: 0;
    background-color: #f5f5f5;
    width: 323px;
    z-index: 10;
    height: calc(100vh - 119px);
    top: 0;
    @media screen and (min-width: 1025px) {
        background-color: $closeButtonBckg;
    }
}
.expanded .chatsPage .chat-page {
    width: 710px;
}
.chatsPage .chat-page > div {
    height: 100%;
}
.chat-page .page-title {
    top: 0;
}
.chat-page .new-chat-wrapper {
    background-color: #fff;
    overflow: hidden;
    height: 100%;
    text-align: left;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-flow: column;
    & > div {
        &:first-of-type {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (min-width: 1025px) {
        background-color: $closeButtonBckg;
        position: relative;
    }
}
.chat-page .new-chat-wrapper .input-container {
    padding: 10px 10px 0;
}
.chat-page .new-chat-wrapper .input-container p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding: 0 6px;
    @media screen and (min-width: 1025px) {
        color: $white;
    }
}
.chat-page .new-chat-wrapper .input-container > div {
    margin-top: 0;
    width: 100%;
    @media screen and (min-width: 1025px) {
        fieldset {
            border-color: $white;
        }
    }
}
.chat-page .new-chat-wrapper .input-container .members-chat-wrapper {
    width: 100%;
}
.new-chat-wrapper label,
.new-chat-wrapper input,
.search-here label,
.visitors label,
.booth-manage .MuiOutlinedInput-inputMultiline {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(137, 137, 137, 0.5);
    height: initial;
}
.booth-manage .MuiOutlinedInput-inputMultiline {
    font-size: 14px;
    color: #000000;
}
.new-chat-wrapper label {
    color: $text-grey !important;
    height: 12px;
    font-weight: 500;
    @media screen and (min-width: 1025px) {
        color: $white !important;
    }
}
.new-chat-wrapper label.MuiFormLabel-filled,
.new-chat-wrapper label.Mui-focused {
    color: $secondary !important;
    @media screen and (min-width: 1025px) {
        color: $primary !important;
    }
}
.new-chat-wrapper label.MuiFormLabel-filled + div fieldset {
    border-color: $secondary !important;
    @media screen and (min-width: 1025px) {
        border-color: $primary !important;
    }
}
.new-chat-wrapper input {
    height: 12px;
    font-size: 16px;
    font-weight: 500;
}
.new-chat-wrapper fieldset span {
    padding: 0;
}
.new-chat-wrapper .Mui-focused fieldset {
    border-color: $secondary !important;
    border-width: 1px !important;
    @media screen and (min-width: 1025px) {
        border-color: $primary !important;
    }
}
.new-chat-wrapper .MuiInputBase-fullWidth {
    height: 53px;
}
.new-chat-wrapper label + div:after,
.booth-manage label + div:after,
.search-here label + div:after,
.register-form-wrapper label + div:after {
    color: rgb(137, 137, 137);
    border-bottom-color: rgb(137, 137, 137);
}
.new-chat-wrapper label + div:hover:before,
.booth-manage + div:hover:before,
.register-form-wrapper label + div:hover:before {
    border-bottom-color: rgb(137, 137, 137) !important;
}
.new-chat-wrapper input,
.booth-manage input {
    color: #000 !important;
}
.new-chat-wrapper input {
    @media screen and (min-width: 1025px) {
        color: $white !important;
    }
}
.new-chat-wrapper .m-bottom {
    margin-bottom: 10px !important;
}
.new-chat-wrapper ul {
    padding: 0 10px;
    margin: 0;
    @media screen and (min-width: 1025px) {
        background-color: $black;
        color: $white;
    }
}
.new-chat-wrapper > h5 {
    margin-top: 0;
    margin-bottom: 8px;
    padding-inline-start: 10px;
}
.new-chat-wrapper .members-chat-wrapper {
    flex: 1;
    height: calc(100% - 56px);
}
.chat-page.edit .new-chat-wrapper .members-chat-wrapper {
    padding: 0;
    position: relative;
}
.members-chat-wrapper .members-list-event .chat-member-details p {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
}
.members-list-event {
    list-style-type: none;
}
.members-list-event li {
    display: flex;
    align-items: center;
    padding: 4px 0;
    @media screen and (min-width: 1025px) {
        font-size: 16px;
        color: $white;
    }
}
.members-list-event li .remove-member,
.members-list-event li .leave-member,
.group-chat-members-container li .remove-member,
.group-chat-members-container li .leave-member {
    color: $error-light;
}
.group-chat-members-container li .remove-member {
    margin-inline-end: 10px;
}
.group-chat-members-container li .leave-member {
    margin-inline-end: 0;
}
.chat-page .cancel-button,
.chat-page .create-button,
.chat-page .update-chat-name,
.exhibitor-representative-dialog .cancel-button,
.exhibitor-representative-dialog .save-file,
.edit-user-information .cancel-button,
.edit-user-information .update-button {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    line-height: initial;
}
.chat-page {
    .video-started-wrapper {
        position: absolute;
        top: 0;
        background: $primary;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        color: #ffffff;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        justify-content: space-between;
        align-items: center;
        max-height: 0;
        transition: max-height 0.3s ease-out;

        &.show {
            z-index: 2;
            display: flex;
            max-height: 500px;
            transition: max-height 0.5s ease-in;
        }

        & > div {
            display: flex;
            flex: 1;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .info-video-text {
            display: flex;
            flex: 1 1;
            padding-inline-end: 10px;
            align-items: center;
        }

        .close-info {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 20px;
            justify-content: flex-end;

            p {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
            }
        }

        p {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
        }

        svg {
            margin-inline-end: 12px;
        }
    }

    .new-chat-buttons-container {
        text-align: right;
        margin-top: 30px;
        display: none;

        .video-meetings-info-wrapper {
            display: none;

            @media screen and (min-width: 1025px) {
                display: block;
            }

            & > div {
                display: flex;
                padding: 14px 12px 0;
                margin-top: 14px;
                border-top: 1px solid $disabled-text;

                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 14px;
                    color: $text-grey;
                    flex: 1;
                    text-align: left;
                    @media screen and (min-width: 1025px) {
                        color: $white;
                    }
                }

                svg {
                    margin-inline-end: 12px;
                    fill: $primary;
                }
            }
        }
    }
}
.chat-page .new-chat-buttons-container.create-group,
.chat-page .new-chat-buttons-container.edit-group-chat {
    position: static;
    width: 100%;
    bottom: 0;
    margin: 0;
    display: block;
    padding: 14.5px 0;

    & > div {
        position: relative;

        .info-button {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (min-width: 1025px) {
                display: none;
            }
        }
    }
}
.chat-page .cancel-button,
.edit-user-information .cancel-button {
    color: $text-grey;
    margin-right: 18px;
}
.chat-page .create-button,
.chat-page .update-chat-name {
    color: $primary;
    margin-right: 30px;
}
.chat-page .create-button:disabled,
.chat-page .update-chat-name:disabled,
.chat-page .create-button[disabled],
.chat-page .update-chat-name[disabled] {
    color: rgba(90, 180, 83, 0.5);
    cursor: initial;
}
.exhibitor-representative-dialog .save-file:disabled,
.edit-user-information .update-button:disabled {
    color: rgba(251, 210, 7, 0.5);
    margin-inline-end: 30px;
    cursor: initial;
}
.create-multiple-invitation-codes .update-button,
.create-multiple-invitation-codes .cancel-button {
    color: $dark;
    border: none;
}
.create-multiple-invitation-codes .update-button:disabled {
    color: $muiDisabled;
    margin-inline-end: 0;
    cursor: initial;
    border: none !important;
}
.chatsPage .chat-page.edit {
    left: -4px;
    height: calc(calc(var(--vh, 1vh) * 100) - 170px);
    top: 51px;
    width: 323px;

    @media screen and (min-width: 1025px) {
        height: calc(100vh - 170px);
    }
}
.expanded .chatsPage .chat-page.edit {
    width: 710px;
}
.chat-page.edit > div {
    height: 100%;
}
.chat-name-container.d-flex {
    align-items: center;
}
.all-chats .avatar {
    position: relative;
}
.avatar .online-button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $primary;
    position: absolute;
    right: 0;
    top: 0;
    color: $black;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chats-list-container .last-message {
    font-size: 12px;
    font-weight: 500;
    color: $text-grey;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: start;
    line-height: 16px;
    @media screen and (min-width: 1025px) {
        color: $white;
    }
}
.chats-list-container .last-message.bold {
    font-weight: 600;
}
.top-container .close-component {
    left: -40px;
}
.top-container .close-component img {
    transform: rotate(-90deg);
}
.empty-chat-conversion {
    flex: 1 1;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 10px;
    padding: 0 10px;
    @media screen and (min-width: 1025px) {
        box-shadow: none;
    }
}
.empty-chat-conversion > div:first-child {
    justify-content: center;
    box-shadow: none;
}
.empty-chat-conversion > div:first-child p {
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.video-conference-container {
    position: absolute;
    top: 48px;
    z-index: 50;
    width: calc(100% - 68px);
    height: 252px;
    font-size: 16px;
}
.expanded .video-conference-container {
    width: calc(100% - 270px);
}
.video-conference-container.fullscreen {
    position: fixed;
    left: 0;
    top: 70px;
    width: 100%;
    height: calc(100vh - 118px);
}
.video-conference-container > div,
#otEmbedContainer {
    width: 100%;
    height: 100%;
}
.video-conference-container .conference-buttons-container {
    position: absolute;
    top: 100%;
    background-color: $primary;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
}
.video-conference-container .conference-buttons-container div {
    display: flex;
    align-items: center;
}
.video-conference-container .conference-buttons-container div span {
    font-size: 16px;
    color: $black;
    max-height: 16px;
}
.video-conference-container .conference-buttons-container button {
    font-family: $fontFamily, sans-serif;
    background-color: transparent;
    border: 0;
    outline: 0;
    font-weight: bold;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}
.video-conference-container .conference-buttons-container button svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.video-conference-container .conference-buttons-container button.toggle-fullscreen svg path {
    fill: $black !important;
}
.video-conference-container iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    border: 0;
}
.video-conference-buttons {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0 !important;
    padding: 5px 0;
}
.close-video {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    color: #ffffff;
    padding: 0;
    outline: 0;
    cursor: pointer;
}
.no-active-chat .private-chat-name {
    display: none;
}
.no-active-chat .private-chat-name.full-name {
    display: block;
}

.meeting-button-wrapper {
    .tooltip-container {
        display: none;
        position: absolute;
        background-color: $grey;
        padding: 8px 16px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        bottom: -27px;
        right: -106px;

        p {
            margin: 0;
            font-size: 10px;
            color: #fff;
        }
    }
}

.new-meeting-button {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    background: #FEF6CD;
    border-radius: 6px;
    border: 0;
    outline: 0;
    margin-inline-end: 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    transition: all 200ms;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    span {
        padding-inline-start: 3px;
    }
    &:hover {
        background-color: $secondary;
        color: $white;
        transition: all 200ms;

        & ~ .tooltip-container {
            display: block;

            @media screen and (max-width: 480px) {
                max-width: 300px;
                right: 10px;
                bottom: -43px;
            }
        }
    }

    &:disabled {
        background: #bbbabf;
        color: #fff;

        &:hover {
            cursor: unset;
        }
    }
}

.private-chat-wrapper {
    .chat-banner {
        .chat-banner-info-container {
            display: flex;
            align-items: flex-start;
            .video-started-container {
                display: block;

                svg {
                    display: none;
                }
            }
        }
    }
}
.opened-chat-container .chat-banner {
    z-index: 10;
    top: 0 !important;
    position: relative;

    .chat-banner-info-container {
        display: flex;
        align-items: flex-start;
        .video-started-container {
            display: block;

            svg {
                display: none;
            }
        }
    }
}
.has-banner {
    &.expanded {
        .chats-container {
            padding-top: 48px;
            .single-chat-container .private-chat-wrapper {
                position: relative;
                .chat-banner {
                    top: 0;
                }
                .empty-space-container {
                    margin-bottom: 44px;
                }
            }
        }
    }
    .chats-container {
        .chat-banner {
            top: 48px;
        }
    }
}

.chat-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: $secondary;
    padding: 6px 8px 5px;
    font-size: 14px;
    line-height: 1;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 44px;
    .chat-banner-info-container {
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        // justify-content: space-between;
        padding-inline-end: 10px;
        p {
            margin: 0 auto 0 0;
        }
        .time {
            padding-top: 5px;
            font-weight: 600;
        }
        .video-started-wrapper {
            display: flex;
            align-items: center;

            svg {
                width: 16px;
                height: 16px;
                margin-left: 16px;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
    .chat-banner-actions-container {
        display: flex;
        align-items: center;
        font-size: 20px;
        justify-content: flex-end;
        .button {
            cursor: pointer;
            padding-inline-start: 20px;
            font-weight: 800;
            font-size: 16px;
            padding-inline-end: 4px;
            &:first-child {
                padding-inline-start: 0;
            }
        }
    }
}
.no-meeting .chats-container {
    padding-top: 48px;
}
.no-active-chat.has-banner .chats-container {
    padding-top: 0;
}
#chat-preference {
    & > div {
        @media screen and (max-width: 1024px) {
            padding-inline-start: 40px;
            &.MuiTooltip-touch {
                padding: 8px 8px 8px 40px;
            }
        }
    }
}
@media all and (max-height: 875px) {
    .group-chat-wrapper .bottom-container {
        height: calc(100% - 10px);
    }
    .group-chat-wrapper .bottom-container > div {
        height: 100%;
    }
    //.group-chat-wrapper .live-wall {
    //    height: 100%;
    //    min-height: inherit;
    //}
}
@media screen and (max-width: 1024px) {
    .chat-header-container {
        .dropdown-title {
            color: $black;
        }
        svg {
            &.back-button {
                fill: $black;
            }
        }
    }
    .chat-page.dropdown-component {
        left: 0 !important;
        max-width: initial;
        width: 100%;
        top: 56px;
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
    }
    .video-conference-container.fullscreen {
        top: 56px;
        height: calc(100% - 160px) !important;
    }
    .chats-container {
        height: calc(100% - 105px);
    }
    .chats-container[data-number-chats='0'] {
        padding-top: 0;
    }
    .chats-container[data-number-chats='0'] .page-title {
        background-color: #f5f5f5;
    }
    .chats-container[data-number-chats='0'] .page-title p {
        color: $primary;
        font-weight: 600;
    }
    .opened-chat-container {
        background-color: #ffffff;
    }
    .chat-header-container {
        background-color: $primary;
    }
    .chat-header-container > div p {
        font-weight: 600;
    }
    .group-chat-wrapper > div:first-child,
    .private-chat-wrapper > .scrollbar {
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .single-chat-container .new-comment-container {
        border: 1px solid $disabled-text;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: none;
        margin-bottom: 16px;
    }
    .single-chat-container .chats-members-container,
    .chatsPage .chat-page.edit,
    .chatsPage .chat-page {
        width: 100vw;
        height: calc(calc(var(--vh, 1vh) * 100) - 212px);
    }
    .event-page.hide {
        .single-chat-container .chats-members-container,
        .chatsPage .chat-page.edit {
            height: calc(calc(var(--vh, 1vh) * 100) - 158px);
        }

        .chatsPage .chat-page {
            height: calc(calc(var(--vh, 1vh) * 100) - 105px);

            .new-chat-buttons-container.create-group {
                display: none;
            }
        }
    }
    .single-chat-container .chats-members-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 208px);
    }
    .chatsPage .chat-page {
        height: calc(calc(var(--vh, 1vh) * 100) - 161px);
    }
    .chatsPage .chat-page .new-chat-buttons-container.create-group {
        padding: 14.5px 0;
    }
    .single-chat-header .group-chat-name {
        max-width: 180px;
    }
    .chat-page.edit > div {
        position: relative;
    }
    .chat-page .new-chat-buttons-container {
        position: absolute;
        width: 100%;
        bottom: 0;
        margin: 0;
        display: block;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.08);
        padding: 5.5px 0;
    }
    .chat-page .cancel-button,
    .edit-user-information .cancel-button {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
    }
    .chat-page .update-chat-name {
        font-size: 16px;
        font-weight: bold;
    }
    .chat-page .new-chat-wrapper {
        border-radius: 0;
    }
    .chat-header-container img {
        display: none;
    }
    .chat-header-container img.mobile {
        display: block;
    }
    .event-page.hide-new-chat-message .chat-page.dropdown-component {
        height: calc(calc(var(--vh, 1vh) * 100) - 56px);
    }
    .event-page.hide-new-chat-message .chats-container {
        height: calc(100% - 49px);
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .dropdown-actions-chats {
        display: none;
    }
    .chat-page.dropdown-component.expanded {
        width: 100%;
    }
    .expanded .opened-chat-container {
        max-width: initial;
    }
    .expanded .chatsPage .chat-page,
    .expanded .single-chat-container .chats-members-container,
    .expanded .chatsPage .chat-page.edit {
        width: 100%;
    }
    .expanded #chat-messages-container .messages-wrappper p:last-child {
        max-width: initial;
    }
    .single-chat-header > div:first-child {
        position: relative;
    }
    .single-chat-header .time-ago-wrapper {
        position: absolute;
        right: 0;
        top: 0;
    }
    .expanded .video-conference-container {
        height: 300px;
    }
}
@media screen and (max-width: 767px) {
    .opened-chat-container {
        max-width: initial;
    }
    .chatsPage .chats-list-container {
        width: 68px;
    }
    .no-active-chat .opened-chat-container {
        display: none;
    }
    .chats-list-container .last-message {
        display: none;
    }
    .no-active-chat .chats-list-container .last-message {
        display: block;
        font-size: 14px;
        line-height: 16px;
        max-width: 350px;
        font-family: $fontFamily, sans-serif;
    }
    .no-active-chat .group-chat-button .members-count {
        font-size: 12px;
        line-height: 14px;
    }
    .no-active-chat .chat-details .group-chat-name {
        font-size: 16px;
        line-height: 18px;
    }
    .no-active-chat .private-chat-name {
        font-size: 16px;
        line-height: 18px;
        display: none;
    }
    .no-active-chat .private-chat-name.full-name {
        display: block;
        max-width: 350px;
    }
    .single-chat-container .manage-chat p,
    .single-chat-container .see-members p {
        font-size: 12px;
    }
}
@media screen and (max-width: 479px) {
    .no-active-chat .chats-list-container .last-message {
        max-width: 210px;
    }
}
