@import 'variables.module';

// Positioning
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.override-align-start {
    align-items: flex-start !important;
}

// Color classes
.primary-color {
    color: $primary !important;
}
.secondary-color {
    color: $secondary !important;
}
.dark-color {
    color: $dark;
}
.grey-color {
    color: $text-grey;
}
.error-light {
    color: $error-light;
}
.error-dark {
    color: $error !important;
}
.disabled-text-color {
    color: $disabled-text;
}
.mui-disabled {
    color: $muiDisabled;
}
// Misc
.default-cursor {
    cursor: default !important;
}
.cursor-pointer {
    cursor: pointer;
}
.buttons-divider {
    height: 42px;
    width: 1px;
    margin: 0 12px;
    background-color: #ccc;
}
.error-border {
    border: 1px solid $error !important;
}
.no-margin {
    margin: 0 !important;
}
.no-padding {
    padding: 0;
}
.pt-12 {
    padding-top: 12px;
}
.pb-12 {
    padding-bottom: 12px;
}
.pl-12 {
    padding-left: 12px;
}
.rotate-90deg {
    transform: rotate(90deg);
}
.reverse-rtl {
    direction: ltr !important;
}
.padding-10 {
    padding-bottom: 10px;
}

.padding-20 {
    padding-bottom: 20px;
}

// buttons
.primary-button {
    font-family: $fontFamily, sans-serif;
    background-color: $primary;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    padding: 7px 12px;
    cursor: pointer;
    border: 1px solid $primary;
    outline: 0;
    &:focus {
        border-color: $white;
        box-shadow: 0 4px 10px 0 $secondary;
        &:active {
            border: 2px solid $white;
            padding-top: 5px;
            padding-bottom: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        }
    }
    &:active {
        border: 2px solid $white;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    &:disabled {
        cursor: initial;
        background-color: $disabled-text;
        border-color: $disabled-text;
    }
}

// wall actions - states styles
.wall-action {
    position: relative;
    width: 24px;
    height: 24px;
    &:after {
        content: '';
        width: 35px;
        height: 35px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        display: none;
    }
    @media (hover: hover) {
        &:hover {
            &:after {
                display: block;
                background: $hover-wall-action-primary;
            }
        }
    }
    &:active {
        svg {
            fill: $primary !important;
        }
        &:after {
            display: none;
        }
    }
    &.focus-visible {
        &:after {
            display: block;
            box-shadow: 0px 4px 10px -4px $secondary;
        }
    }
}
.image-explain-platform {
    font-size: 14px;
    text-transform: none !important;
    color: $text-grey;
}
.poll_button {
    font-family: $fontFamily, sans-serif !important;
}
.restyled-save-button {
    font-family: $fontFamily, sans-serif !important;
    & > span {
        span {
            font-weight: 500;
            font-size: 16px;
            max-height: 16px;
        }
    }
    &.Mui-disabled {
        svg {
            fill: rgba(0, 0, 0, 0.26);
        }
    }
}
.MuiSwitch-root {
    .MuiSwitch-colorSecondary {
        &[aria-disabled="false"] {
            .MuiSwitch-thumb {
                background-color: $grey-dark;
            }
            ~ .MuiSwitch-track {
                background-color: $grey;
            }
        }
        &.Mui-checked {
            .MuiSwitch-thumb {
                border: 2px solid $secondary;
                background-color: $primary;
            }
            ~ .MuiSwitch-track {
                background-color: $primary;
                opacity: 1;
            }
        }
    }
}
.MuiFormControlLabel-root {
    &[data-checked="false"] {
        position: relative;
        margin: 0;
        &:before {
            content: '';
            width: 22px;
            height: 22px;
            background: $white;
            border: 2px solid $secondary;
            border-radius: 50%;
            position: absolute;
        }
    }
    &[data-checked="true"] {
        position: relative;
        margin: 0;
        &:before {
            content: '';
            background-color: $primary;
            border: 2px solid $secondary;
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
        &:after {
            width: 12px;
            height: 12px;
            background-color: $secondary;
            content: '';
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
    }
    .MuiRadio-root {
        padding: 0;
        width: 22px;
        height: 22px;
        color: transparent;
    }
}
.MuiButtonBase-root {
    &.MuiCheckbox-root {
        padding: 0;
    }
}
.MuiRadio-root {
    svg {
        display: none;
    }
    & > span {
        &:first-of-type {
            &:before {
                content: '';
                width: 22px;
                height: 22px;
                background: $white;
                border: 2px solid $secondary;
                border-radius: 50%;
                position: absolute;
            }
        }
    }
    &.Mui-checked {
        span {
            &:first-of-type {
                &:before {
                    content: '';
                    background-color: $primary;
                    border: 2px solid $secondary;
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                }
                &:after {
                    width: 12px;
                    height: 12px;
                    background-color: $secondary;
                    content: '';
                    border-radius: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                }
            }
        }
    }
}
.MuiCheckbox-root {
    width: 18px;
    height: 18px;
    margin-inline-end: 12px !important;
    svg {
        fill: $secondary;
    }
    &.Mui-checked {
        background-color: $secondary;
        border: 1px solid $primary;
        svg {
            fill: $primary;
        }
        &.MuiCheckbox-colorSecondary {
            &:hover {
                background-color: $secondary;
            }
        }
        &:hover {
            background-color: $secondary;
        }
    }
}
