@import 'variables.module';

.my-account-page {
    display: flex;
    position: relative;
    .matching-loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .my-account-wrapper {
        width: calc(100vw - var(--sidebar));
        height: calc(100vh - 69px);
        display: flex;
        .account-navigation {
            min-width: 256px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            .MuiAccordionSummary-root {
                min-height: 52px;
                padding: 0 36px;
                &.Mui-expanded {
                    background-color: $primary;
                }
            }
            .MuiAccordionSummary-content {
                margin: 0;
            }
            .MuiAccordion-root {
                margin: 0;
                box-shadow: none;
            }
            p {
                margin: 0;
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
        .aside-account-wrapper {
            background-color: $white-variant;
            flex: 1;
            padding: 24px 24px 21px 0;
            margin-left: 2px;
            .scroll-left-container {
                & > div {
                    max-width: 966px;
                    margin: 0 auto;
                    @media screen and (max-width: 1370px) {
                        max-width: initial;
                        padding-inline-start: 18px;
                    }
                }
            }
            .gdpr-template-wrapper {
                h1, h2 {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0;
                }
                h2 {
                    margin-top: 40px;
                    margin-bottom: 16px;
                }
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 12px;
                    margin-bottom: 24px;
                }
                p, li {
                    font-size: 18px;
                    line-height: 27px;
                    letter-spacing: 0.005em;
                }
            }
            .my-events {
                padding-inline-start: 24px;
                & > div {
                    max-width: 966px;
                    margin: 0 auto;
                    @media screen and (max-width: 1370px) {
                        max-width: initial;
                        padding-inline-start: 18px;
                    }
                    p {
                        font-size: 20px;
                        font-weight: 500;
                        margin: 0;
                        padding-top: 12px;
                    }
                    .events-table {
                        margin-top: 26px;
                        border: 1px solid $disabled-text;
                        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                        border-radius: 6px;
                        .name {
                            flex: 1;
                        }
                        .date {
                            min-width: 206px;
                        }
                        .open {
                            display: flex;
                            min-width: 92px;
                            justify-content: center;
                        }
                        .events-header {
                            display: flex;
                            align-items: flex-start;
                            font-weight: 500;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $grey-dark;
                            background-color: $light-grey;
                            padding: 16.5px 16px;
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                        }
                        .events-list-wrapper {
                            background-color: $white;
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                            .table-row {
                                display: flex;
                                font-weight: 500;
                                font-size: 14px;
                                padding: 13px 16px;
                                border-bottom: 1px solid $disabled-text;
                                &:last-of-type {
                                    border: 0;
                                }
                                &.empty-row {
                                    min-height: 281px;
                                    justify-content: center;
                                    align-items: center;
                                    & > div {
                                        flex-direction: column;
                                        max-width: 345px;
                                        text-align: center;
                                        p {
                                            color: $grey-dark;
                                        }
                                    }
                                }
                                & > div {
                                    display: flex;
                                    align-items: center;
                                    &.date {
                                        justify-content: flex-end;
                                        text-transform: capitalize;
                                    }
                                    &.open {
                                        svg {
                                            cursor: pointer;
                                        }
                                    }
                                }
                                .name {
                                    padding-inline-end: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
        .restyled-save-button {
            &:active,
            &:active:focus {
                background: $secondary;
                box-shadow: none !important;
                span {
                    color: $white !important;
                    svg path {
                        fill: $white !important;
                    }
                }
            }
            @media (hover: hover) {
                &:hover {
                    border: 1px solid $secondary !important;
                    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25) !important;
                    span {
                        color: $secondary;
                        svg path {
                            fill: $secondary;
                        }
                    }
                }
                &:focus {
                    border: 1px solid $grey;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
    }
}
.my-account-component.dropdown-component {
    max-width: 323px;
    overflow: initial;
    top: 53px;
    right: 0;
    left: initial;
}
.my-account-component .myaccount-container {
    background-color: #fff;

    .row:not(:last-of-type) {
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0px 10px 10px -10px $secondary;
            }
        }
    }

    .logout-row {
        // padding: 4px 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                span {
                    font-weight: bold;
                    color: $error;
                }
                .profile-image-container svg {
                    fill: $error;
                    path {
                        fill: $error;
                    }
                }
            }
        }
    }
}
.my-account-component.dropdown-component .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 16px;
    margin: 0;
}
.my-account-component.dropdown-component .row a {
    color: $secondary;
    padding-top: 5px;
    font-weight: 600;
}
.my-account-component.dropdown-component .row button {
    padding: 0;
}
.my-account-component.dropdown-component .row span {
    cursor: pointer;
}
.profile-image-container {
    flex-basis: 25%;
}
.profile-image-container.logout-icon {
    cursor: pointer;
}
.profile-image-container > img {
    margin-right: 30px;
}
.my-account-component.dropdown-component .row:last-of-type {
    border-bottom: none;
}
.my-account-component.dropdown-component .row > div:last-of-type {
    flex: 1;
    text-align: start;
}
.my-account-page h3 {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding-inline-end: 30px;
}
.edit-section-container {
    // MATCHING
    &.event-matching {
        .matching-required-error {
            min-width: max-content;
            min-height: max-content;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: $error;
            margin-bottom: 32px;
            display: block;
        }
        .matching-questions-container {
            min-width: 485px;
            min-height: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 32px;
            .MuiDivider-root {
                width: 100%;
                min-height: max-content;
                margin: 0 0 24px;
                background-color: $disabled-text;
            }
            .question:last-child {
                margin: 0;
            }
            .question {
                width: 100%;
                min-width: 100%;
                min-height: max-content;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0 0 24px;
                .question-requirement {
                    min-height: max-content;
                    height: max-content;
                    margin: 0;
                    font-size: 16px;
                    line-height: 18px;
                    color: $grey-dark;
                    margin-inline-start: 27px;
                }
                .question-title {
                    text-align: left;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    letter-spacing: 0.0015em;
                    color: $dark;
                    padding: 0 0 16px;
                }
                .question-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                section {
                    min-width: 485px;
                    min-height: max-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .form-control-outlined {
                        padding: 8px 20px;
                        border: 2px solid $primary;
                        border-radius: 4px;
                        legend {
                            width: max-content;
                            font-family: $fontFamily, sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: -0.4px;
                            color: $primary;
                            padding: 0 4px;
                            text-align: left;
                        }
                        .MuiFormControlLabel-root {
                            margin-left: -4px;
                            margin-right: 0;
                        }
                    }
                }
            }
            &.error {
                margin-bottom: 24px;
            }
        }
        .restyled-save-button {
            &:active,
            &:active:focus {
                background: $secondary;
                box-shadow: none !important;
                span {
                    color: $white !important;
                    svg path {
                        fill: $white !important;
                    }
                }
            }
            @media (hover: hover) {
                &:hover {
                    border: 1px solid $secondary !important;
                    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25) !important;
                    span {
                        color: $secondary;
                        svg path {
                            fill: $secondary;
                        }
                    }
                }
                &:focus {
                    border: 1px solid $grey;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
    }
}
.edit-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    button {
        svg {
            margin-inline-end: 10px;
        }
        span {
            font-family: 'Avenir', sans-serif;
            font-weight: 500;
            font-size: 16px;
            max-height: 22px;
        }
    }
}
.matching-section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    button {
        padding: 2px 4px 2px 2px;
    }
    h3,
    span {
        white-space: nowrap;
    }
    .dialog-btn-uppercase {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
    }
}
.edit-section-header button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
}
.edit-user-personal-info-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding: 16px;
    .avatar {
        width: 80px;
        height: 80px;
        margin-inline-end: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .upload-new-image {
        margin-inline-start: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        .upload-container {
            position: relative;
        }
        span {
            font-weight: 500;
            color: $black;
            display: flex;
            svg {
                margin-inline-end: 8px;
            }
        }
    }
    .image-container {
        position: relative;
        display: flex;
        padding-bottom: 33px;
        .select-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
            z-index: 2;
        }
        button {
            background-color: transparent;
            border: 0;
            padding: 0;
            outline: 0;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
            display: flex;
            align-items: center;
            svg {
                margin-inline-end: 8px;
            }
        }
    }
    form,
    .edit-form-matching {
        margin: 0;
        padding: 0;
        max-width: 526px;
        .field-container {
            margin-bottom: 26px;
            label {
                font-family: $fontFamily, sans-serif;
            }
            input {
                font-family: $fontFamily, sans-serif;
            }
        }
    }
    .edit-form-matching {
        max-width: initial;
        .MuiFormControlLabel-root {
            margin-left: -4px;
            margin-right: 0;
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &[data-checked="true"] {
                &:before,
                &:after {
                    display: none;
                }
            }
            & > span {
                &:last-of-type {
                    margin-inline-start: 10px;
                    font-family: $fontFamily, sans-serif;
                }
            }
        }
    }
}
.my-account-page ul {
    list-style: none;
    padding-inline-start: 0;
}
.my-account-page ul li p span {
    text-transform: capitalize;
    padding-inline-end: 5px;
}
.my-account-page .image-error {
    width: 300px;
    color: $error-light;
    font-size: 12px;
    font-weight: bold;
    margin-top: 25px;
}
.image-container .remove-avatar {
    color: $error-light;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.image-container .remove-avatar:disabled {
    cursor: initial !important;
}
.user-profile-information > span {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    color: $dark;
}
button.change-password-btn {
    svg {
        width: 24px;
    }
}
.user-profile-information p,
.edit-participant-info-container p {
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: $dark;
    margin: 0;
    padding-top: 10px;
}
.user-profile-information p > span,
.edit-participant-info-container p > span {
    color: $text-grey;
}
.upload-text-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    color: #fff;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.image-container .select-image:hover + .upload-text-container {
    visibility: visible;
}
.edit-user-information .update-button {
    &:not(.create-multiple-invitation-codes) {
        color: $primary;
    }
    &.Mui-disabled {
        color: $disabled-text;
    }
}
.edit-user-information form {
    padding: 0 0 4px 0;
}
.edit-user-information label {
    font-size: unset !important;
}
.edit-user-information #select-country {
    font-size: unset;
    top: 17px;
    color: #726f79;
    opacity: 0.5;
}
.edit-user-information fieldset {
    background-color: transparent;
}
.edit-user-information input :not(.input-container-double) {
    color: $text-grey;
}
.edit-user-information .buttons-actions {
    padding-bottom: 10px;
}
.edit-user-information .buttons-actions .update-button {
    margin-right: 20px;
    padding-right: 0;
    font-size: 16px;
    font-weight: 700;

    &:disabled {
        color: $muiDisabled;
    }
}
@media all and (max-width: 1024px) {
    .change-password-form {
        padding-bottom: 20px !important;
    }
}
.change-password-form > div {
    margin-bottom: 20px;
}
.my-account-page .gdpr-links {
    margin-top: 25px;
}
.my-account-page .gdpr-links p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: $dark;
    font-family: $fontFamily, sans-serif;
}
.my-account-page .gdpr-links ul {
    margin: 0;
}
.my-account-page .gdpr-links a {
    color: $secondary;
    font-size: 14px;
    line-height: 25px;
}
.my-account-page .select-language-container {
    width: 100%;
    margin-top: 25px;
}
.my-account-page .select-language-container span {
    padding-inline-end: 20px;
}
.edit-section-container.event-profile {
    margin-top: 40px;
    margin-bottom: 63px;
    .edit-participant-info-container {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
        padding: 25px 16px;
        form {
            margin: 0;
            padding: 0;
            .field-container {
                max-width: 526px;
                margin: 0;
                label {
                    font-family: $fontFamily, sans-serif;
                }
                & > div {
                    margin-bottom: 26px;
                    border-radius: 3px;
                    &.Mui-error {
                        margin-bottom: 0;
                        ~ p {
                            padding-top: 0;
                        }
                    }
                    &:before,
                    &:after {
                        border-bottom: 0;
                    }
                }
                input {
                    font-family: $fontFamily, sans-serif;
                }
            }
            legend {
                color: $black;
                font-family: $fontFamily, sans-serif;
                font-weight: bold;
            }
            .select-validator {
                label {
                    padding-inline-end: 40px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 95%;
                    color: $grey-dark;
                    font-family: $fontFamily, sans-serif;
                    opacity: 1;
                    &.filled {
                        transform: translate(12px, 10px) scale(0.75);
                    }
                }
                .field-container {
                    & > div {
                        background-color: $white;
                        .MuiSelect-selectMenu {
                            border: 1px solid rgba(0, 0, 0, 0.23);
                            border-radius: 3px;
                        }
                    }
                }
            }
            .account-sdgs {
                max-width: 526px;
                &.shrink-label {
                    legend {
                        width: auto !important;
                    }
                }
                .chips-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    & > div {
                        margin-bottom: 8px;
                    }
                }
                #select-sdgs {
                    padding-right: 40px;
                }
                & > label {
                    color: $grey-dark;
                }
            }
        }
        .registration-radio-group {
            padding: 10px;
            border-color: rgba(0, 0, 0, 0.23);
            border-radius: 3px;
            max-width: 526px;
            label {
                margin: 0;
            }
            span {
                font-family: $fontFamily, sans-serif;
            }
            .MuiRadio-root {
                margin-right: 12px;
            }
        }
    }
    ul {
        margin: 0;
        p {
            padding: 0;
            line-height: 25px;
            display: flex;
        }
    }
}
.edit-section-container.event-profile .gdpr-links {
    margin-top: 30px;
    margin-bottom: 30px;
}
/* My account dropdown - mobile */
.my-account-component.dropdown-component.my-account-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
}
.my-account-component .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
}
.my-account-component .myaccount-container {
    padding: 0;
}
.my-account-component .profile-image-container {
    flex-basis: auto;
}
.my-account-component .profile-image-container > img {
    margin-right: 16px;
}
.my-account-component .row > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my-account-component.dropdown-component .row a {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}
.my-account-component.dropdown-component .row span {
    padding-right: 16px;
    display: inline-flex;
    flex: 1;
    font-weight: 500;
    font-size: 12px;
}
.my-account-component .myaccount-container .row:last-of-type div:last-of-type p {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}
.my-account-component .myaccount-container .row:last-of-type .profile-image-container img {
    margin: 0;
}
.my-events-mobile {
    h3 {
        margin-top: 0;
    }
    .events-list-wrapper {
        label {
            margin: 0 0 10px;
            .MuiTypography-root {
                margin-inline-start: 10px;
            }
        }
        .table-row {
            &.empty-row {
                & > div {
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .my-account-component.dropdown-component {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
}
/* Mobile -start */
@media screen and (max-width: 1024px) {
    .my-account-page {
        padding-top: 56px;
    }
}
@media screen and (max-width: 479px) {
    .my-account-mobile.dropdown-component .row a {
        font-size: 14px;
        flex-flow: row-reverse;
    }
}
@media screen and (max-width: 390px) {
    .my-account-mobile.dropdown-component .row a {
        flex: 1;
    }
}
@media screen and (max-width: 330px) {
    .my-account-mobile.dropdown-component .row span {
        flex: 1;
    }
}
