.metaverse {
  &-container {
    display: flex;
    position: relative;
    width: calc(100% - 403px);

    background-color: #fff;

    &__mobile {
      width: 100%;
    }

    & > iframe {
      height: calc(100vh - 69px);
      flex: 1;

      z-index: 2;
    }
  }

  &-fullscreen-button {
    position: absolute;

    bottom: 88px;
    right: 24px;

    width: 32px;
    height: 32px;

    z-index: 1001;

    &-image {
      padding: 0;
      
      width: 32px;
      height: 32px;
    }
  }
}