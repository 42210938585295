.three-dots {
    position: absolute;
    top: 6.5px;
    z-index: 99999999999;
    right: 28px;
    font-family: "Avenir", sans-serif;
    color: #000;
    font-weight: 500;
    height: 100%;
    text-align: right;
    padding-right: 10px;
    width: 18px;
    background: white;
    font-size: 16px;
}
