@import 'variables.module';
.lobby-page {
    display: flex;
    width: calc(100% - 323px - var(--sidebar));
    background-color: white;
    height: calc(100vh - 69px);
    position: relative;
    z-index: 1;
    #auditorium {
        width: 50%;
        background-color: $white;
        margin-right: 8px;
        border: 1px solid $grey;
        border-radius: 2px;
        @media (hover: hover) {
            &:hover {
                border-color:$primary;
                .door-text-wrapper {
                    position: relative;
                    & > div {
                        &:first-of-type {
                            background: $primary;
                            &:after {
                                display: none;
                            }
                            p {
                                color: $secondary !important;
                            }
                            span {
                                display: block !important;
                                font-size: 16px;
                                position: absolute;
                                bottom: 15px;
                                font-weight: 400;
                                color: $secondary;
                            }
                        }
                    }
                    & > svg {
                        display: block !important;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
        &.disable-lobby-door {
            display: none;
        }
        & > div {
            height: 100%;
        }
    }
    #showfloor {
        width: 50%;
        background-color: $white;
        border: 1px solid $grey;
        border-radius: 2px;
        @media (hover: hover) {
            &:hover {
                border-color:$primary;
                .door-text-wrapper {
                    position: relative;
                    & > div {
                        &:first-of-type {
                            background: $primary;
                            &:after {
                                display: none;
                            }
                            p {
                                color: $secondary !important;
                            }
                            span {
                                display: block !important;
                                font-size: 16px;
                                position: absolute;
                                bottom: 15px;
                                font-weight: 400;
                                color: $secondary;
                            }
                        }
                    }
                    & > svg {
                        display: block !important;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
        &.disable-lobby-door {
            display: none;
        }
        & > div {
            height: 100%;
        }
    }
    #slider-logos {
        width: calc(calc(100vw - var(--sidebar)) - 69.18vw);
        padding: calc(calc(100vw - var(--sidebar)) - 92.9722vw) calc(calc(100vw - var(--sidebar)) - 91.8009vw);
    }
    #show-lobby-video {
        margin-top: 8px;
        flex: 1;
        &.no-hover {
            cursor: initial;
        }
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 3vh 1px $secondary;
            }
        }
    }
    #banner {
        width: calc((100vh - 70px) * 0.1799);
        height: calc((100vh - 70px) * 0.3379);
        left: calc((100vh - 70px) * -0.8601);
        top: calc((100vh - 70px) * 0.1926);
    }
    .lobby-wrapper {
        padding-left: calc(calc(100vw - var(--sidebar)) - 91.8009vw);
        padding-top: calc(calc(100vw - var(--sidebar)) - 92.9722vw);
        padding-bottom: calc(calc(100vw - var(--sidebar)) - 92.9722vw);
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .doors-wrapper {
        display: flex;
        height: 44.232%;
    }
}
.showfloor-page,
.village-page {
    height: calc(100vh - 69px);
    position: relative;
    width: 100%;
    z-index: 1;
}
#show-lobby-video {
    cursor: pointer;
}
#show-lobby-video > .image-preview-video {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid $grey;
    position: relative;
    border-radius: 2px;
}
#show-lobby-video .play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;

    svg {
        width: 58px;
        height: 58px;
        padding: 10px;
        background: $secondary;
        border-radius: 12px;
        path {
            fill: $primary;
        }
    }
}
#info-booth {
    & > div {
        span {
            position: relative;
            .info-booth-wrapper {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $primary;
                color: $black;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: -5px;
                bottom: 0;
                span {
                    font-weight: 500;
                    font-size: 12px;
                    max-height: 12px;
                }
            }
        }
    }
    &.active {
        .info-booth-wrapper {
            background-color: $secondary;
            color: $primary;
        }
    }
}
#slider-logos,
#bannerAuditorium {
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .slick-slider {
        background-color: white;
        height: 100%;
    }
    .slick-slide {
        // this needs to be added because when we have sliders that use "fade"
        // the images will dissapear on RTL languages
        float: left;
        div {
            height: 100%;
        }
    }
    .slick-list {
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slick-track {
        height: 100%;
    }
}
#showfloor:hover,
#auditorium:hover {
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
}
#showfloor .relativeCenter,
#auditorium .relativeCenter {
    height: 100%;
}
#showfloor .relativeCenter.disable-lobby-door,
#auditorium .relativeCenter.disable-lobby-door {
    pointer-events: none;
}
#showfloor a,
#auditorium a {
    display: block;
    height: 100%;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 3vh 1px $secondary-hover;
        }
    }
}
#showfloor a .relativeCenter,
#auditorium a .relativeCenter {
    height: 100%;
}
.show-info-dialog-button {
    width: 100%;
    height: 100%;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 3vh 1px $secondary-hover;
        }
    }
}
#showfloor .relativeCenter:hover,
#auditorium .relativeCenter:hover {
    -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 -20px 30px $secondary-hover;
}
#slider-logos .slick-slide div div {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.exhibitorShowfloorBooth > a {
    height: 100%;
    display: block;
}
.exhibitorShowfloorBooth > a > div {
    height: 100%;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.exhibitorShowfloorBooth .booth-logo {
    height: 85%;
    width: 85%;
    margin: 0 auto;
}
.exhibitorShowfloorBooth .clickable-element {
    display: flex;
    align-items: center;
    justify-content: center;
}
.exhibitorShowfloorBooth:hover .clickable-element {
    box-shadow: 0 0 3vh 1px $secondary-hover;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.lobby-banner-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: perspective(250px) rotateY(3.6deg);
    transform-origin: top;
}
.lobby-page .clickable-element:is(#show-lobby-video) {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 3vh 1px $secondary-hover;
        }
    }
}
#info-booth.clickable-element {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            .hovering-container {
                display: block;
            }
        }
    }
}
#banner {
    mix-blend-mode: multiply;
}
.door-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 1024px) {
        display: none;
    }

    span {
        font-weight: 500;
    }

    & > div {
        &:first-of-type {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url('../Images/auditorium.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, $secondary, $secondary), $primary;
                opacity: 0.7;
            }
            p {
                position: relative;
                z-index: 1;
                font-size: 34px;
                margin: 0;
            }
        }
    }
    &.showfloor {
        & > div {
            &:first-of-type {
                background-image: url('../Images/showfloor.png');
            }
        }
        .bottom-wrapper {
            text-align: left;
            padding: 13px 12px;
        }
    }
    .bottom-wrapper {
        width: 100%;
        padding: 6px 12px;
        p {
            font-weight: 500;
            font-size: 14px;
            color: $black;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            &.number-videos {
                padding: 14px 0;
                text-align: left;
            }
        }
        span {
            font-weight: 500;
            font-size: 12px;
            color: $grey-dark;
        }
    }
    .status-timeslot {
        display: flex;
        justify-content: space-between;
        .status-timeslot-content {
            text-align: left;
        }
    }
}
.status-button {
    display: flex;
    align-items: center;
    span {
        background-color: $blue;
        font-weight: 500;
        font-size: 14px;
        color: $black !important;
        text-transform: uppercase;
        position: relative;
        padding: 10px 11px 10px 21px;
        border-radius: 6px;
        line-height: 95.9%;
        &:before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $black;
            position: absolute;
            left: 8px;
        }
    }
    &.upcoming-button {
        span {
            background-color: $white;
            text-transform: none;
            &:before {
                background-color: $primary;
            }
        }
    }
}
@media all and (max-height: 800px) {
    .program-entry .speakers-name {
        line-height: initial;
    }
    .program-entry .program-container {
        padding-bottom: 10px;
    }
    .networking-container .participant-avatar {
        margin-right: 10px;
    }
}

/* Lobby page - mobile */
.lobby-mobile #side-menu-mobile {
    display: none;
}
.lobby-mobile .lobby-page {
    display: none;
}

// Matching - Participant Modal
.matching-dialog-paper {
    min-width: 720px;
    border-radius: 6px !important;
    // Tablet
    @media all and (max-width: 768px) and (max-height: 1024px) {
        min-width: 100%;
        min-height: 100%;
        border-radius: 0px !important;
        overflow: hidden;
    }
    .matching-form-wrapper {
        width: 100%;
        padding: 0;
        overflow: hidden;
        .loading-container {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9;
            background-color: #fff;
        }
        // Tablet
        @media all and (max-width: 768px) and (max-height: 1024px) {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        #form-dialog-title {
            z-index: 1;
            padding: 32px 24px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            h2 {
                flex: 1;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                color: $dark;
            }
            .matching-remove {
                width: max-content;
                min-width: 24px;
                border: none;
                position: absolute;
                top: 10px;
                right: 12px;
                background-color: transparent;
                padding: 0;
                .MuiButton-label {
                    max-width: 24px;
                }
                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }
            &.mobile-safari {
            // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    padding: 24px !important;
                }
            }
            // Mobile
            @media all and (max-width: 480px) and (max-height: 926px) {
                display: flex;
                justify-content: center;
                h2 {
                    flex: 1;
                    position: relative;
                    font-size: 16px;
                    line-height: 20px;
                    .noResultsTitle {
                        max-width: 300px;
                        padding-left: 24px;
                    }
                }
                .matching-remove {
                    min-width: 24px;
                    border: none;
                    position: absolute;
                    top: 50% !important;
                    left: 27px;
                    transform: translate(-50%, -50%);
                    background-color: transparent;
                    padding: 0;
                    .MuiButton-label {
                        max-width: 24px;
                    }
                    &:hover,
                    &:focus {
                        background-color: transparent;
                    }
                    &.noResultsCross {
                        top: 40px !important;
                        left: 16px;
                    }
                }
            }
            // Tablet
            @media all and (max-width: 768px) and (max-height: 1024px) {
                width: 100%;
                h2 {
                    position: relative;
                    line-height: 20px !important;
                    padding: 0 54px;
                }
                .matching-remove {
                    min-width: 24px;
                    border: none;
                    position: absolute;
                    top: 50% !important;
                    left: 27px;
                    transform: translate(-50%, -50%);
                    background-color: transparent;
                    padding: 0;
                    .MuiButton-label {
                        max-width: 24px;
                    }
                    &:hover,
                    &:focus {
                        background-color: transparent;
                    }
                    &.noResultsCross {
                        top: 40px !important;
                        left: 27px;
                    }
                }
            }
            &.question-answering {
                padding: 16px 24px 0 24px !important;
                // Tablet
                @media all and (max-width: 768px) and (max-height: 1024px) {
                    padding: 20px 28px 5px 28px !important;
                }
                &.mobile-safari {
                // Mobile
                    @media all and (max-width: 480px) and (max-height: 926px) {
                        padding: 6px 24px 0 !important;
                    }
                }
                h2 {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    line-height: 40px;
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        line-height: 20px;
                    }
                    .matching-counter-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 250px;
                        min-height: 20px;
                        height: max-content;
                        // Tablet
                        @media all and (max-width: 768px) and (max-height: 1024px) {
                            margin-top: 8px;
                        }
                        // Mobile
                        @media all and (max-width: 480px) and (max-height: 926px) {
                            margin-top: 4px;
                        }
                        .counter-numbers {
                            min-width: 18px;
                            min-height: 16px;
                            margin: 0 4.5px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 95.9%;
                            color: $dark;
                            &:first-child {
                                margin: 0 4.5px 0 0;
                            }
                            &:last-child {
                                margin: 0 0 0 4.5px
                            }
                            &.active {
                                background: $primary;
                                border: 2px solid $primary;
                                border-radius: 7px;
                                color: $white;
                                margin: 0 0 2px 0;
                            }
                        }
                    }
                    .required-label {
                        padding-bottom: 3px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                        color: $grey-dark;
                    }
                }
            }
            &.received-response {
                padding: 32px 24px !important;
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    padding: 0 24px !important;
                }
                h2 {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $dark;
                    .noResultsTitle {
                        max-width: 506px;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                    }
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        position: relative;
                        line-height: 20px !important;
                        padding: 0 54px;
                        .noResultsTitle {
                            max-width: 506px;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        .matching-remove {
                            position: absolute;
                            top: 50% !important;
                            left: 27px;
                            transform: translate(-50%, -50%);
                        }
                    }
                    // Mobile
                    @media all and (max-width: 480px) and (max-height: 926px) {
                        &.noResultsTitle {
                            padding: 24px !important;
                        }
                        padding: 24px 24px 24px 34px !important;
                    }
                }
            }
        }
        
        #form-dialog-content {
            width: 100%;
            min-height: 382px;
            max-height: 382px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-x: hidden;
            padding: 16px 82px;
            border: 1px solid $disabled-text;
            // Chrome and others
            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: #777081;
                border: 1px solid #FFFFFF;
                border-radius: 8px;
            }
            // Firefox specific scrollbar
            scrollbar-width: thin;
            scrollbar-color: #777081 transparent;

            #form-dialog-secondary {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.0015em;
                color: $dark;
            }

            &.is-loading {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                min-height: 382px;
                .spinner {
                    position: relative;
                }
            }

            &.show-results {
                position: relative;
                min-height: 382px;
                border-bottom: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px;
                background: $white-variant;
                .btw-responsive-text {
                    display: none;
                }
                // Tablet
                @media all and (max-width: 768px) and (max-height: 1024px) {
                    .btw-responsive-text {
                        min-height: max-content;
                        display: flex;
                        font-size: 16px;
                        line-height: 18px;
                        color: $grey-dark;
                        text-align: center;
                        // Tablet
                        @media all and (max-width: 768px) and (max-height: 1024px) {
                            position: absolute;
                            bottom: 32px;
                            max-width: 393px;
                        }
                        // Mobile
                        @media all and (max-width: 480px) and (max-height: 926px) {
                            position: unset;
                            padding: unset;
                        }
                    }
                }
                .matches-square {
                    min-height: 324px;
                    min-width: 324px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    background: $white-variant;
                    margin: 0;
                    .tablet-start-chat {
                        display: none;
                    }
                    // Mobile
                    @media all and (max-width: 767px) and (max-height: 926px) {
                        margin: 52px 0 32px 0;
                        .tablet-start-chat {
                            display: none !important;
                        }
                    }
                    // Tablet
                    @media all and (max-width: 1024px) and (max-height: 1366px) {
                        position: relative;
                        .tablet-start-chat {
                            z-index: 4;
                            position: absolute;
                            top: -3px;
                            right: -178px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            max-width: 150px;
                            min-height: 150px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 95.9%;
                            color: $dark;
                            svg {
                                margin-bottom: 10px;
                            }
                        }
                    }
                    &.centered {
                        justify-content: center;
                    }
                    .user-main-card {
                        z-index: 3;
                        width: 323px;
                        height: 108px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
                        background-color: $white;
                        padding: 8px;
                        &:not(:last-child) {
                            border-bottom: 1px solid $disabled-text;
                        }
                        &.display-dialog-card {
                            overflow: hidden;
                            .bottom-row {
                                background-color: $white;
                                padding-top: 2px;
                                padding-left: 72px;
                                box-shadow: 0 10px 5px 15px $white;
                            }
                        }
                        .user-container {
                            display: flex;
                            &.is-online {
                                .participant-avatar {
                                    &:after {
                                        background: linear-gradient(137.73deg, $gradientMenu1 -15.66%, $gradientMenu2 43.77%, $gradientMenu3 100.28%);;
                                    }
                                }
                            }
                            &.is-offline {
                                .participant-avatar {
                                    &:after {
                                        background: $grey;
                                    }
                                }
                            }
                            .participant-avatar {
                                min-width: 55px;
                                min-height: 55px;
                                box-shadow: none;
                                margin-inline-end: 8px;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    width: 16px;
                                    height: 16px;
                                    background: linear-gradient(137.73deg, $gradientMenu1 -15.66%, $gradientMenu2 43.77%, $gradientMenu3 100.28%);
                                    border: 3.5px solid $white;
                                    border-radius: 50%;
                                    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
                                }
                            }
                            .user-details {
                                flex: 1;
                                display: flex;
                                border: 0;
                                padding-top: 5px;
                                position: relative;
                                text-align: start;
                                .user-name {
                                    width: 100%;
                                    .user-name-top {
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 95.9%;
                                        letter-spacing: -0.03em;
                                        color: $dark;
                                    }
                                    .user-name-bottom {
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 14px;
                                        letter-spacing: -0.4px;
                                        color: $grey-dark;
                                    }
                                }
                                .participant-button-chat {
                                    width: 80px;
                                    height: 37px;
                                    min-height: 37px;
                                    border: 0;
                                    outline: 0;
                                    background-color: transparent;
                                    cursor: pointer;
                                    div {
                                        height: 100%;
                                        padding-right: 0;
                                        width: 100%;
                                        position: relative;
                                        svg {
                                            width: 100%;
                                            filter: drop-shadow(0 2px 4px rgba(0,0,0,.25));
                                            @media all and (max-width: 1024px) and (max-height: 1366px) {
                                                filter: unset;
                                            }
                                        }
                                        span {
                                            position: absolute;
                                            left: 50%;
                                            top: 12px;
                                            transform: translateX(-50%);
                                            text-transform: uppercase;
                                            font-size: 14px;
                                            line-height: 20px;
                                            letter-spacing: 0.0125em;
                                            color: $black;
                                            font-weight: 700;
                                            font-family: $fontFamily, sans-serif;
                                        }
                                    }
                                    &:disabled {
                                        cursor: initial;
                                    }
                                }
                            }
                        }
                        .bottom-row {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding-bottom: 8px;
                            padding-right: 5px;
                            padding-left: 5px;
                            .match-level {
                                width: 100%;
                                max-width: 60px;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 12px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                letter-spacing: -0.4px;
                                color: $primary;
                                text-transform: uppercase;
                                margin-right: 7px;
                                &.medium {
                                    color: $secondary;
                                }
                            }
                            .social-add {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .match-social {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    .social-button {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 20px;
                                        &:last-child {
                                            margin: 0;
                                        }
                                    }
                                }
                                .participant-button-add {
                                    border: 0;
                                    outline: 0;
                                    background-color: transparent;
                                    cursor: pointer;
                                    &:disabled {
                                        cursor: initial;
                                    }
                                }
                            }
                        }
                    }

                    .one-empty,
                    .two-empty,
                    .all-empty {
                        z-index: 4;
                        width: 323px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 95.9%;
                        color: $grey-dark;
                        border: 1px dashed $grey;
                        border-radius: 6px;
                        background: $white;
                        display: flex;
                        align-items: center;
                    }

                    .one-empty {
                        height: 108px;
                        padding: 28px 24px;
                    }

                    .two-empty {
                        height: 216px;
                        padding: 32px 24px;
                    }

                    .all-empty {
                        height: 108px;
                        margin-top: 24px;
                        padding: 6px 12px;
                        line-height: 16px;
                    }
                }
            }

            &.content-answering {
                width: 100%;
                min-height: 380px;
                // Tablet
                @media all and (max-width: 768px) and (max-height: 1024px) {
                    padding: 8px 86px 24px 86px !important;
                }
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    padding: 0 18px 16px 18px !important;
                }
            }
            // Tablet
            @media all and (max-width: 768px) and (max-height: 1024px) {
                max-height: unset;
                padding: 75px 101px 60px 101px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-top-color: transparent;
                border-left-color: transparent;
                border-right-color: transparent;
            }
            // Mobile
            @media all and (max-width: 480px) and (max-height: 926px) {
                padding: 16px 16px 44px 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-top-color: transparent;
                border-left-color: transparent;
                border-right-color: transparent;
            }
            .matching-intro-header {
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.005em;
                color: $dark;
            }
            .matching-user-cards {
                position: relative;
                width: max-content;
                min-height: max-content;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 55px auto;
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    margin: 26px auto 68px auto;
                }
                .user-main-card {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    z-index: 3;
                    width: 323px;
                    height: 108px;
                    border-radius: 6px;
                    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
                    background-color: $white;
                    padding: 8px;
                    &.display-dialog-card {
                        overflow: hidden;
                        .bottom-row {
                            background-color: $white;
                            padding-left: 72px;
                            box-shadow: 0 10px 5px 15px $white;
                        }
                    }
                    .user-container {
                        display: flex;
                        .participant-avatar {
                            box-shadow: none;
                            margin-inline-end: 8px;
                            &:after {
                                content: '';
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                width: 16px;
                                height: 16px;
                                background: $blue;
                                border: 3.5px solid $white;
                                border-radius: 50%;
                                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
                            }
                        }
                        .user-details {
                            flex: 1;
                            display: flex;
                            border: 0;
                            padding-top: 5px;
                            position: relative;
                            text-align: start;
                            .user-name {
                                width: 100%;
                                .user-name-top {
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 95.9%;
                                    letter-spacing: -0.03em;
                                    color: $dark;
                                }
                                .user-name-bottom {
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 14px;
                                    letter-spacing: -0.4px;
                                    color: $grey-dark;
                                }
                            }
                        }
                    }
                    .bottom-row {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-bottom: 8px;
                        padding-right: 8px;
                        padding-left: 8px;
                        .match-level {
                            width: 44px;
                            height: 24px;
                            padding: 4px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 12px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: -0.4px;
                            color: $primary;
                            text-transform: uppercase;
                            margin-right: 18px;
                        }
                        .social-add {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 4px 0 0;
                            .match-social {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                .social-button {
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 20px;
                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .user-empty-card {
                    position: absolute;
                    z-index: 2;
                    width: 323px;
                    height: 108px;
                    border-radius: 6px;
                    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
                    background: $white;
                    transform: translate(14px, 15px);
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        transform: translate(5px, 20px);
                    }
                }
                .user-empty-card:nth-of-type(2) {
                    position: absolute;
                    z-index: 1;
                    width: 323px;
                    height: 108px;
                    border-radius: 6px;
                    background: $white-variant;
                    transform: translate(28px, 30px);
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        transform: translate(10px, 40px);
                    }
                }
            }
            .matching-intro-skip {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: $grey-dark;
            }
            .question {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .question-title {
                    text-align: left;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    letter-spacing: 0.0015em;
                    color: $dark;
                    margin: 16px 0;
                }
                .question-wrapper {
                    width: 100%;
                    label {
                        span {
                            &:first-of-type {
                                margin: 0 !important;
                            }
                        }
                    }
                }
                section {
                    width: 100%;
                    min-height: max-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .form-control-outlined {
                        height: max-content;
                        padding: 8px 20px;
                        border: 2px solid $primary;
                        border-radius: 4px;
                        legend {
                            width: max-content;
                            font-family: $fontFamily, sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: -0.4px;
                            color: $primary;
                            padding: 0 4px;
                            text-align: left;
                        }
                        .MuiFormControlLabel-root {
                            margin-left: -4px;
                            margin-right: 0;
                            margin-bottom: 10px;
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            &[data-checked="true"] {
                                &:before,
                                &:after {
                                    display: none;
                                }
                            }
                            & > span {
                                &:last-of-type {
                                    margin-inline-start: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #form-dialog-actions {
            width: 100%;
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.is-loading {
                padding: 24px 24px;
            }
            &.show-results {
                padding: 37.5px 24px;
                background: $white;
                box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
                border-top: 1px solid $disabled-text;
                .matching-note {
                    display: flex;
                    font-size: 16px;
                    line-height: 18px;
                    color: $grey-dark;
                    text-align: center;
                    .note-secondary-bold {
                        font-weight: 700;
                        color: $secondary;
                    }
                }
                .iam-in-btn {
                    display: none;
                }
                // Tablet
                @media all and (max-width: 768px) and (max-height: 1024px) {
                    padding: 24px;
                    box-shadow: none;
                    .iam-in-btn {
                        display: flex;
                        padding: 13px 77.5px;
                    }
                    .matching-note {
                        display: none;
                    }
                }
                // Mobile
                @media all and (max-width: 480px) and (max-height: 926px) {
                    padding: 12px;
                    .iam-in-btn {
                        display: flex;
                    }
                    .matching-note {
                        display: none;
                    }
                }
            }
            // Mobile
            @media all and (max-width: 480px) and (max-height: 926px) {
                padding: 14px 20px;
            }
            .doit-later-btn,
            .iam-in-btn,
            .confirm-btn,
            .cancel-btn {
                margin: 0 8px;
                .MuiButton-label {
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    text-align: center;
                    text-transform: uppercase;
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        white-space: nowrap;
                    }
                }
                &:disabled {
                    opacity: 0.5;
                }
            }
            .doit-later-btn {
                padding: 12.5px 30px;
                border-color: $grey;
                border-radius: 6px;
                .MuiButton-label {
                    color: $dark;
                }
            }
            .cancel-btn,
            .confirm-btn {
                border-color: $grey;
                border-radius: 6px;
                .MuiButton-label {
                    color: $dark;
                }
            }
            .confirm-btn {
                padding: 12.5px 49.5px;
                
            }
            .cancel-btn {
                padding: 12.5px 43.5px;
            }
            .go-back-btn {
                padding: 12.5px 54px;
                border-color: $grey;
                border-radius: 6px;
                .MuiButton-label {
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    text-align: center;
                    text-transform: uppercase;
                    color: $dark;
                    // Tablet
                    @media all and (max-width: 768px) and (max-height: 1024px) {
                        white-space: nowrap;
                    }
                }
                &:disabled {
                    opacity: 0.5;
                }
            }
            .iam-in-btn {
                padding: 13.5px 54px;
                background: $secondary;
                border-color: transparent;
                border-bottom-left-radius: 0px !important;
                border-radius: 6px;
                .MuiButton-label {
                    color: $white;
                }
            }
        }
    }
}
