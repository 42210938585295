.pwa-banner {
  z-index: 99;
 display: flex;width: 100%;
  background: #fbd207;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  top: 80px;
  position: fixed;
  height: 83px;
button {
  cursor: pointer;
  background: #000000;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #fbd207;
  padding: 5px 15px;
  border-radius: 30px;
  line-height: 20px;
  :hover {
    background: #fff;

  }
}

  p{
    color: #000;
    font-size: 14px;
    font-weight: 500;;
  }
}