.description-text-wall.link-helper {
    a {
        color: #00A3E0;
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        // line-height: 18px;
    }
    a:active {
        color: #FBD207;
    }
    a:visited{
        color:#6624D1 !important;

    }
}
