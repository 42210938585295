@import './variables.module';

.left-sidebar {
    background-color: #F7F7F8;
    border-right: 1px solid #777081;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 69px);
    width: 80px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .menu-items {
        flex: 1;
        .button-link {
            font-weight: 500;
            font-size: 12px;
            color: black;
            text-transform: uppercase;
            padding: 10px 4px 10px 0;
            cursor: pointer;
            text-align: center;

            outline: 0;
            &.active {
                background-color: $primary;
                color: $secondary;
                border-left-color: $primary;
                a {
                    color: $secondary;
                }
            }
            @media (hover: hover) {
                &:hover {
                    border-left-color: $primary;
                }
                &:focus:not(.active) {
                    border-left-color: $blue;
                    background-color: $blue;
                    &#contacts {
                        svg {
                            path {
                                stroke: $black;
                            }
                        }
                    }
                    &#meetings {
                        svg {
                            path {
                                stroke: $black;
                                &:first-of-type {
                                    fill: $black;
                                    stroke: none;
                                }
                                &:last-of-type {
                                    fill: $black;
                                    stroke: none;
                                }
                            }
                        }
                    }
                    p, a {
                        color: $black;
                    }
                    svg {
                        fill: $black;
                    }
                    &#info-booth {
                        .info-booth-wrapper {
                            background-color: $secondary;
                            color: $white;
                        }
                    }
                    &#event-archive {
                        svg {
                            g {
                                path {
                                    fill: $secondary;
                                    &:first-of-type {
                                        fill: $blue;
                                    }
                                }
                            }
                            & > path {
                                fill: $secondary;
                            }
                        }
                    }
                }
            }
            .button-link-label {
                margin: 0;
                padding-top: 8px;
            }
            a {
                color: $white;
            }
        }
    }
    .powered-by {
        padding: 0 4px 10px;
        text-align: center;
        span {
            text-transform: uppercase;
            font-size: 9px;
            color: white;
            display: block;
            padding-bottom: 8px;
        }
    }
    #chats {
        position: relative;
        &.active {
            div {
                background-color: $secondary;
            }
            & > span {
                span {
                    color: $primary;
                }
            }
        }
        & > span {
            position: relative;
            font-size: 0;
            span {
                color: $black;
            }
        }
        div {
            height: 18px;
            width: 18px;
            position: absolute;
            left: 12px;
            bottom: -5px;
            border-radius: 50%;
            color: white;
            background-color: $primary;
            font-weight: 500;
        }
    }
    @media screen and (min-width: 1025px){
        .meetings,
        .contacts-page {
            .page-title {
                background-color: $pageTitleBck;
                text-align: left;
                padding-inline-start: 16px;
                h3 {
                    color: $primary;
                }
            }
        }
        .auditoriums-program {
            .page-title {
                background-color: $pageTitleBck;
            }
        }
    }
}