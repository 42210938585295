@import '../../CSS/_variables.module';

.notifications-page.dropdown-component {
    max-width: 323px;
    overflow: initial;
    background-color: #f5f5f5;
    height: auto;
    top: 50px;
    left: auto;
    right: 0;
}
.notifications-page .notifications-container {
    padding: 10px 0;
    background-color: #fff;
    overflow: hidden;
}
.notifications-page .notifications-container.active-scrollbar {
    max-height: 235px;
}
.notifications-page .notifications-container.active {
    max-height: calc(100vh - 185px);
    padding: 10px 0;
}
.notifications-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.notifications-list.active {
    height: auto;
    overflow-y: visible;
    background-color: transparent;
}
.notifications-list li {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $fontFamily, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    .user-name {
        font-weight: 600;
    }
    .single-notification-text {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.notifications-list li:hover {
    background-color: #efeef1;
}
.single-notification-text {
    display: flex;
    align-items: center;
}
.notifications-list li:last-of-type .single-notification-text {
    margin: 0;
}
.contacts-wrapper {
    flex: 1;
    text-align: start;
}
.contacts-wrapper .user-name {
    margin: 0;
    color: $secondary;
}
.contacts-wrapper span:last-of-type {
    margin-inline-start: 3px;
}
.date-time {
    font-family: $fontFamily, sans-serif;
    font-weight: 500;
    font-size: 10px;
    color: $text-grey;
    margin-left: 20px;
    white-space: nowrap;
}
// See all notifications
.notifications-page .see-all-notifications {
    margin-top: 0;
    box-shadow: inset 0px 6px 14px rgba(16, 16, 16, 0.14) !important;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            background-color: $secondary-light;
        }
    }
}
.see-all-notifications p {
    padding: 13px 0;
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: $secondary;
    margin: 0;
    text-align: center;
    cursor: pointer;
}
// Notifications mobile
.notifications-page.notifications-mobile {
    max-width: initial;
    width: 100%;
    top: 56px;
}
.notifications-mobile .notifications-container.active {
    max-height: calc(100vh - 152px);
}
.notifications-mobile .page-title {
    height: 48px;
    line-height: 48px;
    text-align: left;
    padding-left: 16px;
     h3 {
        font-weight: 600;
        font-size: 16px;
     }
}
.notifications-mobile .notifications-list li {
    font-size: 14px;
}
.notifications-mobile .notifications-list li .date-time {
    font-size: 12px;
}
@media screen and (min-width: 1025px) {
    .notifications-page.dropdown-component {
        z-index: 6;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
}

// Smart Notifications
.go-onDemand {
    font-weight: 700;
    font-size: 12px;
    color: #000;
    line-height: 95.9%;
    background: #fbd207;
    border-radius: 30px;
    display: flex;
    width: 62px;
    align-items: center;
    justify-content: center;
    height: 27px;
    cursor: pointer;
}
.user-name.platform-notf-helper {
    margin: 0 !important;
}

@media screen and (min-width: 1900px) {
    .go-onDemand {
        padding-top: 5px !important;
    }
}
