@import 'variables.module';

.event-actions-container.swipeable-drawer {
    position: absolute;
    right: 0;
    transform: rotate(-90deg);
    transform-origin: right bottom;
    top: 34px;
    z-index: 2;
}
.event-actions-container.swipeable-drawer .drawer-buttons-container {
    display: flex;
}
.event-actions-container .purple-background {
    background-color: $secondary;
    color: white;
    font-weight: 700;
    font-family: $fontFamily, sans-serif;
}
.event-actions-container .purple-background:hover {
    background-color: $secondary;
}
.event-actions-container .green-background {
    background-color: $primary;
    color: white;
    font-weight: 700;
    font-family: $fontFamily, sans-serif;
}
.event-actions-container .green-background:hover {
    background-color: $primary;
}
.wall-and-networking-container {
    position: absolute;
    right: 0;
    z-index: 9;
    height: calc(100vh - 70px);
    width: 0;
    overflow: hidden;
    opacity: 0;
    top: 0;
}
.wall-and-networking-container.open {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    width: 363px;
    max-width: 363px;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.wall-and-networking-container .tabs-container {
    z-index: 11;
    position: relative;
    margin-left: auto;
    background-color: $white;
    width: 100%;
    height: 48px;
    display: flex;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid $disabled-text;
}
.wall-and-networking-container > div {
    position: relative;
    padding-left: 40px;
    height: 100%;
}
.wall-and-networking-container .tab {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    width: 50%;
}
.wall-and-networking-container .tab.networking-tab span:first-child > span {
    padding: 0;
}
.wall-and-networking-container .live-wall-tab.active,
.wall-and-networking-container .networking-tab.active {
    color: $secondary;
    font-weight: 500;
    font-family: $fontFamily, sans-serif;
    font-size: 14px;
    line-height: 22px;
    background-color: $primary;
}
.close-dropdown,
.close-component {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #cacbd0;
    border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 0;
    svg {
        fill: black;
    }
}
.auditorium-sidebar-close-dropdown {
    left: -40px;
}
.scrollbar > div:nth-child(3) {
    left: 2px;
    width: 4px !important;
}
.grey-scroll {
    border-radius: 10px;
}
.custom-scroll-container.spinner-wrapper {
    position: relative;
    background-color: $disabled-text;
}
.wall {
    padding-top: 10px;
}
.wall-container {
    .custom-scroll-container {
        height: calc(100vh - 192px);
        position: relative;
    }
    &.wall-moderation {
        .custom-scroll-container {
            height: calc(100vh - 199px - var(--moderation));
        }
    }
    &.closed-wall {
        .custom-scroll-container {
            height: calc(100vh - 153px);
            position: relative;
            .comments-list-container {
                &:after {
                    display: none;
                }
            }
            .post-comments {
                &[data-empty='0'] {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    &.edit-mode-active {
        .custom-scroll-container {
            height: calc(100vh - 212px);
        }
    }
}
.scrollbar .box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    width: 100%;
    overflow-x: hidden !important;
    &::-webkit-scrollbar {
        width: 0;
    }
    scrollbar-width: none;
}
.button-tab.tab {
    text-transform: uppercase;
    width: 50%;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    font-weight: 500;
}
.button-tab.live-wall-tab,
.button-tab.video-wall-tab {
    float: left;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 12px 10px -10px $secondary;
        }
    }
}
.button-tab.networking-tab {
    float: right;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 12px 10px -10px $secondary;
        }
    }
}
.button-tab.networking-tab .restricted-network {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.booth-wall-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    height: calc(100vh - 70px);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    width: 323px;
    display: flex;
    flex-flow: column;
    background-color: #f5f5f5;
    box-shadow: -6px 6px 14px rgba(16, 16, 16, 0.14);
    .wall-container {
        .sort-container {
            span {
                text-align: center;
                font-size: 13px;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .networking-container {
        background-color: #fff;
        .networking-wrapper {
            background-color: transparent;
        }
    }
}
.booth-wall-container.hidden {
    width: 0;
    .booth-wall-header {
        font-size: 0;
    }
}
.booth-wall-container .booth-wall-header {
    width: 100%;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark;
    font-weight: 500;
    font-size: 16px;
    font-family: $fontFamily, sans-serif;
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    &.demand {
        background-color: $primary;
    }
    .tab {
        text-transform: uppercase;
        width: 50%;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        height: 44px;
        color: $dark;
        @media all and (max-width: 1024px) {
            color: $grey;
        }
        &.active {
            color: $secondary;
            background-color: $primary;
        }
    }
}
.booth-wall-container .booth-wall-subheader {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    margin-left: auto;
    width: 100%;
    min-height: 44px;
    display: flex;
    align-items: center;
    color: $dark;
    font-family: $fontFamily, sans-serif;
    position: relative;
    box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    padding-inline-start: 15px;
    background-color: $white;
    span {
        padding-inline-start: 15px;
    }
    svg {
        cursor: pointer;
        fill: $black;
    }
}
.single-booth-participant-view {
    height: calc(100vh - 269px);
    position: relative;
}
.single-booth-participant-view .booth-preview-container {
    width: 100%;
    position: absolute;
    height: 100%;
    text-align: center;
    font-size: 0;
}
.single-booth-participant-view .booth-preview-container img {
    height: 100%;
    position: absolute;
    width: auto;
    top: 0;
    left: 50%;
    transform: translate(-50%);
}
#info-tab .user-details .user-name p:last-child {
    max-width: initial;
}
@media all and (max-width: 1440px) {
    .wall-and-networking-container .tab {
        font-size: 15px !important;
    }
}
@media screen and (max-width: 1024px) {
    .wall-and-networking-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px) !important;
    }
    .booth-wall-container {
        flex: 1;
        height: auto;
        position: relative;
    }
    .booth-wall-container .add-new-post-container {
        bottom: 66px;
    }
    .event-page.hide .booth-wall-container .add-new-post-container {
        bottom: 10px;
    }
    .booth-wall-container .booth-wall-header,
    .booth-wall-container .booth-wall-subheader {
        display: none;
    }

    .wall-and-networking-container,
    .booth-wall-container {
        width: 100% !important;
        max-width: initial !important;
        position: relative !important;
        box-shadow: none;
    }
    .wall-and-networking-container > div {
        padding: 0;
    }
    #wall-and-network-mobile {
        .matching-banner,
        .matching-banner-closed {
            z-index: 10;
            position: absolute;
            top: 48px;
            left: 0;
        }
        .matching-banner {
            width: 100%;
            min-height: 79px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 12px 12px 8px;
            background: $blue;
            box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
            animation: matchingShow 0.5s 3s forwards;
            transform: translate(0, -104px); // 80 height from banner + 24 from box shadow spread offset
            // Tablet
            @media only screen and (max-height: 1024px) and (max-width: 1366px) {
                padding-bottom: 8px;
            }
            // Mobile
            @media only screen and (max-width: 480px) and (max-height: 926px) {
                min-height: 103px;
                transform: translate(0, -127px); // 103 height from banner + 24 from box shadow spread offset
                .bottom-actions {
                    align-items: center !important;
                }
            }
            .top-text {
                width: 100%;
                text-align: left;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: $black;
            }
            .bottom-actions {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .matching-banner-close {
                    color: $black;
                    padding: 0 !important;
                    min-width: 24px;
                    .MuiButton-label {
                        width: 24px !important;
                    }
                }
            }
            .matching-banner-try {
                padding: 9px 8px;
                background-color: $white;
                span {
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 120% !important;
                    text-transform: uppercase;
                    color: $dark;
                }
            }
        }
        .matching-banner-closed {
            width: 100%;
            min-height: 79px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px;
            background: $grey-dark;
            box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
            text-align: left;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: $white;
            animation: matchingHide 0.5s 6s forwards;
            transform: translate(0, 0);
            // Mobile
            @media only screen and (max-width: 480px) and (max-height: 926px) {
                min-height: 69px !important;
            }
        }
        @keyframes matchingShow {
            to {
                transform: translate(0, 0);
            }
        }
        @keyframes matchingHide {
            to {
                transform: translate(0, -127px); // 103 height from mobile banner + 24 from box shadow spread offset
            }
        }
    }
    #wall-and-network-mobile .networking-container {
        background-color: #f5f5f5;
    }
    .wall-and-networking-container .tabs-container {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .wall-and-networking-container .tab {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }
    #standard-search::placeholder {
        color: $text-grey;
        font-size: 16px;
    }
    .search-user-container {
        background-color: #f5f5f5;
        height: 40px;
    }
    #standard-search {
        background-color: #f5f5f5;
        font-size: 16px;
    }
    .event-page.hide {
        .networking-container {
            .custom-scroll-container {
                height: calc(calc(var(--vh, 1vh) * 100) - 183px);
            }

            .empty-results {
                height: calc(calc(var(--vh, 1vh) * 100) - 195px);
            }
        }
    }
    .networking-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 240px);
    }
    .networking-container .custom-scroll-container.ios-support {
        height: calc(calc(var(--vh, 1vh) * 100) - 280px);
    }
    .auditorium-page .networking-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 280px);
    }
    .button-tab.networking-tab .restricted-network {
        width: 100%;
        max-width: 150px;
    }
    .wall-container {
        position: relative;
    }
    .wall-container {
        .custom-scroll-container {
            height: calc(calc(var(--vh, 1vh) * 100) - 199px);
        }
        &.closed-wall {
            .custom-scroll-container {
                height: calc(calc(var(--vh, 1vh) * 100) - 196px);
            }
        }
        &.wall-moderation {
            .custom-scroll-container {
                height: calc(calc(var(--vh, 1vh) * 100) - 203px - var(--moderation));
                .wall {
                    padding-top: 10px !important;
                }
            }
        }
        &.edit-mode-active {
            .custom-scroll-container {
                height: calc(calc(var(--vh, 1vh) * 100) - 235px);
            }
        }
    }
    .booth-wall-container {
        .wall-container {
            .custom-scroll-container {
                height: calc(calc(var(--vh, 1vh) * 100) - 239px);
            }
            &.wall-moderation {
                .custom-scroll-container {
                    height: calc(calc(var(--vh, 1vh) * 100) - 239px - var(--moderation));
                }
            }
        }
    }
    .showfloor-page {
        .booth-wall-container {
            .wall-container {
                .custom-scroll-container {
                    height: calc(calc(var(--vh, 1vh) * 100) - 247px);
                    &.scholar {
                        height: calc(calc(var(--vh, 1vh) * 100) - 312px);
                    }
                }
                &.wall-moderation {
                    .custom-scroll-container {
                        height: calc(calc(var(--vh, 1vh) * 100) - 251px - var(--moderation));
                        &.scholar {
                            height: calc(calc(var(--vh, 1vh) * 100) - 312px - var(--moderation));
                        }
                    }
                }
            }
        }
    }
    .participant-avatar {
        width: 40px;
        height: 40px;
        flex: 0 0 40px !important;
        &.scholar {
            background-position: top;
        }
    }
    .user-container .user-name p:last-child {
        font-size: 12px;
        margin-bottom: 34px;
        line-height: initial;
    }
    .user-details .social-button {
        margin-inline-end: 16px;
    }
    .user-details .social-button:last-of-type {
        margin-inline-end: 0;
    }
    .user-details .social-button > div img {
        width: 24px;
        height: 24px;
    }
    #info-tab .custom-scroll-container {
        padding: 0;
    }
    #info-tab .box {
        padding: 0 !important;
    }
    #info-tab .representative-list {
        margin: 0;
        list-style-type: none;
        padding: 10px 0 0;
    }
    #info-tab .representative-list li {
        background-color: #ffffff;
    }
    #info-tab .user-container {
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        padding-bottom: 10px;
        padding-top: 10px;
    }
    #info-tab .user-details {
        padding: 0;
        border: 0;
    }
    #info-tab .user-details .user-name p:last-child {
        margin-bottom: 0;
    }
    #info-tab .user-details > div:last-of-type {
        min-height: initial;
    }
    #info-tab .participant-button.open-chat {
        top: 2px;
    }
    #info-tab .custom-scroll-container.networking-wrapper {
        height: calc(calc(var(--vh, 1vh) * 100) - 160px);
        background-color: #f5f5f5;
    }
    .event-page.hide .wall-container .custom-scroll-container,
    .event-page.hide-new-comment .wall-container .custom-scroll-container {
        height: calc(calc(var(--vh, 1vh) * 100) - 63px);
    }
    .event-page.hide .wall-and-networking-container.open,
    .event-page.hide-new-comment .wall-and-networking-container.open {
        height: calc(calc(var(--vh, 1vh) * 100) - 56px) !important;
    }
}

@media screen and (max-width: 1366px) {
    .auditorium-page {
        .booth-wall-container {
            width: 22%;
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .wall-and-networking-container .tabs-container,
    .auditorium-page .tabs-container {
        justify-content: center;
    }
    .button-tab.tab {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
    .wall-container .sort-container,
    .wall-container > div:last-of-type {
        max-width: 600px;
        margin: 0 auto;
    }
    .add-new-post-container {
        max-width: 610px;
    }
}
@media screen and (max-width: 480px) {
    #info-tab .user-details .user-name p:last-child {
        max-width: 150px;
    }
}
@media screen and (max-width: 420px) {
    .wall-and-networking-container .tab {
        font-size: 13px !important;
    }
}
@media screen and (max-width: 360px) {
    .wall-and-networking-container .tab {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 330px) {
    .wall-and-networking-container .tab {
        font-size: 11px !important;
    }
}
@media screen and (max-height: 859px) {
    .auditorium-page {
        .booth-wall-container {
            z-index: 8;
            &.hidden {
                width: 0;
            }
            .pending-approval {
                span {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
}
@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse),
    (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: fine) {
    .add-new-post-container.ios-support:not(:focus-within) {
        bottom: 55px !important;
        position: absolute !important;
    }
}
