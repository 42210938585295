// font family
$fontFamily: 'Avenir';

// USE ONLY HASH COLORS
$primary: #fbd207;
$primary-dark: darken(#fbd207, 10%);
$primaryColorBranding: rgba(251, 210, 7, 0.99);
$secondary: #000000;
$black: #000000;
$secondaryColorBranding: rgba(0, 0, 0, 0.99);
$secondaryDarken: #000000; // for gradients (on white labels should be secondary color)
$secondary-banner1: #000000; // for gradients (on white labels should be secondary color)
$secondary-banner2: #000000; // for gradients (on white labels should be secondary color)
$secondary-rooms1: rgba(0, 0, 0, 0.7); // for gradients (on white labels should be secondary color)
$secondary-rooms2_56: rgba(0, 0, 0, 0.56); // secondary color with opacity
$secondary-rooms2_80: rgba(0, 0, 0, 0.8); // secondary color with opacity
$secondary-light: lighten(#000000, 45%);
$secondary-hover: #00000059; // the last 2 digits represents the alpha parameter (opacity/transparency) - secondary color
$secondaryOpacity70: rgba(0, 0, 0, 0.7); // secondary color
$avatarBckg1: #fbd207;
$avatarBckg2: #fbd207;
$secondaryLight10: rgba(0, 0, 0, 0.1); // secondary color
$dark: #2b2b2b;
$grey: #898989;
$grey-dark: #777081;
$text-grey: #726f79;
$light-grey: #efeef1;
$disabled-text: #cacbd0;
$error: #e73131;
$error-light: #eb5757;
$red: #d82a2a;
$black: #000;
$warning: #f2994a;
$white: #ffffff;
$white-variant: #f5f5f5;
$facebook: #4267b2;
$linkedin: #2867b2;
$twitter: #1da1f2;
$doorColorText: #1d1d1b;
$lobbyDoorTextColor: rgba(29, 29, 27, 0.99); // $doorColorText with opacity
$muiDisabled: #b9b9b9;
$hover-wall-action-primary: rgba(255, 193, 7, 0.1); // primary color
$gradientMenu1: #fbd207; // for white label will be primary color
$gradientMenu2: #fbd207; // for white label will be primary color
$gradientMenu3: #fbd207; // for white label will be primary color
$matchingBanner1: #fbd207; // for white label will be primary color
$matchingBanner2: #fbd207; // for white label will be primary color
$matchingBanner3: #fbd207; // for white label will be primary color
$matchingBanner4: #fbd207; // for white label will be primary color
$matchingBanner5: #fbd207; // for white label will be primary color
$backgroundPages: #15191B;
$blue: #00A3E0;
$blueHover: rgba(0,163,224, .6);
$closeButtonBckg: #403E43;
$pageTitleBck: #222626;
$backgroundProgram: #F7F7F8;
$pastStatus: #F76F6F;

// email for custom renders
$emailCustomRender: 'design@myonvent.com';

// social links
$facebookLink: 'AIforGood';
$linkedInLink: 'company/ai-for-good-global-summit-un/';
$twitterLink: 'itu_aiforgood';

// validations for email
$emailEndsWith1: '@myonvent.com';
$emailEndsWith2: '@onvent.online';

// export variables
:export {
    fontFamily: $fontFamily;
    primary: $primary;
    primaryColorBranding: $primaryColorBranding;
    primaryDark: $primary-dark;
    secondary: $secondary;
    secondaryColorBranding: $secondaryColorBranding;
    secondaryDarken: $secondaryDarken;
    secondaryHover: $secondary-hover;
    secondaryBanner2: $secondary-banner2;
    dark: $dark;
    muiDisabled: $muiDisabled;
    grey: $text-grey;
    lightGrey: $disabled-text;
    darkWhite: $light-grey;
    disabledText: $disabled-text;
    error: $error;
    errorLight: $error-light;
    warning: $warning;
    white: $white;
    whiteVariant: $white-variant;
    greyVariant: $grey;
    greyDark: $grey-dark;
    black: $black;
    facebook: $facebook;
    linkedin: $linkedin;
    twitter: $twitter;
    doorColor: $doorColorText;
    redCancel: $red;
    secondaryLight: $secondary-light;
    gradientMenu1: $gradientMenu1;
    gradientMenu2: $gradientMenu2;
    gradientMenu3: $gradientMenu3;
    secondaryOpacity70: $secondaryOpacity70;
    lobbyDoorTextColor: $lobbyDoorTextColor;
    emailCustomRender: $emailCustomRender;
    facebookLink: #{$facebookLink};
    linkedInLink: #{$linkedInLink};
    twitterLink: #{$twitterLink};
    emailEndsWith1: $emailEndsWith1;
    emailEndsWith2: $emailEndsWith2;
    black: $black;
    blue: $blue;
    closeButtonBckg: $closeButtonBckg;
    backgroundPages: $backgroundPages;
}
