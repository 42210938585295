@import '../../CSS/variables.module';
.network-filters {
    position: absolute;
    width: 100%;
    right: -10000px;
    transition: 200ms;
    background-color: $white;
    z-index: 9999;
    animation: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--show {
        right: 0;
        transition: 200ms;
        animation: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }
    &__header {
        width: 100%;
        flex: 1 1 5%;
        max-height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 598px;
        p {
            text-align: left;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            padding: 12px 8px 12px 8px;
            color: $dark;
        }
    }
    &__body {
        flex: 2 1 80%;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin: 0;
        }
    }
    &__footer {
        background-color: $white;
        border-top: 1px solid rgba(137, 137, 137, 0.5);
        max-height: 75px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        padding-bottom: 20px;
        height: 67px;
        @media screen and (max-width: 1024px) {
            padding-bottom: 14px;
        }
    }
    &__reset-btn {
        margin-right: 15px !important;
    }
    &__apply-btn {
        font-weight: 600;
    }
    &__apply-btn--disabled {
        background-color: black !important;
    }
}

.network-filter {
    margin-bottom: 8px;
    max-width: 598px;
    width: 100%;
    .expanded-less-icon {
        fill: $primary;
    }
    &__accordion {
        border: 1px solid $disabled-text;
        box-sizing: border-box;
        border-radius: 6px;
    }
    &__title-counter {
        background: $primary;
        width: 16px;
        height: 16px;
        display: block;
        color: $black;
        font-family: $fontFamily, sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        margin-right: 5px;
    }
    &__title-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
            flex: 1;
            text-align: left;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
            &.title--active {
                color: $primary;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 0 !important;
    }

    label {
        margin-left: 0;
        padding-bottom: 9px;
    }
}

.MuiFormControlLabel-label {
    user-select: none;
}
