.text-align-left {
    text-align: left;
}

// .MuiFormControl-root.MuiFormControl-fullWidth {
//     text-align: left;
// }
.align-helper {
    .MuiFormControlLabel-root {
        display: block;
        span {
            display: block;
            float: left;
        }
        span.MuiTypography-root {
            margin-top: 9px;
        }
    }
    .MuiFormLabel-root {
        text-align: left;
    }
    .MuiSelect-select {
        text-align: left;
    }
}
.align-helper.mT-10 {
    margin-top: 10px;
}
