.description.link-helper {
    font-family: 'Avenir Next' !important;
    a {
        color: #fbd207;
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        // line-height: 18px;
    }
    a:active {
        color: #00a3e0;
    }
    a:visited {
        color: #db9409 !important;
    }
}
p.title {
    a,
    a:visited,
    a:hover,
    a:active {
        color: black !important;
    }
}
