@import '../../../CSS/variables.module';

@keyframes delay-overflow {
    from,
    to {
        overflow: hidden;
    }
}

.poster-details-container {
    transition: height 0.2s ease-in;
    max-width: 100%;
    padding: 0 120px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    &.expanded {
        height: calc(100vh - 250px);
        width: 100%;
        max-width: 100% !important;
        .poster-details-inner-container {
            height: calc(100% - 45px);
            .poster-details {
                margin-inline-end: 0;
                &.scroll-left-container {
                    overflow: auto;
                    animation: delay-overflow 1s backwards;
                    animation-delay: 0.5s;
                    &.booth-preview {
                        & > div {
                            padding-bottom: 25px;
                        }
                    }
                }
                & > div {
                    padding-inline-start: 5px;
                    padding-bottom: 65px;
                }
            }
        }
    }
    .scroll-info-container {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid $disabled-text;
        background: #f5f5f5;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.12);
        padding: 10px 30px;
        width: 100%;
        display: flex;
        align-items: center;
        span {
            color: $primary;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
        }
    }
    .title-container {
        .title {
            color: $secondary;
        }
    }
}

.poster-details-inner-container {
    margin: auto;
    position: relative;
    .showfloor-menu-button {
        right: 0;
    }
}
.poster-details {
    &.scroll-left-container {
        overflow: hidden;
    }
    & > div {
        display: flex;
    }
    .left-column {
        flex: 0 0 30%;
        padding-inline-end: 16px;
    }
    .section {
        font-size: 16px;
        line-height: 24px;
        color: $dark;
        padding-bottom: 16px;
        p {
            margin: 0;
            white-space: pre-line;
        }
        .section-subtitle {
            font-size: 14px;
            font-weight: 500;
            color: $primary;
            padding-bottom: 8px;
            text-transform: uppercase;
        }
        .poster-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            &.empty {
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
}

.icon-scroll:before {
    position: absolute;
    left: 32.5%;
}
.icon-scroll {
    width: 14px;
    height: 24px;
    position: relative;
    margin-left: 7px;
    box-shadow: inset 0 0 0 1px $dark;
    border-radius: 25px;
}
.icon-scroll:before {
    content: '';
    width: 5px;
    height: 5px;
    background: $dark;
    margin-left: 0px;
    top: 3px;
    border-radius: 131px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}
@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(18px);
    }
}
