@import '../../../CSS/variables.module';

.session-thumbnail-image.live-session-type {
    .image-container {
        width: 100%;
        max-width: 450px;
        height: 150px;
        text-align: center;
        margin: auto;
        img {
            width: 70%;
            height: 100%;
            object-fit: contain;
        }
        label {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $dark;
            cursor: pointer;
            flex-flow: column;
            span {
                padding-top: 16px;
            }
        }
    }
    .buttons-container{
        padding-top: 10px;
        padding-bottom: 10px;
        button{
            margin-right: 16px;
            label{
                cursor: pointer;
            }
        }
    }
}
