@import '../../CSS/variables.module';
.on-demand-video {
    padding: 0 12px 12px;
    .image-preview-video {
        border: 0.5px solid #ffffff;
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        border-radius: 8px;
        font-size: 0;
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }
        .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 22px;
            svg {
                stroke: $secondary;
                path {
                    fill: $primary;
                }
            }
        }
    }
    .video-details-container {
        .speakers-container {
            color: $white;
            font-size: 14px;
            font-weight: 500;
            padding-top: 8px;
            padding-bottom: 0;
            .speaker {
                display: initial;
            }
        }
        .title {
            display: block;
            color: black;
            padding: 4px 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
        }
        .date {
            color: $text-grey;
            padding-top: 8px;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}
