.export-list-container {
  position: absolute;
  width: 100%;
  bottom: 80px;
  display: flex;
  justify-content: center;
}

.export-list-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  background-color: #FBD207;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 16px;
}

.export-list-button:hover {
  cursor: pointer;
}

.export-list-text {
  margin: 0;
  margin-left: 8px;
}