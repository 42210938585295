@import '../../CSS/mixins';
@import '../../CSS/variables.module';

.zoom_session_dashboard_page {
    padding-top: 110px; // TODO: fix
    position: relative;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #f5f5f5;
    overflow: hidden;
    max-width: 100%;
    padding-right: 0;

    @include xs {
        padding-right: 0;
        overflow: auto;
    }
    &.isFullScreen {
        overflow: initial;
    }
    &-container {
        // max-width: 1430px;
        display: flex;
        justify-content: space-between;
        // width: calc(100% - 323px);
        width: 100%;
        padding: 16px 28px;
        // min-height: calc(100vh - 70px);
        height: 100%;
        // margin: 0 auto;
        @include xs {
            flex-direction: column;
            width: 100%;
            padding: 16px 10px;
            height: auto;
        }
    }
    &-column {
        display: flex;
        flex-direction: column;
        min-width: 452px;

        &:first-child {
            flex-grow: 1;

            .videoWrapper {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                top: 48px;
                left: 0;
                .session-name {
                    position: absolute;
                    top: -48px;
                    margin: 0;
                    width: 100%;
                    border-radius: 8px 8px 0 0;
                    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
                    border: 1px solid $disabled-text;
                }
                div {
                    width: calc((100vh - 70px) * 1.10804);
                    height: calc((100vh - 70px) * 0.62516);
                }
            }

            .buttons_group {
                justify-content: center;
                margin-top: 88px;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;
            min-width: 588px;
            @include xs {
                margin-right: 0;
            }
        }
        @include xs {
            min-width: 0;
            flex-direction: column;
            max-width: 100%;
            width: 100%;
        }
    }
    .module {
        color: #fff;
        display: flex;
        flex-direction: column;
        @include xs {
            margin-bottom: 15px;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:last-child {
            flex-grow: 1;
        }

        &-container {
            // background-color: #666;
            border-radius: 8px;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-header {
            display: flex;
            align-items: center;
            height: 48px;
            background-color: #f5f5f5;
            padding: 0 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #2b2b2b;
            border-bottom: 1px solid $disabled-text;

            .d-flex {
                align-items: center;
            }
        }
        &-body {
            background-color: #fff;
            // height: 100%;
            @include xs {
                min-height: 200px;
            }
        }
        &-video_container {
            position: relative;
            height: 342px;
            overflow: hidden;
            background-color: #000;
        }
        &-link_block {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            color: #211e16;
            display: flex;
            align-items: center;
            padding: 0 12px;
            height: 55px;
            justify-content: space-between;
            &:not(:last-child) {
                border-bottom: 1px solid #e6e6e6;
            }
        }
        &.-video {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background: #000;
            height: 350px;

            .conference-buttons-container {
                position: absolute;
                cursor: pointer;
                top: 100%;
                background-color: rgba(228, 14, 32, 1);
                width: 100%;
                height: 48px;
                display: flex;
                justify-content: center;
                display: flex;
                align-items: center;

                button {
                    font-family: $fontFamily, sans-serif;
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    font-weight: bold;
                    font-size: 16px;
                    color: white;
                    text-transform: uppercase;
                    cursor: pointer;

                    div {
                        display: flex;
                        align-items: center;
                    }

                    svg {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;

                        path {
                            fill: #fff !important;
                        }
                    }
                }
            }
        }
        &.-fullScreen {
            position: absolute;
            width: 100vw;
            height: 100vh;
            z-index: 1001;
            left: 0;
            top: -118px;
        }
        &.withTopMargin {
            margin-top: 40px;
        }
        &.-permissions {
            .tab {
                font-weight: bold;
                font-size: 16px;
                line-height: 1.2;
                display: flex;
                align-items: center;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
                cursor: pointer;
                margin: 0;
                height: 100%;
                padding: 0 35px;
                position: relative;
                color: #898989;
                transition: all 0.3s ease-out;
                &:after {
                    transition: all 0.3s ease-out;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    color: inherit;
                    background-color: currentColor;
                    opacity: 0;
                }
                &.-active {
                    color: $primary;
                    &:after {
                        opacity: 1;
                    }
                }
            }
            .module-body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }
            .module-header {
                justify-content: center;
            }
            .module-row {
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                justify-content: space-between;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.2;
                overflow: hidden;
                letter-spacing: -0.4px;
                color: #777081;
                &.-head {
                    background-color: #f5f5f5;
                    border-bottom: 1px solid $disabled-text;
                    text-transform: uppercase;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid $disabled-text;
                }
                .avatar-name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre;
                    max-width: 150px;
                }
                .avatar {
                    border-radius: 50%;
                    position: relative;
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    background-color: $primary;
                }
            }
            .module-action_column {
                width: 92px;
                min-width: 92px;
                text-align: center;
            }
            .d-flex {
                align-items: center;
            }
        }
        &.-chat {
            .module-body {
                padding: 0 0 8px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: flex-end;
            }
            .chat-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-bottom: 10px;
            }
            .message-input {
                height: initial;
                background: #ffffff;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                position: relative;
                height: auto;
                min-height: 43px;
                display: flex;
                max-width: 100%;
                margin: 0;
                padding: 0;
                margin: 0 8px;
                align-items: center;
                input {
                    height: 24px;
                    line-height: 24px;
                    display: block;
                    flex: 1 1;
                    border: none;
                    outline: none;
                    padding-left: 15px;
                    color: var(--theme-text-color);
                    font-size: 12px;
                }
                button {
                    font-family: $fontFamily, sans-serif;
                    height: 47px;
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    padding: 0 16px 0 10px;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    color: var(--theme-primary-color);
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }
            .message {
                display: flex;
                margin-bottom: 15px;
                // padding-left: 10px;
                &-container {
                    padding: 8px 8px 0;
                }
                &-avatar {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    margin-right: 10px;
                    border-radius: 50%;
                    overflow: hidden;

                    border-radius: 50%;
                    position: relative;
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    background-color: $primary;
                }

                &-text {
                    font-weight: 500;
                    font-size: 12px;
                    flex-grow: 1;
                    line-height: 1.2;
                    letter-spacing: -0.4px;
                    color: #2b2b2b;
                    .title {
                        font-size: 16px;
                        margin: 0 0 5px;
                    }
                }
                &-time {
                    min-width: 55px;
                    margin-left: 10px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: #898989;
                }
            }
        }
    }

    .buttons_group {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .tip {
            font-weight: 500;
            font-size: 14px;
            color: $primary;
        }
    }
    .button {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        cursor: pointer;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        color: #211e16;
        outline-color: rgba(#211e16, 0.2);
        background-color: transparent;
        padding: 10px 15px;
        border: none;
        display: flex;
        align-items: center;
        &.-colored {
            color: $secondary;
            outline-color: rgba($secondary, 0.2);
        }
        &_icon {
            color: currentColor;
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
        &:disabled {
            cursor: initial;
            color: rgba(33, 30, 22, 0.2);
        }
    }
    .screen_is_recrding {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 4px solid $secondary;
        pointer-events: none;
        z-index: 999;
    }
    .time-to-end-popup {
        position: fixed;
        width: 100%;
        display: flex;
        left: 0;
        bottom: 0;
        z-index: 110;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        display: flex;
        align-items: center;
        color: #ffffff;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        height: 72px;
        background: linear-gradient(180deg, #a8261c 0%, #bd2d21 100%);
        button {
            border: 1px solid #ffffff;
            box-sizing: border-box;
            border-radius: 8px 8px 0px 8px;
            font-weight: bold;
            background-color: transparent;
            padding: 10px 65px;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            letter-spacing: 0.0125em;
            text-transform: uppercase;

            /* white */

            color: #ffffff;
        }
    }
}
