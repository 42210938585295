.Button {
    display: inline-block !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    font: inherit !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: inherit;
    text-transform: uppercase !important;
    outline: none !important;
    cursor: pointer !important;
    appearance: none !important;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.Button__icon {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}

.Button__title {
    vertical-align: middle;
}
