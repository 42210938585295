@import '../../../CSS/variables.module';

.timeslot-sdgs {
    .single-option-container {
        #select-sdgs {
            padding-right: 40px;
        }
        .shrink-label {
            legend {
                width: auto !important;
            }
        }
    }
    .chips-wrapper {
        display: flex;
        flex-wrap: wrap;
        & > div {
            margin-bottom: 8px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}