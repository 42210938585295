@import '../../../CSS/variables.module';

.advanced-program-container {

    text-align: left;
    .scrollbar {
        & > div {
            &:nth-child(3) {
                //background-color: $grey-dark;
            }
        }
    }
    .scroll-left-container {
        scrollbar-color: #777081 transparent;
        margin-inline-end: 4px;
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #777081;
            border: 1px solid #ffffff;
            border-radius: 8px;
        }
    }
    .linked-session {
        border: 1px solid $grey;
        border-radius: 6px;
        margin: 0 16px 8px 10px;
        padding: 16px;
        display: block;
        @media screen and (max-width: 479px) {
            padding: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }
        &.remove-session-item {
            opacity: 0;
            transition: opacity 700ms ease-out;
        }
        &.restricted {
            pointer-events: none;
        }
        &.has-sdgs {
            // padding-bottom: 0;
        }
    }
    .tabs-wrapper {
        background-color: white;
        display: flex;
        border: 1px solid #CACBD0;
        & > div {
            flex: 1;
            padding: 15.5px 0;
            border-right: 1px solid #CACBD0;
            cursor: pointer;
            text-align: center;
            &:last-of-type {
                border-right: 0;
            }
            &.active {
                background-color: $primary;
                cursor: initial;
                span {
                    color: $black;
                }
            }
        }
        span {
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;

        }
    }
}
.smart-suggestions {
    & > p {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
        padding: 16px 0 16px 16px;
    }
}
.smart-wrapper {
    height: calc(100vh - 170px);
}
.single-session-item {
    &.meeting {
        background-color: white;
        border: 1px solid $grey;
        border-radius: 6px;
        margin: 0 16px 8px 10px;
        padding: 16px;
        display: block;
        @media screen and (max-width: 479px) {
            margin-left: 1px;
            margin-right: 5px;
        }
    }
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
            display: inline-flex;
            align-items: center;
        }
        &:first-of-type {
            & > div {
                &:nth-child(2) {
                    flex: 1;
                    justify-content: flex-end;
                }
            }
        }
    }
    .time-timeslot {
        font-size: 14px;
        margin: 0;
        padding: 10px 0;
    

        &-container {
            display: flex;
            justify-content: unset;

           
        }

        &-auditorium-link {
            cursor: pointer;
        }

        &-auditorium {
            color: black;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 95.9%;

            margin: 0;

            &__disabled {
                color: black;
                cursor: auto;
            }
        }
    }
    .title {
        color: black;
        font-weight: 700;
        font-size: 16px;
        margin: 0;
    }
    .description {
        font-size: 16px;
        display: none;
        margin: 0;
        p {
            margin-top: 16px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .sdgs-wrapper {
        justify-content: flex-start;
        padding-top: 8px;
        display: none;
        flex-wrap: wrap;
        & > div {
            width: 80px;
            height: 80px;
            border-radius: 2px;
            margin-inline-end: 16px;
            margin-bottom: 16px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 2px;
            }
        }
    }
    .program-time-container {
        display: inline-flex;
        align-items: center;
        border: 1px solid $grey;
        border-radius: 6px;
        padding: 6px;
        span {
            font-weight: 500;
            font-size: 14px;
            margin-inline-start: 10px;
            text-transform: capitalize;
            color: black;
            @media screen and (max-width: 479px) {
                font-size: 13px;
            }
        }
    }
    .status-wrapper {
        &.mobile {
            padding-top: 10px;
            .status-button {
                margin-inline-start: 0;
            }
        }
    }
    .status-button {
        margin-inline-start: 16px;
        span {
            text-transform: none;
            &:before {
                top: 11px;
            }
        }
        &.upcoming-button,
        &.meeting-status-button {
            span {
                background-color: transparent;
                padding-inline-start: 16px;
                padding-inline-end: 0;
                &:before {
                    left: 0;
                }
            }
        }
        &.meeting-status-button {
            span {
                padding-inline-start: 6px;
                padding-top: 8px;
                padding-bottom: 8px;
                &:before {
                    display: none;
                }
            }
        }
        &.past-button {
            span {
                background-color: transparent;
                padding-inline-start: 16px;
                padding-inline-end: 0;
                
                &:before {
                    background-color: $pastStatus;
                    left: 0;
                }
            }
        }
    }
    .sponsored {
        margin-inline-start: 18px;
        cursor: pointer;
        span {
            display: inline-block;
        }
        svg {
            outline: 0;
        }
    }
    .speakers-wrapper {
        justify-content: initial;
        padding: 8px 0 0;
        p {
            font-size: 14px;
            margin: 0;
        }
    }
    button {
        background: transparent;
        outline: 0;
        border: 0;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-family: $fontFamily, sans-serif;
        &.is-added {
            span {
                color: black;
                word-break: normal;
            }
        }
        &.add-to-agenda {
            background: $primary;
            border-radius: 6px;
            padding: 8px;
            color: $black;
        }
        span {
            margin-inline-end: 10px;
            font-weight: 500;
            font-size: 14px;
            max-height: 14px;
            @media screen and (max-width: 1024px) {
                max-height: initial;
            }
        }
        svg {
            flex: 1;
        }
    }
    .expand {
        margin-inline-end: 6px;
        padding: 8px;
        cursor: pointer;
        svg {
            fill: black;
        }
    }
    &.expanded {
        .description {
            display: block;
        }
        .sdgs-wrapper {
            display: block;
            padding-top: 16px;
        }
    }
    .mobile-wrapper {
        display: flex;
        padding-top: 10px;
        .status-button {
            margin-inline-start: 0;
        }
    }
    .add-to-external-calendar-button {
        color: black;
        cursor: pointer;
        padding: 0 8px;
    }
}
.session-item {
    &-actions-container {
        display: flex;
        font-size: 20px;
        justify-content: space-between;
        align-items: center;
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            svg {
                fill: black;
            }
            &.accept-icon,
            &.forward-icon {
                margin-inline-start: 16px;
            }
        }
    }
}
.my-agenda {
    position: relative;
    .agenda-title-wrapper {
        padding: 2px 16px 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 14px;
            text-transform: uppercase;
            margin: 0;
        }
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .agenda-wrapper {
        height: calc(100vh - 168px);
    }
}
.empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    p {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        padding-top: 10px;
    }
    div {
        max-width: 320px;
    }
}
@keyframes delay-overflow {
    from,
    to {
        overflow: hidden;
    }
}
.programme {
    padding-top: 16px;
    @media screen and (max-width: 1024px) {
        padding-top: 13px;
    }
    .programme-wrapper {
        height: calc(100vh - 137px);
        @media screen and (max-width: 1024px) {
            height: calc(calc(var(--vh, 1vh) * 100) - 170px);
        }
        //.scroll-left-container {
        //    //overflow: hidden;
        //}
        //&.overflow-auto {
        //    .scroll-left-container {
        //        animation: delay-overflow 1s backwards;
        //        animation-delay: 1s;
        //    }
        //}
    }
    .search-programme {
        display: flex;
        position: sticky;
        top: 0;
        background-color: $backgroundProgram;
        z-index: 1;
        flex-direction: column;
        &.advanced {
            ~ .empty-data {
                height: calc(100vh - 515px);
                @media screen and (max-width: 1024px) {
                    height: calc(100vh - 545px);
                    p {
                        font-size: 16px;
                    }
                }
            }
            .simple-programme-variant {
                .filters-container {
                    display: none;
                }
                .action-buttons {
                    display: none;
                }
            }
            .advanced-programme-variant {
                max-height: 350px;
                transition: max-height 0.9s ease-in;
                overflow: hidden;

                @media screen and (min-width: 1024px) {
                    margin-top: 14px;
                }
            }
            & > div {
                &:first-of-type {
                    padding-bottom: 6px;
                    @media screen and (max-width: 1024px) {
                        background: transparent;
                        padding: 0;
                        margin-inline-start: 5px;
                        margin-inline-end: 8px;
                    }
                }
                &:last-of-type {
                    display: flex;
                    justify-content: center;
                    button {
                        margin-top: 16px;
                        margin-bottom: 4px;
                        margin-inline-start: 0;
                        @media screen and (max-width: 1024px) {
                            margin-top: 4px;
                            margin-bottom: 17px;
                        }
                    }
                }
            }
        }
        &.simple {
            & > div {
                &:last-of-type {
                    display: none;
                }
            }
            .simple-programme-variant {
                display: flex;
                align-items: center;
                .action-buttons {
                    display: flex;
                    align-items: center;
                }
            }
            .advanced-programme-variant {
                max-height: 0;
                transition: max-height 0.9s ease-out;
                overflow: hidden;
                margin-top: 0;
            }
        }
        & > div {
            &:first-of-type {
                border-radius: 6px;
                border: 1px solid var(--MO-semi_grey_objects_only, #CACBD0);
                background-color: white;;

                padding: 14px 16px;

                margin: 0 16px 0 10px;
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 1024px) {
                    background: transparent;
                    padding: 0;
                    margin-inline-start: 5px;
                    margin-inline-end: 8px;
                }
            }
        }
        .simple-container {
            position: relative;
            flex: 1;
            @media screen and (max-width: 1024px) {
                margin-bottom: 0;
            }
            .filters-container {
                position: absolute;
                right: 15px;
                padding: 5px;
                cursor: pointer;
                top: 12px;
                @media screen and (max-width: 1024px) {
                    top: 8px;
                }
                svg {
                    margin: 0;
                }
                div {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    top: -5px;
                    span {
                        color: $secondary;
                        font-weight: 500;
                        font-size: 12px;
                        max-height: 12px;
                    }
                }
            }
            input {
                width: 100%;
                background-color: #F6F7F6;
            }
        }
        input {
            border: 1px solid $disabled-text;
            border-radius: 4px;
            height: 56px;
            padding: 0 12px;
            outline: 0;
            font-family: $fontFamily, sans-serif;
            color: $black;
            font-weight: 500;
            font-size: 16px;
            -webkit-appearance: none;
            @media screen and (max-width: 1024px) {
                height: 48px;
            }
            &::placeholder {
                color: $grey-dark;
            }
        }
        input[type='search']::-webkit-search-decoration,
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-results-button,
        input[type='search']::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
        .simple-programme-variant {
            @media screen and (max-width: 1024px) {
                margin-bottom: 8px;
            }
        }
        .advanced-programme-variant {
            flex-direction: column;
            @media screen and (max-width: 1024px) {
                margin: 0;
            }
            & > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                & > div {
                    width: calc(50% - 8px);
                    margin-bottom: 8px;
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }
                    .field-container {
                        background-color: $white;
                        border-radius: 4px;
                        @media (hover: hover) {
                            &.MuiInput-underline {
                                &:before {
                                    border-bottom: 0;
                                }
                            }
                        }
                        input {
                            padding-inline-end: 40px;
                        }
                        & > div {
                            border-radius: 4px;
                            border: 1px solid var(--MO-semi_grey_objects_only, #CACBD0);
                            background: #F6F7F6;

                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-weight: 500;
                            font-size: 16px;
                            padding-inline-end: 40px;
                            ~ svg {
                                width: 16px;
                            }
                            &[aria-expanded='true'] {
                                ~ svg {
                                    transform: rotate(180deg);
                                    fill: $blue;
                                }
                            }
                            .select-placeholder {
                                font-weight: 500;
                                font-size: 16px;
                                color: $grey-dark;
                            }
                        }
                    }
                    .MuiSelect-root {
                        padding: 18px 12px;
                        @media screen and (max-width: 1024px) {
                            padding: 14.5px 12px;
                        }
                    }
                    .MuiInput-underline {
                        &:after {
                            border-bottom: 0;
                        }
                    }
                    .select-icon {
                        margin-inline-start: 0;
                        margin-inline-end: 14px;
                    }
                }
            }
        }
        svg {
            fill: $grey-dark;
            margin-inline-start: 14px;

        }
        button {
            border-radius: 6px;
            height: 34px;
            margin-inline-start: 14px;
            background-color: $primary;
            &.Mui-disabled {
                background-color: $grey-dark;
                color: rgba(0, 0, 0, 0.5);
                font-size: 14px;
            }
            &.reset-filters {
                background-color: transparent;

                border: 1px solid $white;
                margin-inline-end: 8px;

                color: black;
                border-color: gray;
            }
            span {
                font-weight: 500;
                max-height: 14px;
                font-size: 14px;
            }
        }
    }
    .programme-wrapper-title {
        margin: 16px 16px 16px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1024px) {
            margin-top: 6px;
            margin-bottom: 13px;
        }
        p {
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            @media screen and (max-width: 1024px) {
                font-size: 12px;
            }
        }
        .download-program {
            width: auto;
            padding: 0;
        }
        .download-program-link {
            svg {
                fill: $primary;
            }
            span {
                color: $primary;
            }
        }
    }
    .empty-data {
        height: calc(100vh - 270px);
        @media screen and (max-width: 479px) {
            height: calc(100vh - 380px);
        }
        & > div {
            max-width: 385px;
        }
    }
}

.speakers-extended-wrapper {
    display: flex;
}

.select-timezone-container {
    transition: all 0.3s;
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        display: none;
    }
    .selected-timezone-value {
        display: flex;
        align-items: center;
        color: black;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        svg {
            padding-inline-end: 2px;
        }
    }
    .selected-timezone-value-container-mobile {
        svg {
            font-size: 16px;
            z-index: 1;
        }
    }
    .MuiSelect-select {
        padding-inline-end: 24px !important;
        &[aria-expanded='true'] {
            .MuiSvgIcon-root {
                color: black;
            }
        }
    }
    .MuiSelect-icon {
        color: black;
    }
    .MuiSelect-iconOpen {
        color: black;
    }
}

#program-mobile {
    .advanced-program-container {
        .tabs-wrapper {
            background-color: $white;
            width: 100%;
            height: 48px;
            display: flex;
            border: 0;
            border-bottom: 1px solid $disabled-text;
            & > div {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 0;
            }
            span {
                color: $black;
            }
        }
    }
    .smart-wrapper {
        height: calc(calc(var(--vh, 1vh) * 100) - 112px - var(--smart-height));
    }
    .single-session-item {
        .speakers-wrapper {
 
        }
        .program-time-container {
            margin-inline-end: 20px;
            span {
                flex: 1;
            }
        }
    }
    .my-agenda {
        .agenda-title-wrapper {
            padding-top: 10px;
            padding-bottom: 4px;
            p {
                flex: 1;
            }
            & > div {
                margin-inline-start: 16px;
            }
        }
        .agenda-wrapper {
            height: calc(calc(var(--vh, 1vh) * 100) - 112px - var(--agenda-height));
        }
    }
    .auditoriums-program {
        height: calc(calc(var(--vh, 1vh) * 100) - 160px);
    }
}
#smart-tooltip {
    max-width: 210px !important;
    .MuiTooltip-tooltip {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .MuiTooltip-tooltipPlacementTop {
        margin-top: 0 !important;
    }
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        display: block !important;
        .MuiTooltip-tooltipPlacementBottom {
            margin: 15px 0;
        }
    }
}
.grey-scroll {
    background: $grey-dark !important;
    border-radius: 8px;
    border: 1px solid white;
}

#search-filters-dropdown,
#search-filters-dropdown-empty {
    .MuiMenu-paper {
        max-height: 246px;
        width: 316px !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow-y: auto;
        direction: rtl;
        scrollbar-width: thin;
        scrollbar-color: #777081 transparent;
        margin-top: -2px;
        border-top: 1px solid $disabled-text;
        @media screen and (max-width: 1024px) {
            min-width: calc(100vw - 19px) !important;
            left: 14px !important;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #777081;
            border: 1px solid #ffffff;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        & > ul {
            direction: ltr;
            padding: 0 !important;
            width: 100% !important;
            li {
                padding: 12px;
                font-weight: 500;
                font-size: 16px;
                white-space: normal;
                &.Mui-selected {
                    background-color: $primary;
                }
            }
        }
    }
}
#search-filters-dropdown-empty {
    .MuiMenu-paper {
        @media screen and (max-width: 1024px) {
            left: 10px !important;
            min-width: calc(100vw - 19px) !important;
        }
    }
}

html.lang-rtl {
    .programme .search-programme .simple-container input {
        text-align: right;
    }
    .programme .search-programme .advanced-programme-variant > div > div .field-container > div {
        padding-inline-end: 12px;
        padding-inline-start: 40px;
    }
    .programme .search-programme .advanced-programme-variant > div > div .field-container > div ~ svg {
        right: auto;
        left: 0;
    }
    .programme .search-programme .advanced-programme-variant > div > div .select-icon {
        margin-inline-end: 0;
        margin-inline-start: 14px;
    }
    .programme .search-programme .simple-container .filters-container {
        right: auto;
        left: 15px;
    }
}
