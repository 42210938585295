#qr-dialog-title {
  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } 
}

#qr-dialog-secondary {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 95.9%; /* 13.426px */
}

#qr-show-button {
  border-radius: 34px;
  background: var(--AIFG-gold, #FBD207);
  padding: 0;

  span {
    padding: 8px 12px;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  display: flex;
  align-items: center;
}

#qr-dialog-container .MuiPaper-root {
  width: 320px;
  height: 169px;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}