@import 'variables.module';

#regenerate-matching-btn-container {
    height: 80px;
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}


#regenerate-matching-btn {
    background: $primary;
    border-radius: 26px;
    padding: 9px 12px;
    color: $black;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    outline: 0;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: "Avenir", sans-serif;
}

.sort-buttons {
    justify-content: space-between;
    width: 100%;
}

.network-filters-wrapper {
    position: relative;
}

.selected-filters-count {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $primary;
    font-family: $fontFamily, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $black;
    border-radius: 50%;
    top: -4px;
    right: -9px;
}

.network-filters-show-btn {
    position: relative;

    &:hover {
        svg {
            path {
                fill: $secondary !important;
            }
        }
    }
}

.search-user-container {
    height: 43px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-right: 19px;
    padding-bottom: 8px;
    padding-left: 16px;

    .standard-search {
        margin-right: 20px;
    }

    .search-input-container {
        flex: 90%;
        display: flex;
        align-items: flex-start;

        input {
            padding: 0 !important;
            width: 100%;
        }
    }

    .search-icon-container {
        flex: 5%;
        margin-right: 20px;
    }

    .search-filters-icon-container {
        flex: 5%;
    }
}

.no-results {
    background: #f5f5f5;

    .search-user-container {
        position: relative;
        z-index: 11;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.2);
        background: #f5f5f5;

        #standard-search {
            background: #f5f5f5;
            color: $black;
        }
    }
}

.filter-users {
    display: none;
}

.participants-page .friends-list {
    padding: 0;
    margin: 0;
}

.participants-page .friends-list li {
    list-style-type: none;
    padding: 13px 0 0 0;
}

.participants-page .friends-list li:first-child {
    padding-top: 0;
}

.participants-page .friends-list li:last-of-type {
    padding-bottom: 13px;
}

.participants-page .friends-list li:last-of-type .user-details {
    border-bottom: 0;
}

.participants-page .friends-list li>div {
    text-align: left;
}

.custom-scroll-container.networking-wrapper {
    background-color: #fff;
    border-top: 1px solid rgba(137, 137, 137, 0.5);
}

.custom-scroll-container.networking-wrapper div,
.scrollbar,
.scrollbar div {
    outline: none !important;
}

.participant-avatar {
    width: 54px;
    height: 54px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: relative;
    margin-inline-end: 16px;
}

.networking-container .participant-avatar {
    flex: 0 0 54px;
}

.is-online .participant-avatar:after,
.is-online .avatar:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-color: $blue;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}

.is-offline .participant-avatar:after,
.is-offline .avatar:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-color: $grey;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}

.participant-avatar .online-button {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.participant-avatar .online-button:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: $primary;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border: 0.7px solid #ffffff;
}

.participant-button {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    line-height: 1;
}

// add some margin to the last user card
.ReactVirtualized__Grid__innerScrollContainer:last-child {
    margin-bottom: 10px;

    // Mobile
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        margin-bottom: 0;
    }

}

// make virtualized list row (user card) height auto for long name / company / title
.Row {
    height: auto !important;
}

// MATCHING USER CARD STYLES
.user-main-card {
    z-index: 3;
    width: 323px;
    min-height: 108px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    padding: 8px;
    border-bottom: 1px solid $disabled-text;

    // Small desktop
    @media all and (max-width: 1280px) and (max-height: 800px) {
        width: 100%;
    }

    // Tablet
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        width: 100%;
        padding-left: 10px;
    }

    .user-container {
        padding: 0 !important;
        display: flex;

        &.is-online {
            .participant-avatar {
                &:after {
                    background: $blue;
                }
            }
        }

        &.is-offline {
            .participant-avatar {
                &:after {
                    background: $grey;
                }
            }
        }

        .participant-avatar {
            min-width: 55px;
            min-height: 55px;
            box-shadow: none;
            margin-inline-end: 8px;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 16px;
                height: 16px;
                background: $blue;
                border: 3.5px solid $white;
                border-radius: 50%;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
            }
        }

        .match-level {
            z-index: 5;
            position: absolute;
            top: 70px;
            left: 5.5px;
            width: 100%;
            max-width: 60px;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: -0.4px;
            color: $primary;
            text-transform: uppercase;
            margin-right: 7px;

            &.medium {
                color: $secondary;

                &.smaller-label {
                    font-size: 10px;
                }
            }

            &.smaller-label {
                font-size: 10px;
            }
        }

        .user-details {
            flex: 1;
            display: flex;
            border: 0;
            padding-top: 5px;
            position: relative;
            text-align: start;
            justify-content: space-between;

            div {
                max-width: 175px;
                height: auto;
                position: relative;
            }

            .user-name {
                max-width: 175px;
                height: auto;

                .user-name-top {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 95.9%;
                    letter-spacing: -0.03em;
                    color: $dark;
                }

                .user-name-bottom {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.4px;
                    color: $grey-dark;
                }
            }

            .participant-button-chat {
                width: 80px;
                height: 37px;
                min-height: 37px;
                border: 0;
                outline: 0;
                background-color: transparent;
                cursor: pointer;
                padding: 0;

                div {
                    height: 100%;
                    padding-right: 0;
                    width: 100%;
                    position: relative;

                    svg {
                        width: 100%;
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));

                        @media all and (max-width: 1024px) and (max-height: 1366px) {
                            filter: unset;
                        }
                    }

                    span {
                        position: absolute;
                        left: 50%;
                        top: 12px;
                        transform: translateX(-50%);
                        text-transform: uppercase;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.0125em;
                        color: $black;
                        font-weight: 700;
                        font-family: $fontFamily, sans-serif;
                    }
                }

                &:disabled,
                &.display-only {
                    cursor: initial;
                }
            }
        }
    }

    .bottom-row {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 8px;
        padding-right: 5px;
        padding-left: 72px;

        .social-add {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .match-social {
                display: flex;
                flex-direction: row;
                align-items: center;

                .social-button {
                    width: 24px;
                    height: 24px;
                    margin-right: 20px;

                    &:hover {
                        cursor: pointer;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .participant-button-add {
                border: 0;
                outline: 0;
                background-color: transparent;
                cursor: pointer;

                &:disabled,
                &.display-only {
                    cursor: initial;
                }

                svg {
                    fill: $white;
                }
            }
        }

        &.sessions-actions {
            bottom: 45px;
            padding-bottom: 4px;
        }
    }
}

#matching-tooltip,
#sponsored,
#smart-tooltip {
    max-width: 215px;
    max-height: 90px;

    .MuiTooltip-tooltip {
        padding: 15px 12px 15px 48px;
        border-radius: 12px;
        border: 1px solid $disabled-text;
        background-position: 14px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dark;
    }

    // Tablet
    @media all and (max-width: 1024px) and (max-height: 1366px) {
        display: none;
    }
}

#featured-match {
    max-width: 139px;
    max-height: 36px;

    .MuiTooltip-tooltip {
        padding: 8px 12px 8px 12px;
        border-radius: 12px;
        border: 1px solid $disabled-text;
        background: #FFFFFF 14px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dark;
    }
}

// END - MATCHING USER CARD STYLES
.user-container {
    display: flex;
    flex-wrap: wrap;
}

.networking-wrapper .user-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.already-contacts-wrapper .user-container .participant-button.open-chat {
    margin: 5% 0;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }

        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}

.contacts-page .box div:last-child .user-container .user-details {
    border-bottom: 0;
}

.user-container .user-details {
    flex: 1;
    display: flex;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    padding-bottom: 0;
    position: relative;
    text-align: start;
}

.user-container .user-name p:last-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-family: $fontFamily, sans-serif;
    margin-bottom: 34px;
    word-break: break-word;
    word-wrap: break-word;
}

.user-details>div:first-child {
    flex: 1;
}

.user-details>div:last-of-type {
    min-height: 58px;
    word-break: break-word;
}

.user-details .user-name {
    margin: 0;
}

.user-details .social-button {
    display: inline-block;
    line-height: 1;
    margin-inline-end: 20px;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }

        &.focus-visible {
            outline: none;

            svg {
                fill: $secondary;

                path {
                    fill: $secondary;
                }
            }
        }
    }
}

.networking-wrapper .scrollbar>div:nth-child(3) {
    margin-bottom: 0;
}

.connection {
    height: 100%;
}

.participant-button.request-sent,
.participant-button.connect-button,
.participant-button.add-button {
    position: absolute;
    right: 0;
    cursor: initial;
    outline: 0;
    bottom: 5px;
    display: flex;
    align-items: center;
}

.participant-button.add-button span,
.participant-button.request-sent span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 10px;
}

.participant-button.request-sent span {
    color: $text-grey;
}

.participant-button.add-button:disabled {
    cursor: initial;
}

.participant-button.open-chat {
    outline: 0;
    background-color: transparent;

    div {
        position: relative;

        span {
            position: absolute;
            left: 50%;
            top: 14px;
            transform: translateX(-50%);
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.0125em;
            color: $black;
            font-family: $fontFamily, sans-serif;
        }

        svg {
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));

            @media all and (max-width: 1024px) and (max-height: 1366px) {
                filter: unset;
            }
        }
    }
}

.networking-container .participant-button.open-chat {
    top: -5px;
    background-color: transparent;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }

        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}

.participant-button.connect-button,
.participant-button.add-button {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }

        &.focus-visible {
            outline: none;
            filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -webkit-filter: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
            -ms-flex-item-align: drop-shadow(1px 1px 3px $secondary) drop-shadow(-1px -1px 3px $secondary);
        }
    }
}

.networking-container .participant-button.open-chat:disabled {
    cursor: initial;
    background-color: transparent;
}

.participant-button.add-button {
    cursor: pointer;
}

.networking-container {
    border-left: 1px solid #cacbd0;
    clear: both;
}

.networking-container .accept-request {
    display: none;
}

.networking-container .reject-request {
    display: none;
}

.networking-container .custom-scroll-container {
    height: calc(100vh - 197px);
}

.networking-container .empty-results {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f5f5f5;
    height: calc(100vh - 202px);

    @media screen and (max-width: 1024px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 250px);
    }

    .empty-artwork {
        padding: 0 22px;
        margin-top: -53px;

        &.move-artwork {
            margin-bottom: -150px;
        }
    }

    p {
        font-weight: 500;
        font-size: 16px;
        color: $grey-dark;
        margin-bottom: 0;
    }
}

.networking-container .matching-spinner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $white;
    height: calc(100vh - 202px);
    border-top: 1px solid rgba(137, 137, 137, 0.5);

    @media screen and (max-width: 1024px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 250px);
    }

    p {
        font-size: 16px;
        line-height: 18px;
        color: $dark;
        padding: 4px 16px;
    }
}

.networking-wrapper .user-wrapper-separator {
    display: none;
}

@media screen and (max-width: 1024px) {
    .user-container .user-details {
        border: 0;
    }

    .auditorium-page .networking-container .empty-results {
        height: calc(calc(var(--vh, 1vh) * 100) - 290px);
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
    .networking-wrapper .user-wrapper-separator {
        display: block;
    }

    .networking-container .user-container .user-details .social-links-container {
        margin-left: -50px;
    }
}

.participants-page .sort-container {
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #f5f5f5;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    padding: 0 8px;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
}

.participants-page .sort-container span {
    padding: 9.5px;
}

.participants-page .sort-container span:first-of-type {
    padding-left: 10px;
}

.participants-page .sort-container .button {
    border-radius: 18px;
    cursor: pointer;
    text-transform: uppercase;

    &.matching-tooltip-border {
        box-shadow: 0 0 0 1px $primary;

        // Tablet
        @media all and (max-width: 1024px) and (max-height: 1366px) {
            box-shadow: none;
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }

        &.focus-visible {
            outline: none;
            color: $primary !important;
        }
    }
}

.participants-page .sort-container .button.active {
    color: $white;
    background-color: $secondary;

    &:focus {
        outline: none;
        outline-width: 0;
    }

    &.focus-visible {
        outline: none;
        outline-width: 0;
    }
}

@media screen and (max-width: 1024px) {
    .participants-page .sort-container span {
        font-weight: 500;
        font-size: 16px;
    }

    .participants-page .sort-container span.button {
        font-weight: bold;
    }
}

@media screen and (max-width: 374px) {
    .participants-page .sort-container span {
        font-size: 15px;
    }
}

@media screen and (max-width: 355px) {
    .participants-page .sort-container span {
        font-size: 14px;
    }
}

@media screen and (max-width: 330px) {
    .participants-page .sort-container span {
        font-size: 13px;
    }
}

.is-featured {
    position: absolute;
    left: 40px;
    top: 0;
    width: 16px;
    height: 16px;
    background: linear-gradient(137.73deg, #92D142 -15.66%, #34AF20 43.77%, #09955A 100.28%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: #FFFFFF;
        font-size: 13px;
    }
}

.matching-progress-container {
    .matching-percentage {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
    }

    .matching-progress {
        width: 55px;
        height: 6px;
        background-color: #cacbd0;
        border-radius: 10px;

        .progress {
            display: block;
            height: 6px;
            border-radius: 10px;

            &.low-match {
                background-color: #808080;
            }

            &.medium-match {
                background-color: $primary;
            }

            &.high-match {
                background-color: $blue;
            }
        }
    }
}