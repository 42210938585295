@import 'variables.module';
.MuiOutlinedInput-root fieldset {
    border-color: $grey;
}
// FIX: Platform level focused Mui-Input lines overlapping label fix
.MuiInputBase-root {
    font-family: unset !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-family: $fontFamily, sans-serif;
}
// END
.edit-lang-switch-container {
    padding-right: 15px;
}
.upload-program-container {
    .uploaded-file {
        margin-top: 16px;
        width: 308px;
        p {
            height: 20px;
            text-transform: uppercase;
            font-family: $fontFamily, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $dark;
        }
        span {
            color: $secondary;
            text-transform: lowercase;
            width: 120px;
            margin-left: 3px;
        }
    }
    .remove-file {
        background: none;
        border: none;
        cursor: pointer;
        padding-right: 0;
    }
}

.organizer-dashboard-page {
    background-color: #f5f5f5;
    padding: 118px 32px 0;
    color: $dark;
    min-height: 100vh;
    display: flex;
    position: relative;

    @media screen and (max-width: 1385px) {
        padding: 139px 32px 0;
    }

    @media screen and (max-height: 850px) {
        padding-top: 120px;
    }
    .left-sidebar {
        position: fixed;
        height: calc(100vh - 118px);
    }
    .auditorium-page.dropdown-component,
    .contacts-page.dropdown-component,
    .chat-page.dropdown-component {
        top: 121px;
        height: calc(100vh - 121px);
        position: fixed;
        .networking-wrapper {
            height: calc(100vh - 150px);
        }
    }
    .auditorium-page.dropdown-component {
        .smart-wrapper,
        .agenda-wrapper {
            height: calc(100vh - 218px);
        }
        .programme-wrapper {
            height: calc(100vh - 185px);
        }
        .programme {
            .empty-data {
                height: calc(100vh - 320px);
            }
        }
    }
    .organizer-dashboard-container,
    .sessions-wrapper{
        width: calc(100vw - var(--sidebar));
    }
    .organizer-dashboard-container,
    .sessions-wrapper,
    .add-audience-page {
        margin-inline-start: var(--sidebar);
    }

    .add-audience-page {
        padding-inline-start: 32px;
    }

    label,
    input,
    textarea {
        font-family: $fontFamily, sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    label {
        color: $grey-dark;
    }

    .dashboard-with-side-nav {
        .side-navigation {
            position: fixed;
            width: 256px;

            @media screen and (max-height: 850px) {
                position: static;
            }
        }

        .aside-dashboard-container {
            margin-left: 280px;

            @media screen and (max-height: 850px) {
                margin-left: 24px;
            }
        }
    }
    &.not-sticky {
        .side-navigation {
            position: static;
        }
        .aside-dashboard-container {
            margin-left: 24px;
        }
    }
}
.organizer-dashboard-page.has-side-nav {
    padding-left: 0;
}
.organizer-dashboard-page form:not(.chat-message-form) {
    max-width: initial;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Navigation styles */
.inner-dashboard-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.organizer-navigation-container .inner-dashboard-navigation,
.organizer-navigation-container h4 {
    display: none;
}

.organizer-navigation-container {
    margin: 0 auto;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    top: 69px;
    padding: 0 22px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
}
.organizer-navigation-container .dashboard-navigation a {
    padding: 11.5px 12px 7.5px;
    border-radius: 30px;
    margin-right: 10px;
    &.active {
        background-color: $primary;
    }
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 8px $secondary;
        }
    }
}
.organizer-navigation-container button:hover,
.organizer-navigation-container button:focus,
.organizer-navigation-container button:active {
    background-color: transparent;
    box-shadow: none;
}
.organizer-navigation-container button span {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black;
}
.organizer-dashboard-page .advanced-options-title {
    margin: 16px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.organizer-dashboard-page .advanced-options-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px !important;
    background-color: #fff;
    margin-bottom: 24px;
    padding: 24px 16px 16px;
    .device-title {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 20px;
        margin-top: 40px;
    }
    & > div {
        position: relative;
        & > svg {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.organizer-dashboard-page .advanced-options-title,
.organizer-dashboard-page .advanced-options-container,
.organizer-dashboard-page .actions-container,
.organizer-dashboard-page.statistics-dashboard .statistics {
    max-width: 1112px;
}
.actions-container-portrait {
    margin-bottom: 20px;
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    width: auto;
    &.tablet {
        .cover-img-container {
            max-width: 420px;
            height: 315px;
        }
    }
    &.mobile {
        .cover-img-container {
            max-width: 310px;
            height: 310px;
        }
    }
    &.color-picker {
        align-items: flex-start;
        .input-description-alt {
            padding-top: 0;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .options-container-lang {
    display: flex;
    align-items: center;
    margin: 24px 0;
    width: auto;
}
.organizer-dashboard-page .advanced-options-container .options-container-lang .single-option-container {
    flex: 0 0 520px;
}
.organizer-dashboard-page .advanced-options-container .options-container-lang .single-option-container.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 30px;
    position: relative;
}
.organizer-dashboard-page .advanced-options-container .options-container.parent-link {
    margin-bottom: 32px;
}
.branding-homepage-description,
.advanced-options-container {
    .char-count {
        position: relative;
        top: 10px;
        left: 10px;
        font-size: 14px;
    }
    .char-count.error {
        color: $error;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container {
    flex: 0 0 520px;
}
.organizer-dashboard-page {
    .single-option-container {
        textarea {
            ~ svg {
                position: relative;
                top: -85px;
            }
        }
        .select-auditorium {
            & > div {
                max-width: 520px;
            }
        }
    }
}
.branding-participant-registration,
.branding-exhibitor-registration,
.branding-lobby,
.branding-scholar-registration {
    .single-option-container {
        textarea {
            ~ svg {
                position: relative;
                top: -50px;
            }
        }
    }
}
.organizer-dashboard-page .overlay-color-picker {
    width: 100% !important;
    box-shadow: none !important;
    border: 0.5px solid $grey;
    border-radius: 8px !important;
}
.organizer-dashboard-page .overlay-color-picker > div {
    border-radius: 8px 8px 0 0 !important;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container .setting-input {
    margin: 0;
}
.event-dates {
    .MuiOutlinedInput-adornedEnd {
        padding: 0 !important;
    }
}
.MuiButton-label {
    font-weight: bold;
}
.random-banners-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #fff;
    padding: 16px;
    max-width: 1112px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 1.5rem;

    .MuiSvgIcon-root {
        position: relative !important;
        margin-left: 10px;
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container .setting-input label {
    background-color: #fff;
    padding: 0 5px 0 3px;
    color: $grey-dark;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description {
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark;
    padding-left: 25px;

    a {
        color: $secondary;
    }

    svg {
        position: relative;
        margin-left: 10px;
        top: 3px;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    .clipboard-label {
        padding-top: 8px;
    }
}
.input-description {
    line-height: 18px !important;
}
.input-description-portrait {
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;

    p {
        margin: 0;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description-alt {
    padding-top: 52px;
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;
}
.organizer-dashboard-page .advanced-options-container .options-container .input-description-auditorium {
    padding-top: 60px;
    font-size: 16px;
    line-height: 24px;
    color: $text-grey;
    padding-left: 25px;
    p {
        margin: 0;
    }
}
.organizer-dashboard-page .advanced-options-container .options-container {
    .single-option-container {
        &.event-dates {
            display: flex;
            justify-content: space-between;
            &.disabled {
                & > div {
                    pointer-events: none;
                    input,
                    label {
                        color: $disabled-text;
                    }
                    .MuiInputAdornment-root {
                        svg {
                            color: $disabled-text;
                        }
                    }
                    fieldset {
                        border-color: $disabled-text;
                    }
                }
            }
        }
    }
    .input-description {
        p {
            margin: 0;
        }
        .ongoing-wrapper {
            outline: 0;
            span {
                font-weight: 600;
            }
            svg {
                outline: 0;
            }
        }
    }
}
.organizer-dashboard-page
    .advanced-options-container
    .options-container
    .single-option-container.event-dates
    .date-input {
    width: calc(50% - 12.5px);
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url input {
    color: $secondary;
}
.organizer-dashboard-page .advanced-options-container .options-container.event-url p {
    cursor: pointer;
    color: $dark;
    display: flex;
    align-items: center;

    span {
        padding-left: 10px;
    }
}
.clipboard-input-hidden {
    display: none !important;
}
.organizer-dashboard-page .advanced-options-container .inner-options-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $text-grey;
    text-transform: uppercase;
    margin-top: 0;
}
.organizer-dashboard-page .advanced-options-container .editor-wrapper {
    border-radius: 4px;
    max-width: 520px;

    .rdw-editor-main {
        padding: 0 14px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
            }
        }
    }
}
.organizer-dashboard-page .advanced-options-container.predefined-fields-container {
    .switch-container {
        padding-right: 12px;
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                border-color: transparent;
                box-shadow: -1px 1px 5px $secondary, 1px -1px 5px $secondary;
            }
        }
    }

    .inner-options-title {
        padding-bottom: 24px;
    }
}

.manage-partner-logo {
    margin-top: 20px;
}
.manage-partner-logo-portrait {
    display: flex;
    flex-direction: row;
}
.organizer-dashboard-page .advanced-options-container .options-container .single-option-container.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 30px;
    position: relative;
}
.organizer-dashboard-page .child-link {
    .graphic-container {
        width: 16px;
        height: 58px;
        border-left: 2px solid $disabled-text;
        border-bottom: 2px solid $disabled-text;
        position: absolute;
        bottom: 15px;
    }

    .setting-label {
        padding-left: 24px;
    }
}

/* Setup page*/

.organizer-dashboard-page .dashboard-with-side-nav {
    display: flex;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation {
    flex: 0 0 256px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    height: calc(100vh - 116px);
    border-right: 1px solid $disabled-text;

    overflow-y: scroll;
    scrollbar-width: none;

    .add-day {
        padding: 14px 16px 14px 26px;
        font-weight: 600;
        cursor: pointer;
        svg {
            margin-inline-end: 10px;
        }
    }
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        position: relative;
    }

    svg {
        width: 16px;
        height: 16px;
        fill: $primary;
        margin-inline-start: 8px;
    }

    .selected-language {
        display: flex;
        align-items: center;
        padding: 12px 18px;
        border: 1px solid $disabled-text;
        border-radius: 6px;
        background: #ffffff;
        cursor: pointer;

        svg {
            margin: 0;

            path {
                fill: $dark;
            }
        }
    }

    .languages-list-container {
        position: absolute;
        top: 0;
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid $disabled-text;
        box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
        z-index: 1;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: 12px 18px;
                cursor: pointer;

                &:first-of-type {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }

                &:last-of-type {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }

                &.active {
                    background: $primary;
                    color: #ffffff;
                }
            }
        }
    }
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container .MuiSelect-outlined {
    padding: 8px 32px 8px 8px;
    font-size: 14px;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .select-language-container span {
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation .accordion {
    background-color: #f5f5f5;

    &.active {
        margin: 0;
        background-color: $white;

        .Mui-expanded {
            &:hover {
                .delete-day {
                    display: block !important;
                }
            }
        }

        .summary {
            background-color: $primary;
        }

        p {
            overflow-wrap: anywhere;
            margin-left: -4px;
        }

        svg {
            path {
                fill: $secondary;
            }
        }

        .add-new-button {
            border-radius: 6px;
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    box-shadow: 0 0 10px $secondary;
                }
            }
            svg {
                path {
                    fill: $dark;
                }
            }
        }
        .delete-day {
            width: 20px;
            position: relative;
            z-index: 1;
        }
    }
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p {
    margin: 0;
    padding-left: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-wrap: anywhere;
    //font-size: 16px;
    font-weight: 500;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p.disabled .label {
    opacity: 0.5;
}
.organizer-dashboard-page .dashboard-with-side-nav .side-navigation p.disabled .help-icon {
    color: $primary;
    font-size: 20px;
}
.organizer-dashboard-page .aside-dashboard-container {
    flex: 1;
    margin-left: 24px;
    position: relative;
    max-width: calc(100vw - 374px);
}
.organizer-dashboard-page .advanced-options-title .preview-link {
    padding: 10px 20px;
    font-size: 16px;
    color: $secondary;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 6px;

    svg {
        margin-right: 8px;
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.organizer-dashboard-page ul.subtabs {
    list-style: none;
    padding: 0 0 0 32px;
    margin: 0;
    width: 100%;
}
.organizer-dashboard-page ul.subtabs li {
    padding: 16px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    word-break: break-word;
    font-weight: 500;
    &.active {
        color: $primary;
    }
}
.organizer-dashboard-page li .bullet {
    flex: 0 0 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $primary;
    margin-right: 8px;
}

.organizer-dashboard-page .branding-homepage-description {
    form {
        margin-top: 30px;
    }

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
.custom-renders-wrapper {
    display: flex;
    flex-direction: row;

    label {
        &[data-checked='true'] {
            & > span {
                &:first-of-type {
                    span {
                        &:before {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

    .render-save-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px 16px !important;
        border-radius: 6px !important;
    }

    .MuiFormControl-root {
        min-width: 320px;
        margin-right: 80px;
    }

    .lobby-section .options-container {
        flex-direction: column;
        margin-right: 60px;

        .single-option-container {
            display: flex;
            flex-direction: column-reverse !important;
            margin-top: -220px;

            .actions-container {
                margin: 0 0 12px 0;
            }
        }

        .input-description {
            padding: 0 !important;

            @media all and (max-width: 1250px) {
                padding: 25px 0 0 0 !important;
            }
        }
    }

    .lobby-section .preview-only-image {
        z-index: 1;
        margin-top: 14px;
        margin-right: 60px;
        margin-bottom: 70px;

        .preview-only-btn {
            position: absolute;
            bottom: -40px;
            right: 0;
        }
    }

    .lobby-section .cover-img-container {
        min-width: 620px;
        @media all and (max-width: 1410px) {
            min-width: 520px;
        }
        @media all and (max-width: 1310px) {
            min-width: 420px;
        }
        @media all and (max-width: 1210px) {
            min-width: 320px;
        }
        @media all and (max-width: 1120px) {
            min-width: 100%;
        }
    }

    .auditorium-section .options-container {
        flex-direction: column;
        margin-right: 60px;

        .single-option-container {
            display: flex;
            flex-direction: column-reverse !important;
            margin-top: -220px;

            .actions-container {
                margin: 0 0 12px 0;
            }
        }

        .input-description {
            padding: 0 !important;

            @media all and (max-width: 1250px) {
                padding: 25px 0 0 0 !important;
            }
        }
    }

    .auditorium-section .preview-only-image {
        margin-top: 14px;
        margin-right: 60px;
        margin-bottom: 70px;

        .preview-only-btn {
            position: absolute;
            bottom: -40px;
            right: 0;
        }
    }

    .auditorium-section .cover-img-container {
        min-width: 620px;
        @media all and (max-width: 1410px) {
            min-width: 520px;
        }
        @media all and (max-width: 1310px) {
            min-width: 420px;
        }
        @media all and (max-width: 1210px) {
            min-width: 320px;
        }
        @media all and (max-width: 1120px) {
            min-width: 100%;
        }
    }
}
.organizer-dashboard-page .advanced-options-container .branding-background-image-container .options-container {
    align-items: flex-start;
}
.organizer-dashboard-page
    .advanced-options-container
    .branding-background-image-container
    .options-container
    .sublabel {
    display: block;
    margin-top: 20px;
}
.branding-background-image-container .actions-container {
    margin-top: 20px;
    height: 40px;
    display: flex;
    align-items: center;
}
.branding-background-image-container .cover-img-container {
    max-width: 100%;
    height: 195px;
}
.branding-background-image-container .cover-img-container-portrait {
    width: 179px;
    height: 341px;
}
.branding-background-image-container .cover-img-container > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border-radius: 8px;
}
.branding-background-image-container .cover-img-container-portrait > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border-radius: 8px;
}
.organizer-dashboard-page
    .advanced-options-container
    .branding-background-image-container
    .options-container
    .input-description {
    padding-top: 87px;
}
.branding-background-image-container .image-status-container {
    margin-top: 20px !important;
    color: $dark;
}
.branding-background-image-container .uploaded-file .file-name {
    color: $secondary;
}
.organizer-dashboard-page .add-button-container,
.organizer-dashboard-page .add-button-container > span {
    display: flex;
    align-items: center;
    padding: 4px 5px !important;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    font-family: $fontFamily, sans-serif;
    &.sponsored {
        margin-right: 12px;
        & > span {
            padding-bottom: 2px !important;
        }
        div {
            width: auto;
            height: auto;
            display: flex;
        }
        svg {
            path {
                fill: $black;
            }
        }
    }
    &.background-primary {
        background-color: $primary;
    }
    &.collapse {
        svg {
            font-size: 35px;
        }
    }
}
.organizer-dashboard-page {
    .advanced-options-title {
        .invitations-container {
            .add-button-container {
                border: 0;
                span {
                    font-weight: bold;
                }
            }
        }
    }
}
.organizer-dashboard-page .add-button-container svg {
    font-size: 20px;
}
.organizer-dashboard-page .add-button-container span ~ svg {
    color: $primary;
    margin-inline-start: 8px;
}
.organizer-dashboard-page .advanced-options-title .add-button-container {
    border: none;
    background-color: transparent;
    color: $dark;
    font-family: $fontFamily, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    outline: none;
    padding: 10px 24px;

    &.btn-hover-styling {
        color: $dark;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        font-family: $fontFamily, sans-serif;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid transparent;

        svg {
            path {
                fill: #000;
            }
        }

        &:hover {
            border: 1px solid $secondary;
            background-color: $secondary-light;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }

        &:active {
            color: #fff;
            background-color: $secondary;
            box-shadow: none;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        &:disabled {
            color: $text-grey;
            background-color: $light-grey;
            cursor: default;

            svg {
                path {
                    fill: $grey;
                }
            }

            &:hover {
                border: 1px solid transparent;
                box-shadow: none;
            }
        }
    }
}
.organizer-dashboard-page .actions-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
}
.organizer-dashboard-page .aside-dashboard-container .list-wrapper h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.organizer-dashboard-page .aside-dashboard-container .list-wrapper .single-banner-h3 {
    width: 100%;
    .actions-container {
        padding: 0;
    }
}
.single-banner-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.banner-container-500 {
    min-width: 500px;
}
.no-bottom-margin {
    margin-top: 8px;
}
.single-banner-save {
    margin-top: 16px !important;
    padding: 10px 16px !important;
    border-radius: 6px !important;
}
.restyled-save-button {
    padding: 10px 16px !important;
    border-radius: 6px !important;
}
.drag-and-drop-container {
    flex: 0 0 308px !important;
    max-width: 308px;
    .or-wrapper {
        text-align: center;
        padding-bottom: 16px;
        font-weight: 600;
        font-family: $fontFamily, sans-serif;
    }
}
.secondary-upload-button {
    background-color: $secondary !important;
    color: #fff !important;
    width: 308px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    padding: 12px;
    font-family: $fontFamily, sans-serif;
    font-weight: 700;
    font-size: 16px;
    svg {
        display: block;
        position: inherit !important;
    }
    span {
        display: block;
        width: 248px;
        height: 20px;
        text-align: center;
        text-transform: uppercase;
    }
}
.single-banner-delete {
    margin-top: 8px !important;
}
.single-banner-image,
.no-bottom-margin {
    margin-bottom: 0 !important;
    min-width: 297px;
}
.single-banner-input {
    min-width: 297px !important;
}
.organizer-dashboard-page .branding-background-image-container .actions-container {
    justify-content: flex-start;
    padding: 0;
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .branding-background-image-container .actions-container-noP {
    button,
    label {
        margin-right: 20px;
    }
}
.organizer-dashboard-page .program-entry {
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
}

.organizer-dashboard-page .aside-dashboard-container .participants-dashboard .search-container {
    width: 50%;
    background-color: #fff;
}
.organizer-dashboard-page .aside-dashboard-container .participants-dashboard .filters-wrapper,
.organizer-dashboard-page .aside-dashboard-container .participants-list-wrapper {
    background-color: #fff;
}
.organizer-dashboard-page.event-archive-dashboard .side-navigation .add-button-container {
    height: 64px;
}

.timeslot-accordion {
    padding: 18px 16px !important;
}
.timeslot-accordion .summary {
    padding: 0;
}
.organizer-dashboard-page .timeslot-title-details,
.organizer-dashboard-page .field-title-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
.organizer-dashboard-page .timeslot-title-details p,
.organizer-dashboard-page .field-title-details p {
    margin: 0;
}
.organizer-dashboard-page .timeslot-title-details p.title,
.organizer-dashboard-page .field-title-details p.title {
    color: $text-grey;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    flex: 0 0 50%;
}

.organizer-dashboard-page .timeslot-title-details .hours {
    display: flex;
}
.organizer-dashboard-page .timeslot-title-details .hours p {
    display: flex;
    flex-flow: column;
    margin: 0 10px;
}
.organizer-dashboard-page .timeslot-title-details .hours span {
    color: black;
    font-size: 16px;
}
.organizer-dashboard-page .timeslot-title-details .hours .label {
    color: $text-grey;
    font-weight: 500;
    font-size: 14px;
}
.organizer-dashboard-page {
    .timeslot-title-details {
        .expand-label {
            display: flex;
            align-items: center;
            .border-separator {
                width: 1px;
                height: 36px;
                background-color: $disabled-text;
                margin: 0 10px;
            }
        }
        .simple-labels {
            display: flex;
            .simple-sponsored-button {
                background-color: $primary;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 38px;
                margin-right: 12px;
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
    }
    .field-title-details {
        .expand-label {
            display: flex;
            align-items: center;
        }
    }
}
.branding-participant-registration .field-wrapper .switch-container,
.branding-scholar-registration .field-wrapper .switch-container,
.branding-exhibitor-registration .field-wrapper .switch-container {
    padding-right: 12px;
}
.organizer-dashboard-page .timeslot-wrapper,
.organizer-dashboard-page .field-wrapper {
    flex: 1;
}
.organizer-dashboard-page .field-wrapper .custom-fields-container {
    padding-left: 30px;
}
.organizer-dashboard-page button.add-speaker,
.organizer-dashboard-page button.remove-item {
    display: flex;
    align-items: center;
    color: $dark !important;
    text-transform: uppercase;
}
.organizer-dashboard-page.statistics-dashboard {
    .select-report-date {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        p {
            margin: 0 16px 0 0;
            font-weight: bold;
        }
    }
}
.organizer-dashboard-page.statistics-dashboard .statistics {
    padding-bottom: 40px;
    position: relative;
    .table-statistics-container {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
    }
    &.old-statistics {
        padding-top: 24px;
        padding-bottom: 0;
        .statistics-title {
            width: 92%;
        }
        .statistics-table {
            flex: 0 0 100%;
        }
    }
    &.archived-statistics {
        &.is-loading {
            .advanced-options-container {
                max-height: calc(100vh - 200px);
            }
        }
        .advanced-options-container {
            position: relative;
            .spinner {
                position: absolute;
                margin: 0;
            }
        }
    }
}
.organizer-dashboard-page .statistics .statistics-title {
    display: flex;
    justify-content: space-between;
    width: 46%;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 16px 0;
}
@media all and (max-width: 1320px) {
    .organizer-dashboard-page .statistics .statistics-title {
        width: 45%;
    }
}
.organizer-dashboard-page .statistics .statistics-title .button {
    cursor: pointer;
}
.organizer-dashboard-page .statistics .statistics-part {
    display: flex;
    justify-content: space-between;
    margin: 0;
    &.enhanced-statistics {
        justify-content: flex-start;
        .statistics-details {
            width: 50%;
            .statistics-table {
                margin-top: 0;
            }
        }
        .statistics-chart {
            margin-top: 24px;
            flex: initial;
        }
    }
    .statistic-column {
        display: flex;
        flex-flow: column;
        flex: 0 0 50%;
        .statistics-chart {
            flex: auto;
        }
        .statistics-table {
            margin-bottom: 32px;
        }
    }
}
.statistics-part .statistics-table {
    flex: 0 0 50%;
    padding: 0 12px;
}
.statistics-part .statistics-table ul {
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}
.organizer-dashboard-page .statistics .statistics-part .statistics-header {
    background-color: $light-grey;
}
.organizer-dashboard-page .statistics .statistics-part li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    color: #2b2b2b;
    font-weight: 600;
    font-size: 14px;
    min-height: 50px;
}
.organizer-dashboard-page .statistics .statistics-chart {
    flex: 0 0 50%;
    max-height: 330px;
    padding: 0 12px;
}
.organizer-dashboard-page .statistics .statistics-chart .chart {
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 16px;
    height: 100%;
    canvas {
        height: 100% !important;
        width: auto !important;
        margin: 0 auto;
    }
}

.organizer-dashboard-page .smallContainer,
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='true'] {
    max-width: 100%;
    min-height: calc(100vh - 130px);
}
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='false'] {
    height: auto;
}
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='false'] .dashboard-wrapper,
.program-dashboard.organizer-dashboard-page .smallContainer[data-empty='true'] .dashboard-wrapper {
    height: auto;
}
.organizer-dashboard-page .smallContainer[data-empty='false'] .program-section,
.organizer-dashboard-page .smallContainer[data-empty='true'] .program-section {
    padding-bottom: 30px;
    margin: 0;
}
.organizer-dashboard-container .aside-dashboard-container .spinner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    max-width: initial !important;
}
.organizer-dashboard-container .aside-dashboard-container .spinner {
    margin-top: 40px;
    max-width: 1112px;
}
.navigation-container {
    padding-left: 77px;
}
.organizer-dashboard-page .advanced-options-container .action-container {
    margin: 20px 0;
    text-align: left;
    .restyled-save-button {
        margin-right: 20px;
    }
}
.settings-dashboard .advanced-options-container .action-container {
    margin: 20px 0 0;
}
.organizer-dashboard-page .field-wrapper .action-container,
.organizer-dashboard-page .advanced-options-container.predefined-fields-container .action-container {
    margin: 20px 0 0 0;
}
.organizer-dashboard-page {
    .branding-section {
        .single-option-container {
            max-width: 800px;
            margin: 30px auto 0;
        }
    }
}
.dashboard-navigation {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.dashboard-navigation button {
    background-color: transparent;
    box-shadow: none;
    font-weight: bold;
    cursor: pointer;
    color: grey;
    padding: 0;
}

.speakers-dashboard .speakers-list .scrollbar-container .speaker > div p:first-of-type {
    font-size: 16px;
    margin-bottom: 3px;
}
.showfloor-dashboard .box > div {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showfloor-dashboard .box > div > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.showfloor-dashboard .box > div button,
.remove-representative,
.add-representative,
.remove-item,
.notify-speaker,
.add-speaker-button,
.add-to-booth,
.cancel-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    margin-left: 30px;
}
.showfloor-dashboard .box > div > div {
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    margin-left: 40px;
}

.showfloor-dashboard .boothCompany,
.showfloor-dashboard .boothEmail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-inline-end: 15px;
    }
}
.select-showfloor label {
    background-color: #fff;
    padding: 0 5px 0 3px;
}
.showfloor-dashboard .boothEmail a {
    color: $secondary;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
}
.buttons-exhibitor .remove-exhibitor,
.remove-representative,
.remove-item {
    color: $error-light;
}
.buttons-exhibitor .change-exhibitor,
.notify-speaker {
    color: $primary;
}
.notify-speaker-disabled {
    color: grey;
    cursor: default;
}
.add-exhibitor,
.add-representative,
.add-speaker-button,
.add-to-booth {
    color: $secondary;
}
.showfloor-dashboard .box > div:last-of-type {
    padding-bottom: 0;
}
.program-dashboard.organizer-dashboard-page {
    height: auto;
    .program-section {
        &.is-loading {
            min-height: 200px;
            .spinner {
                margin-top: 0;
                //& > div {
                //    margin-top: 20%;
                //}
            }
        }
    }
}
.organizer-dashboard-page .top-container {
    position: fixed;
}
.program-dashboard.organizer-dashboard-page .smallContainer {
    height: auto;
}
.program-dashboard.organizer-dashboard-page .program-section h3,
.program-dashboard.organizer-dashboard-page .program-section h2 {
    padding-bottom: 30px;
    margin: 0;
    color: grey;
    font-family: $fontFamily, sans-serif;
    font-size: 22px;
    letter-spacing: 2px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.program-dashboard.organizer-dashboard-page .program-section h2 {
    font-size: 32px;
    padding-top: 25px;
    align-items: center;
    padding-bottom: 25px;
    padding-right: 60px;
}
.program-dashboard.organizer-dashboard-page.display-program .auditorium-details {
    flex-flow: row;
}
.program-dashboard.organizer-dashboard-page.display-program .program-section h2 {
    padding-right: 30px;
    flex: 1;
}
.program-dashboard.organizer-dashboard-page.display-program .import-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button {
    padding: 7px;
    font-size: 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button:hover {
    border-color: black;
}
.program-dashboard.organizer-dashboard-page .program-section h2 .auditorium-navigation-button.next {
    transform: rotate(180deg);
}
.program-dashboard.organizer-dashboard-page .program-section h3 > div {
    margin: 0;
}
.speakers-list ul,
.representatives-list ul,
.showfloor-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
}
.speakers-list ul li,
.representatives-list ul li,
.showfloor-list ul li {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.speakers-list ul li > div {
    flex: 1;
}
.speakers-list ul li button:first-of-type {
    margin-right: 10px;
}
.representatives-list > p,
.speakers-list > p {
    font-weight: 600;
    font-size: 16px;
    color: #000;
}
.representatives-list ul,
.speakers-list ul {
    margin: 15px 0;
}
.representatives-list ul li,
.speakers-list ul li {
    justify-content: space-between;
}
.representatives-list ul li > div,
.speakers-list ul li > div {
    flex: 1;
    margin-right: 20px;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.speakers-list ul li.speaker div > p {
    margin: 0;
}
.add-representative,
.add-speaker-button {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $fontFamily, sans-serif;
}
#add-exhibitor-dialog #form-dialog-title,
#add-representative-dialog #form-dialog-title,
#add-speaker-dialog #form-dialog-title,
#showfloor-position #form-dialog-title {
    background-color: $primary;
    padding: 0;
}
#add-exhibitor-dialog #form-dialog-title h2,
#add-representative-dialog #form-dialog-title h2,
#add-speaker-dialog #form-dialog-title h2,
#showfloor-position #form-dialog-title h2 {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    text-align: center;
}
.add-exhibitors-list,
#add-representative-dialog .add-representative-title,
#add-speaker-dialog .add-representative-title {
    padding-top: 25px;
}
.add-exhibitors-list > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
}
.add-exhibitors-list > div:last-of-type {
    padding-bottom: 0;
}
#add-exhibitor-dialog .cancel-button,
#add-representative-dialog .cancel-button,
#add-speaker-dialog .cancel-button,
#showfloor-position .cancel-button {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: $text-grey;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: $fontFamily, sans-serif;
}
#showfloor-position > div > div {
    max-width: initial;
}
#add-representative-dialog .add-representative-title,
#add-speaker-dialog .add-representative-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}
#add-representative-dialog #standard-search,
#add-speaker-dialog #standard-search {
    padding-bottom: 10px;
}
.participants-search-wrapper.representatives-list-wrapper {
    height: 100px;
    padding-top: 10px;
    padding-bottom: 0;
    width: 520px;
}
.corg-wrapper {
    .timeslot-title-details {
        .switch-container {
            padding-right: 12px;
            border-radius: 6px;
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    border-color: transparent;
                    box-shadow: -1px 1px 5px $secondary, 1px -1px 5px $secondary;
                }
            }
        }
        .user-container {
            flex: 0 0 40%;
            .participant-avatar {
                margin: auto 0;
                width: 36px;
                height: 36px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                box-shadow: none;
                box-sizing: border-box;
                border-radius: 50%;
                border: 0.5px solid #ffffff;
                position: relative;
                -webkit-margin-end: 16px;
                margin-inline-end: 16px;
                filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
                -ms-flex-item-align: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            }
            .user-details {
                border: none;
                padding: 0;
                .user-name {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .user-name-top {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        text-transform: capitalize;
                        line-height: 95.9%;
                        color: $dark;
                        overflow-wrap: break-word;
                        text-align: start;
                    }
                    p:not(.user-name-top) {
                        margin: 4px 0 0 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 95.9%;
                        word-break: break-word;
                        word-wrap: break-word;
                        color: $grey-dark;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .timeslot-title-details .user-container {
            flex: 0 0 36%;
        }
    }
    @media screen and (max-width: 1200px) {
        .timeslot-title-details .user-container {
            flex: 0 0 24%;
        }
    }
    .add-button-container {
        position: relative;
        left: 4px;
        padding: 0;
        margin-right: 12px;
    }
    #align-labels {
        width: auto;
    }
    .MuiPaper-root {
        border-radius: 6px !important;
        border: 1px solid #cacbd0 !important;
        background-color: $white-variant;
        &:before {
            background-color: transparent;
        }
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .switch-container {
        white-space: nowrap;
    }
    @media screen and (max-width: 1400px) {
        .switch-container:last-of-type {
            margin-right: 20px;
        }
    }
    .MuiButtonBase-root:is(.MuiAccordionSummary-root) {
        cursor: default !important;
    }
    .MuiAccordionDetails-root {
        padding-top: 0;
    }
    .MuiAccordionSummary-content {
        margin: 7px 0;
    }
    .MuiButton-root {
        padding: 8px 12px;
    }
    .acces-tables-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .access-table-wrapper:first-child {
            margin-right: 24px;
        }
        .access-table-wrapper {
            width: 100%;
            max-width: 512px;
            border-radius: 6px;
            background-color: $light-grey;
            border: 1px solid $disabled-text;
            box-sizing: border-box;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            .access-table-header {
                width: 100%;
                padding: 14px 34px 14px 14px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $disabled-text;
                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                    color: $grey-dark;
                }
            }
            .access-table-option {
                width: 100%;
                padding: 3px 28px 3px 14px;
                background-color: $white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $disabled-text;
                .dashboard-label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 95.9%;
                    color: $dark;
                }
                @media (hover: hover) {
                    &:focus {
                        outline: none;
                    }
                    &.focus-visible {
                        outline: none;
                        background-color: $secondary-light;
                    }
                }
            }
            .access-table-option:last-child {
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            .access-table-option.unavailable {
                background-color: $light-grey;
                .dashboard-label {
                    cursor: default;
                    color: $muiDisabled;
                }
            }
        }
    }
    .user-component-closed:not(.MuiAccordion-root.Mui-expanded) {
        margin-bottom: 14px;
    }
}
.participants-search-wrapper.corg-list-wrapper {
    z-index: 999;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 1px solid $disabled-text;
    border-top-width: 0;
    position: absolute;
    height: 218px;
    padding: 0;
    width: 515px;
    left: 2.5px;
    padding: 0 0 0 4px;
    overflow-y: hidden;
    em {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 95.9%;
        color: $grey-dark;
        margin-left: 2px;
    }
    .friends-list {
        padding-top: 10px;
    }
}
.MuiFormControl-marginNormal.corg-search {
    margin: 0;
}

.audience-list-search-wrapper {
    height: 460px;
}
.audience-list-search-wrapper .audience-list {
    list-style-type: none;
    padding: 0;
}
.audience-list-search-wrapper .audience-list .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.audience-list-search-wrapper .audience-list .info-container .user-avatar {
    flex: 0 0 35px;
}
.audience-list-search-wrapper .audience-list .info-container .info-wrapper {
    flex: 1;
}
.representatives-list-wrapper .friends-list .info-container button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:disabled{
        color: grey;
    }
}
.showfloor-dashboard.dashboard-wrapper .see-position-booths {
    text-align: center;
    display: block;
    cursor: pointer;
    padding-top: 10px;
    color: $text-grey;
}
.positioning-image-container {
    width: 95vh;
    height: 50vh;
    padding-top: 24px;
}
.positioning-image-container img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.showfloor-list li,
.showfloor-dashboard .list-header {
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showfloor-dashboard .list-header {
    padding: 20px;
    margin: 0;
    justify-content: flex-start;
}
.showfloor-list li > p {
    width: 150px;
}
.showfloor-list li:last-of-type {
    padding-bottom: 0;
}
.showfloor-list li > div,
.showfloor-dashboard .list-header .buttons-exhibitor,
.showfloor-dashboard .boothCompany,
.showfloor-dashboard .boothEmail,
.showfloor-dashboard .select-showfloor {
    flex: 1 1;
    display: inline-flex;
    justify-content: space-between;
    width: 33.3%;
}
.showfloor-dashboard {
    .button-col {
        justify-content: flex-end;
        max-width: 260px;
    }
    .list-header {
        .buttons-exhibitor {
            span {
                padding-left: 12px;
            }
        }
    }
}
.showfloor-list .exhibitor button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    margin-left: 30px;
}
.showfloor-list {
    position: relative;
}
.showfloor-list .spinner {
    background-color: rgba(255, 255, 255, 0.6);
}
.showfloor-list .exhibitor.custom-booth {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px 20px 150px;
}
.showfloor-list .exhibitor.custom-booth .spinner {
    justify-content: center;
}
.showfloor-list .exhibitor.custom-booth .error-text {
    position: absolute;
    width: auto;
    left: 20px;
    bottom: 5px;
}
.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container {
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: 600px;
}
.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container label {
    width: 170px;
    height: 100px;
    background-color: #eee;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $secondary !important;
}

.showfloor-list .exhibitor.custom-booth .upload-custom-booth-container img {
    height: 100px;
    width: auto;
    margin-left: 80px;
}
.organizer-dashboard-page.statistics-dashboard .building-options-container {
    max-width: 522px;
    padding: 16px 0 0;
}
.organizer-dashboard-page .statistics .name-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 30px;
}
.organizer-dashboard-page .statistics .navigation-button {
    padding: 7px;
    font-size: 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
}
.organizer-dashboard-page .statistics .navigation-button.next {
    transform: rotate(180deg);
}
.organizer-dashboard-page .statistic-title {
    margin-top: 30px;
    padding-left: 15px;
}
.organizer-dashboard-page .statistics-part ul {
    width: 100%;
    padding-left: 0;
}
.organizer-dashboard-page .inner-statistic-container {
    flex-flow: column;
    align-items: flex-start;
    margin-top: 8px;
}
.organizer-dashboard-page .inner-statistic-container .statistics-part {
    width: 100%;
}
.organizer-dashboard-page.statistics-dashboard .online-users-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 522px;
}
.organizer-dashboard-page.statistics-dashboard .refresh-button {
    display: flex;
    align-items: center;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;
}
.organizer-dashboard-page.statistics-dashboard .refresh-button svg {
    margin-right: 10px;
}
.organizer-dashboard-page.statistics-dashboard .online-users-container .users-number {
    font-size: 20px;
    padding: 0 10px;
    color: $primary;
    font-weight: bold;
    letter-spacing: 1px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details {
    display: flex;
    flex-flow: column;
    position: relative;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .edit-auditorium-details-button {
    position: absolute;
    right: 0;
    top: 38px;
    cursor: pointer;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .image-container {
    width: 300px;
    height: 180px;
    border-radius: 15px;
    padding: 10px;
    background-color: #d3d3d35e;
    margin-top: 15px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners {
    padding: 0;
    margin-top: 20px;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners button,
.organizer-dashboard-page .branding-section .manage-partner-logo button,
.organizer-dashboard-page .branding-section .manage-exhibitor-logo button,
.organizer-dashboard-page .branding-section .manage-lobby-logo button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #3f51b5;
    padding-right: 0;
    text-transform: uppercase;
}
.program-dashboard.organizer-dashboard-page .auditorium-details .manage-booth-banners .remove-file,
.organizer-dashboard-page .branding-section .manage-partner-logo .remove-file,
.organizer-dashboard-page .branding-section .manage-exhibitor-logo .remove-file {
    color: $error-light;
}
.branding-section .partners-container,
.branding-section .exhibitors-container,
.branding-section .banners-container,
.branding-section .previous-events-container {
    margin-top: 50px;
}
.branding-section .single-partner-container h3,
.branding-section .single-exhibitor-container h3,
.branding-section .single-banner-container h3,
.branding-section .single-previous-event-container h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 30px;
}
.branding-section .single-partner-container,
.branding-section .single-exhibitor-container,
.branding-section .single-banner-container,
.branding-section .single-previous-event-container {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 2px solid #ccc;
}
.branding-section .single-partner-container p,
.branding-section .single-exhibitor-container p {
    margin-bottom: 10px;
}
.logos-list-container .lobby-logos-container .single-lobby-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;

    .remove-file {
        color: $error-light;
    }

    .cover-img-container {
        margin-bottom: 0;
        flex: 1;
    }

    .cover-img-container-portrait {
        margin-bottom: 0;
        flex: 1;
    }
}
.manage-lobby-logo {
    margin-top: 50px;
}
.branding-section .single-partner-container p > span.label,
.branding-section .single-exhibitor-container p > span.label,
.branding-section .single-previous-event-container p > span.label {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #3f51b5;
    padding-right: 10px;
    min-width: 110px;
    display: inline-block;
}
.organizer-dashboard-page .branding-section {
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
}
.organizer-dashboard-page .branding-section .inner-tabs {
    margin-top: 50px;
}
.organizer-dashboard-page .branding-section form {
    max-width: initial;
    padding: 0;
    margin-bottom: 50px;
}
.branding-section .manage-partner-logo .cover-img-container,
.branding-section .manage-exhibitor-logo .cover-img-container,
.branding-section .manage-lobby-logo .cover-img-container {
    margin-bottom: 0;
    width: 300px;
    height: 200px;
}
.branding-section .manage-partner-logo .cover-img-container-portrait,
.branding-section .manage-exhibitor-logo .cover-img-container-portrait,
.branding-section .manage-lobby-logo .cover-img-container-portrait {
    margin-bottom: 0;
    width: 300px;
    height: 200px;
}
.branding-section .manage-partner-logo .image-wrapper,
.branding-section .manage-exhibitor-logo .image-wrapper,
.branding-section .manage-lobby-logo .image-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto !important;
}
.branding-section .manage-partner-logo .image-wrapper {
    flex-flow: column;
    align-items: flex-start;
}
.branding-section .manage-partner-logo .image-wrapper button {
    line-height: 36px;
}
.branding-section .manage-partner-logo .cover-img-container {
    margin: 20px 0;
}
.branding-section .manage-partner-logo .cover-img-container-portrait {
    margin: 20px 0;
}
.branding-section .sublabel {
    font-size: 12px;
    display: block;
    color: $text-grey;
}
.branding-section .background-image-container {
    display: flex;
    justify-content: space-between;
}
.branding-section .color-picker {
    display: flex;
    flex-flow: column;
}
.branding-section .color-picker .chrome-picker {
    margin: 20px 0;
    max-width: 182px;
}
.branding-section .color-picker p {
    line-height: 24px;
}
.organizer-dashboard-page p.list-empty-text {
    margin: 20px;
}
.participants-table-wrapper {
    margin-bottom: 30px;
    border: 1px solid $disabled-text;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    border-radius: 0 0 6px 6px;
}
.organizer-dashboard-page .participants-dashboard .participants-list-wrapper {
    height: 730px;
    position: relative;
    border-radius: 0 0 6px 6px;
    .single-participant-row {
        align-items: center;
    }
}
.participants-dashboard .participants-list-wrapper .single-participant-row,
.participants-dashboard .participants-list-header {
    display: flex;
    font-size: 14px;
    align-items: flex-start;
}
.participants-dashboard .participants-list-wrapper .single-participant-row:hover {
    background-color: #eee;
}
.dashboard-wrapper.participants-dashboard {
    max-width: 1600px;
}
.participants-dashboard .filters-wrapper {
    padding: 15px;
    background-color: #eee;
    margin: 30px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: auto;
}
.filters-wrapper-general {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px;
    background-color: #fff;
    margin: 30px 0;
    border: 1px solid #ccc;
    border-radius: 8px;

    div {
        margin-right: 1rem;
    }

    .MuiSelect-root {
        margin: 0;
    }
}
.participants-dashboard .search-container {
    width: 400px;
    margin-right: 30px;
}
.participants-dashboard .switch-container {
    margin: 30px 0;
    p {
        margin: 0;
    }
}
.participants-dashboard .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .single-filter-container {
        margin-top: 20px;
    }
}
.participants-dashboard .filters-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin: 0;

    .single-filter-container-invitation {
        margin-top: 18px;
        padding-right: 12px;
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
    .single-filter-container {
        margin-top: 0;
    }
}
.participants-dashboard .filters-list label {
    display: flex;
    align-items: center;
}
.participants-dashboard .filters-list label input {
    margin-left: 0;
}
.participants-dashboard .participants-list-wrapper .single-participant-row > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 45px;
    font-weight: 400;
    margin: 0 10px;
    padding: 0;
    min-height: 19px;
    display: flex;
    align-items: center;

    .MuiSelect-icon {
        left: 0;
    }
    .MuiSelect-select.MuiSelect-select {
        padding: 0 0 0 25px;
        text-align: left;
        line-height: 24px;
    }
}
.participants-dashboard .participants-list-wrapper .single-participant-row > div.delete {
    padding: 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: 0 1px !important;
}
.participants-dashboard .participants-list-wrapper .single-participant-row > div.delete.delete-helper {
    margin-left: auto !important;
}
.participants-dashboard .participants-list-header {
    background-color: $light-grey;
    border-radius: 6px 6px 0 0;
}
.participants-list-header > div {
    margin: 0 10px;
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 12px;
    color: $text-grey;
}
.participants-list-wrapper .single-participant-row .name,
.participants-dashboard .participants-list-header .name {
    width: 19.7%;
}
.participants-list-wrapper .single-participant-row .company,
.participants-dashboard .participants-list-header .company {
    width: 18%;
}
.participants-list-wrapper .single-participant-row .email,
.participants-dashboard .participants-list-header .email {
    width: 19.7%;
}
.participants-list-wrapper .single-participant-row .phone,
.participants-dashboard .participants-list-header .phone {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .registration-date,
.participants-dashboard .participants-list-header .registration-date {
    width: 14.18%;
}
.participants-list-wrapper .single-participant-row .role,
.participants-list-wrapper .single-participant-row .registration-date {
    margin: 0 14px !important;
}
.participants-list-wrapper .single-participant-row .invitation-code {
    margin: 0 5px !important;
}
.participants-list-wrapper .single-participant-row .registration-type,
.participants-dashboard .participants-list-header .registration-type {
    width: 8%;
}
.participants-list-wrapper .single-participant-row .access-package,
.participants-dashboard .participants-list-header .access-package {
    width: 12%;
    height: 45px;
    @media screen and (max-width: 1551px) {
        padding-left: 12px;
    }
}
.participants-list-wrapper .single-participant-row .invitation-code,
.participants-dashboard .participants-list-header .invitation-code {
    width: 7.5%;
}
.participants-list-wrapper .single-participant-row .role,
.participants-dashboard .participants-list-header .role {
    width: 15.19%;
}
.participants-list-wrapper .single-participant-row .delete,
.participants-dashboard .participants-list-header .delete {
    width: 73px;
    text-align: center;
    margin-left: auto;
}
.participants-list-wrapper .single-participant-row .access-package .select-user-package {
    width: 100%;
    font-size: 12px;
    text-align: right;
}
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:before,
.participants-list-wrapper .single-participant-row .access-package .MuiInput-underline:after {
    display: none !important;
}
.participants-list-wrapper .single-participant-row .access-package .MuiSelect-select:focus {
    background-color: transparent !important;
}
.participants-list-wrapper .single-participant-row .access-package .MuiInputBase-root {
    max-width: 100%;
}
.participants-list-wrapper .single-participant-row .access-package .MuiSelect-selectMenu {
    font-family: $fontFamily, sans-serif;
}
.snackbar div {
    margin-left: auto;
    margin-right: auto;
}
.snackbar-success-message {
    display: block;
    width: 100%;
    text-align: center;
}
.settings-dashboard .advanced-options-container .big-centered-button {
    width: 100%;
    max-width: 440px;
    height: 48px;
    margin: 0 auto 10px;
    display: block;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container .timeslot-details {
    padding-right: 30px;
}
.program-dashboard.organizer-dashboard-page .archived-event-videos-container .single-video-container .small-label {
    display: block;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .timeslot-details
    p {
    margin-bottom: 10px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .speakers-container
    .speaker {
    display: inline-block;
    padding-right: 5px;
}
.speakers-container {
    padding-bottom: 20px;
    .single-option-container .MuiFormLabel-root {
        font-size: 14.5px;
    }
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video {
    width: 250px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video
    img {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}
.program-dashboard.organizer-dashboard-page
    .archived-event-videos-container
    .single-video-container
    .image-preview-video
    .actions-container {
    display: flex;
    justify-content: space-between;
}
.invitations-wrapper {
    max-width: 1112px;
}
.invitation-codes-action-wrapper {
    margin: 30px 0;
}
.invitation-codes-action-wrapper button {
    margin-right: 30px;
}
.invitation-codes-list {
    list-style: none;
    padding: 0;
    border: 1px solid $disabled-text;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
}
.invitation-codes-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 0 16px 10.5px;
    border-bottom: 1px solid $disabled-text;
    background-color: #ffffff;

    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-of-type {
        border: 0;
    }
}
.invitation-codes-list li:hover {
    background-color: $light-grey;
}
.invitation-codes-list li.list-header {
    text-transform: uppercase;
    color: $text-grey;
    background: $light-grey;
    padding: 12.5px 0 16px 12.5px;

    svg {
        width: 17px;
        height: 9px;
        margin-inline-start: 8px;
    }
}
.invitation-codes-list li.list-header:hover {
    background: $light-grey;
}
.invitation-codes-list li .invitation-details {
    display: flex;
    width: 100%;

    #light {
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.4px;
    }
}
.invitation-codes-list li .invitation-details p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 95.9%;
    text-align: right;
    width: 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    min-height: 19px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:first-of-type {
        justify-content: flex-start;
    }
}
.invitation-codes-list li .invitation-details p.code {
    margin-left: 7px;
    width: 66%;
}
.ReactVirtualized__Table__rowColumn {
    margin: 0 !important;
}
.virtualized-list-paper {
    width: 100%;
    height: 51.3vh;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $disabled-text;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    direction: rtl;
    overflow: hidden;
    // Chrome and others
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #777081;
        border: 1px solid #ffffff;
        border-radius: 8px;
    }
    // Firefox specific
    scrollbar-color: #777081 transparent;
    scrollbar-width: thin;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
    direction: ltr;
    writing-mode: horizontal-tb;
}
.invitation-spinner-container {
    height: 57vh;
    .spinner {
        max-width: 1112px;
        background-color: transparent;
    }
}
.MuiTableCell-alignRight {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}
.invitation-actions {
    all: unset;
    cursor: pointer;
    margin-left: 1.3vw;

    @media all and (min-width: 1700px) {
        margin-left: 1vw;
    }
    @media all and (max-width: 1300px) {
        margin-left: 0.9vw;
    }
    @media all and (max-width: 1290px) {
        margin-left: 1vw;
    }
    @media all and (max-width: 1200px) {
        margin-left: 0.5vw;
    }
    @media all and (max-width: 1100px) {
        margin-left: 0.2vw;
    }
    @media all and (max-width: 1024px) {
        margin-left: 0;
    }

    svg {
        width: 20px;
        height: 24px;

        path {
            fill: $dark;
        }
    }

    &:hover {
        svg {
            path:not(:last-child) {
                fill: $error-light;
            }
        }
    }

    &:disabled {
        cursor: default;
        svg {
            path {
                fill: $grey;
            }
        }
    }
}
.ReactVirtualized__Table__Grid:focus {
    outline: none;
}
.audiences-list-wrapper .audiences-actions,
.campaigns-list-wrapper .campaigns-actions {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}
.campaigns-list-wrapper {
    margin-bottom: 25px;
}
.single-audience-row,
.audiences-list-header,
.single-campaign-row,
.campaigns-list-header {
    display: flex;
    font-size: 14px;
    align-items: center;
}
.single-campaign-row {
    align-items: initial;
}
.single-audience-row:hover,
.single-campaign-row:hover {
    background-color: #eee;
}
.audiences-list-header .name,
.single-audience-row .name,
.campaigns-list-header .subject,
.single-campaign-row .subject {
    width: 30%;
    margin-left: 0;
}
.audiences-list-header .users-count,
.single-audience-row .users-count,
.campaigns-list-header .audience-name,
.single-campaign-row .audience-name {
    width: 20%;
    text-align: center;
    justify-content: center;
}
.audiences-list-header .users-count,
.single-audience-row .users-count,
.campaigns-list-header .sent-on,
.single-campaign-row .sent-on {
    width: 15%;
    text-align: center;
    justify-content: center;
}
.audiences-list-header .assigned-to,
.single-audience-row .assigned-to {
    width: 20%;
    text-align: center;
    justify-content: center;
}
.campaigns-list-header .preview,
.single-campaign-row .preview {
    width: 10%;
    text-align: center;
    justify-content: center;
}
.campaigns-list-header .wide,
.single-campaign-row .wide {
    width: 60%;
}
.campaigns-list-header .wide > span,
.single-campaign-row .wide > span {
    text-transform: none;
}
.audiences-list-header .actions,
.single-audience-row .actions {
    width: 20%;
    text-align: center;
    justify-content: space-around;
    padding: 7.5px 0 !important;
}
.audiences-list-header .actions {
    width: 20%;
    text-align: center;
    justify-content: space-around;
    padding: 20px 0 !important;
}
.single-campaign-row .actions,
.campaigns-list-header .actions {
    width: 30%;
    text-align: center;
    justify-content: space-around;
}
.single-audience-row .actions,
.single-campaign-row .actions,
.single-campaign-row .preview {
    padding: 0;
}
.audiences-list-header > div,
.campaigns-list-header > div {
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 20px 0;
    text-transform: uppercase;
}
.campaigns-list-header > div {
    font-weight: 600;
}
.single-audience-row > div,
.single-campaign-row > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 15px 0;
    min-height: 19px;
    display: flex;
    align-items: center;
}

.add-audience-page .audience-name {
    width: 50%;
}
.add-audience-page .participants-dashboard .search-container {
    margin-bottom: 30px;
}

.add-audience-page .participants-list-wrapper .single-participant-row .name,
.add-audience-page .participants-dashboard .participants-list-header .name {
    width: 27%;
}
.add-audience-page .participants-list-wrapper .single-participant-row .title,
.add-audience-page .participants-dashboard .participants-list-header .title {
    width: 20%;
}
.add-audience-page .participants-list-wrapper .single-participant-row .status,
.add-audience-page .participants-dashboard .participants-list-header .status {
    width: 10%;
}
.add-audience-page .participants-list-wrapper .single-participant-row .role,
.add-audience-page .participants-dashboard .participants-list-header .role {
    width: 13%;
}
.add-audience-page .participants-list-wrapper .single-participant-row .action,
.add-audience-page .participants-dashboard .participants-list-header .action {
    width: 10%;
    cursor: pointer;
    text-align: center;
}
.add-audience-page .participants-list-wrapper .single-participant-row .action {
    padding: 0;
    justify-content: center;
}

.rdw-link-modal {
    max-height: 180px;
}

.rdw-link-modal-target-option {
    display: none;
}
.superadmin-dashboard {
    .rdw-link-modal-target-option {
        display: block;
    }

    .rdw-link-modal {
        max-height: initial;
        height: auto;
    }

    .gdpr-dashboard {
        .options-container {
            .single-option-container {
                width: 100%;

                div {
                    width: 100%;
                    height: 98%;
                }
            }
            .rdw-editor-main {
                max-height: initial;
            }
        }
        .action-container {
            button {
                margin-inline-start: 10px;
            }
        }
    }
}
.speaker-main-info-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}
.confirm-event-privacy-policy {
    margin-top: 20px;
}
.confirm-event-privacy-policy label {
    margin: 0 !important;

    &:first-of-type {
        padding-bottom: 15px;
    }
}
.confirm-text p {
    color: $text-grey;
}
.confirm-event-privacy-policy .confirm-event-privacy-policy-text {
    margin-left: 20px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
}
.event-payment-information ul li span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 450px;
}
.organizer-dashboard-page .payment-setup {
    color: $text-grey;
}
.organizer-dashboard-page .payment-setup .stripe-link {
    padding: 10px 20px;
    border: 1px solid #3f51b5;
    border-radius: 10px;
    color: #3f51b5;
    transition: all 0.2s;

    &:hover {
        background-color: #3f51b5;
        color: white;
        transition: all 0.2s;
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 4px 10px $secondary;
            background-color: #3f51b5;
            color: white;
            transition: all 0.2s;
        }
    }
}
.commisions a {
    color: $secondary;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            font-weight: bold;
            border-bottom: 1px solid $secondary;
        }
    }
}
.organizer-dashboard-page .payment-setup .connection-details,
.organizer-dashboard-page .payment-setup .currency {
    padding: 15px;
    background-color: #eee;
    margin: 30px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
}
.organizer-dashboard-page .payment-setup .connection-details {
    margin-top: 0;
}
.organizer-dashboard-page .payment-setup .connection-details h4 {
    margin-bottom: 20px;
    margin-top: 0;
}
.organizer-dashboard-page .payment-setup .connection-details p span {
    font-style: italic;
    color: black;
}
.organizer-dashboard-page .payment-setup .currency-container {
    display: flex;
    align-items: center;
    .currency-info-icon {
        width: 16px;
        height: 16px;
        fill: $primary;
        margin-inline-start: 8px;
        margin-inline-end: 30px;
    }
}
.organizer-dashboard-page .payment-setup .currency .helper-text {
    padding-top: 10px;
    font-size: 13px;
    font-style: italic;
}
.organizer-dashboard-page .access-packages-setup .packages-list {
    columns: 2 475px;
}
// don't delete the double width, else the above layout effect will break
.access-package-container {
    display: inline-block;
    width: 50%;
    width: 100%;
    height: 100%;
    padding: 15px 7.5px;
}
.organizer-dashboard-page .access-packages-setup {
    max-width: 1112px;
}
.organizer-dashboard-page .access-packages-setup .package {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    div {
        .package-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .name {
                width: 320px;
                height: auto;
                word-wrap: break-word;
            }
        }
    }
}
.organizer-dashboard-page .access-packages-setup .package .package-description {
    color: $text-grey;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
}
.organizer-dashboard-page .access-packages-setup .package.disabled {
    background-color: #fcfcfc;
}
.organizer-dashboard-page .access-packages-setup .access-package-container .label {
    font-style: italic;
    color: $text-grey;
}
.access-packages-setup .access-package-container .package-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
.access-packages-setup .access-package-container .package-description .label {
    display: block;
}
.access-packages-setup .access-package-container .package-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}
.access-packages-setup .access-package-container .package-header .package-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    min-width: 76px;
    margin-left: 16px;
}
.access-packages-setup .access-package-container .price-container {
    margin-top: 50px;
    .price {
        margin-top: 50px;
        color: $primary;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        white-space: nowrap;
    }
}
.access-packages-setup .access-package-container .package-actions .edit-container button {
    margin-left: 20px;
}
.access-packages-setup .access-package-container .package-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;

    button {
        color: #211e16;
        font-size: 16px;
        line-height: 20px;
        margin-right: 8px;

        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .switch-container {
        font-size: 16px;
        line-height: 20px;
        font-style: normal;
        margin-right: 8px;
        padding: 0 8px;
        border-radius: 4px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }

        label {
            margin-right: 0;
            color: #211e16;
        }
    }
}
.access-packages-setup .access-package-container .package-buttons-container button:first-child {
    margin-left: 10px;
    .MuiButton-label {
        white-space: nowrap;
        .MuiButton-startIcon {
            margin: 0;
        }
    }
}
.switch-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            border-color: transparent;
            box-shadow: -1px 1px 5px $secondary, 1px -1px 5px $secondary;
        }
    }
}
#align-labels {
    width: 100px;
}
.access-management .access-management-table {
    background-color: #eee;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    .access-types {
        flex: 0 0 200px;
        width: 200px;
        div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left !important;
            font-weight: bold;
            &.subsection-title {
                font-weight: normal;
                padding-left: 32px;
            }
        }
    }
    .packages-columns {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        flex: 1;
        .column {
            flex: 0 0 120px;
            display: flex;
            flex-flow: column;
            align-items: center;
        }
    }
    .column {
        & > div {
            height: 40px;
            line-height: 40px;
            text-align: center;
            padding: 0 8px;
        }
        & > div.title {
            line-height: 20px;
            word-break: break-all;
        }
    }
}
.organizer-dashboard-page .timeslot-resources-container {
    padding-top: 20px;
    & > div {
        padding-bottom: 20px;
    }
    .add-link-form-container {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .action-container {
            margin: 0;
        }
    }

    .editor-wrapper {
        ul {
            padding-left: 24px;
        }
        ol {
            padding: 0;
            margin: 0;
        }
        ul li {
            display: list-item;
            list-style: disc;
        }
    }

    ul :not(.editor-wrapper) :not(.link-container) {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            a {
                color: $dark;
                font-weight: bold;
            }
        }
    }
    .add-resource-container.uploadFile {
        justify-content: flex-start;
    }
    .add-resource-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin: 0 -15px;
        margin-right: 0 !important;
        align-items: flex-start;

        .options-container {
            margin-bottom: 0;
            flex: 0 0 50%;
            padding: 0 15px;
        }

        .single-option-container {
            flex: auto;
        }

        .new-file-upload-container {
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            label {
                display: flex;
                align-items: center;

                span {
                    &:first-of-type {
                        font-weight: bold;
                    }
                    &:last-of-type {
                        padding-left: 10px;
                        font-weight: 500;
                    }
                }
            }

            p {
                margin: 5px 0 0 0;
            }
        }
    }
}
.organizer-dashboard-page .account-not-approved-container {
    flex-flow: column;
    padding-top: 48px;

    p {
        margin: 0 0 24px;
    }
}
.organizer-dashboard-page .statistics-table {
    &.actionable {
        li {
            &:hover {
                cursor: pointer;
            }
            span {
                padding: 0;
            }
        }
        .statistics-header span {
            font-size: 12px;
        }
        .title {
            width: 50%;
            display: flex;
            align-items: center;
            &.attendees {
                svg {
                    color: $primary;
                    margin-inline-start: 4px;
                }
            }
            &.title-item {
                span {
                    font-weight: 500;
                }
            }
        }
        .action {
            width: 30%;
            text-align: center;
        }
        .counter {
            width: 20%;
            text-align: right;
            padding-inline-end: 0;
        }
    }
}
.organizer-dashboard-page .statistics {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    .statistics-header {
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        .StatisticUsers__title {
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
.organizer-dashboard-page .statistics .StatisticUsers {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    .statistics-header {
        padding: 6px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        min-height: 45px;
        .StatisticUsers__title {
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    &__userListHolder {
        flex: 1;
        min-height: 250px;
        ul {
            box-shadow: none;
            border-radius: 0;
            li {
                border-bottom: none;
                .user-container {
                    width: 100%;
                    .participant-avatar {
                        width: 36px;
                        height: 36px;
                    }
                    .user-details {
                        border-bottom: none;
                        .actions {
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }
}
.package-filters {
    margin-bottom: 30px;
    p {
        margin-top: 0;
    }

    & > div {
        width: 150px;

        .MuiFormLabel-root {
            font-family: $fontFamily, sans-serif;
        }
    }

    .MuiFormControl-root {
        margin-left: 12px;
    }
}
#menu-filterPackage li {
    font-family: $fontFamily, sans-serif;
}
.branding-lobby {
    .custom-renders-wrapper {
        .link-focus {
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    border-bottom: 1px solid $secondary;
                }
            }
        }
        .MuiFormControl-root {
            margin-bottom: 80px;
            .radio-fix {
                @media (hover: hover) {
                    &:focus {
                        outline: none;
                    }
                    &.focus-visible {
                        outline: none;
                        background-color: $secondary-light;
                    }
                }
            }
            label span:last-child {
                font-family: $fontFamily, sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
                color: black;
            }
            label {
                position: relative;
                padding: 15px 0;
                display: flex;
                justify-content: space-between;
                margin: 0 0 0 10px;
                border-bottom: 1px solid #ccc;
            }
            label > span span {
                color: $primary;
            }
            label svg {
                display: none;
            }
            label input {
                -webkit-appearance: none;
            }
            label[data-checked='true'] span:last-child {
                color: $primary;
            }
            label[data-checked='true']:after {
                right: 5px;
                position: absolute;
                transform: translateY(-50%);
                left: initial;
            }
        }
        @media all and (max-width: 1250px) {
            .preview-link-btn {
                left: 0;
                margin: 90px 0 0 400px;
            }
        }
        .preview-link-btn {
            margin-right: 60px !important;
            position: absolute !important;
            right: 0 !important;
        }
        .preview-link-btn-label {
            font-family: $fontFamily, sans-serif;
            font-size: 16px;
            color: $secondary;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: bold;

            svg {
                margin-right: 8px;
            }
        }
    }
}
.organizer-dashboard-page.buildings-dashboard {
    // Setup Tab with all buildings
    .buildings-setup {
        .buildings-render-container {
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            border-radius: 8px;
            margin-bottom: 40px;
            max-width: 1112px;
            img {
                width: 100%;
                height: auto;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin-top: 16px;
            background: #f5f5f5;
            text-transform: uppercase;
            border: 1px solid $disabled-text;
            box-sizing: border-box;
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            border-radius: 6px;
            padding-bottom: 104px;
            margin-bottom: 104px;
            li {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-bottom: 1px solid #ccc;
                font-weight: 500;
                font-size: 14px;
                line-height: 32px;
                background-color: #fff;
                &.list-header {
                    color: $text-grey;
                    font-weight: 500;
                    font-size: 12px;
                    background-color: transparent;
                }
                &:last-child {
                    border-bottom: none;
                }
                &.selected {
                    background-color: #e6e6e6;
                }
                .building-index-container {
                    flex: 1;
                }
                .building-name-container {
                    flex: 1;
                }
                .building-type-container {
                    flex: 1;
                    position: relative;
                    cursor: pointer;
                    span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .building-type-selection {
                        position: absolute;
                        background-color: #fff;
                        z-index: 2;
                        cursor: initial;
                        padding: 0;
                        border-radius: 6px;
                        margin-top: 0;
                        li {
                            cursor: pointer;
                            &.active {
                                color: var(--theme-primary-color);
                            }
                        }
                    }
                }
            }
        }
    }
    // main side navigation with all buildings
    .buildings-list {
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        list-style: none;
        background-color: #f5f5f5;
        border-right: 1px solid #ccc;
        flex: 0 0 256px;
        width: 256px;
        li {
            padding: 0 16px 0 24px;
            border-left: 4px solid transparent;
            transition: all 0.2s;
            cursor: pointer;
            display: flex;
            flex-flow: column;
            &.setup-option {
                padding: 16px 16px 16px 24px;
            }
            &.active {
                background-color: #fff;
                color: var(--theme-primary-color);
                border-left-color: var(--theme-primary-color);
                transition: all 0.2s;
            }
            .building-name-container {
                padding: 16px 0 16px 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    // When building is active, dropdown building navigation
    .building-navigation {
        padding-left: 32px;
        font-size: 16px;
        line-height: 36px;
        color: #211e16;
        &-tab {
            padding: 8px 0;
            white-space: nowrap;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.active {
                color: var(--theme-primary-color);
            }
        }
    }
    // When building is active, second side navigation
    .single-building-dashboard {
        display: flex;
        flex: 1;
        .side-navigation {
            p {
                padding-left: 20px;
            }
            .accordion.active .summary {
                border-left-color: transparent;
            }
            .MuiAccordionSummary-root {
                min-height: 52px !important;
            }
            .MuiAccordion-root:before {
                display: none !important;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            ul.subtabs {
                padding: 0 0 0 24px;
                .bullet {
                    opacity: 0;
                    transition: all 0.2s;
                }
                .active {
                    .bullet {
                        opacity: 1;
                        transition: all 0.2s;
                    }
                }
                .add-new-button {
                    margin-left: -6px;
                }
            }
        }
    }
}
.gdpr-dashboard {
    height: calc(100vh - 116px);

    .gdpr-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;

        p {
            font-weight: 600;
        }

        h4 {
            margin: 0;
        }
    }

    .action-container {
        display: flex;
        align-items: center;

        button {
            position: relative;
            left: 0;
        }

        & > div {
            display: flex;
            align-items: center;
            font-size: 14px;

            & > svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    form {
        height: 100%;
    }

    .options-container {
        height: calc(100% - 142px);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px !important;
        background-color: #fff;
        padding: 24px 16px 16px;
        position: relative;

        &.event-page-dashboard {
            display: flex;
            flex-direction: column;

            & > div {
                &:first-of-type {
                    display: flex;
                    flex: 1;
                    height: calc(100% - 142px);
                }
            }

            .single-option-container {
                width: 60%;

                div {
                    width: 100%;
                    height: 100%;
                }
            }

            .input-description {
                display: flex;
                flex: 1;
                align-items: center;
                padding-left: 20px;
                color: $text-grey;
            }

            .action-container {
                margin-top: 30px;
                justify-content: flex-start;
            }
        }

        .editor-wrapper {
            height: 100%;
            border: 0;

            .rdw-editor-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .rdw-editor-main {
                height: 100%;
            }
        }

        .single-option-container {
            height: 100%;
            display: flex;

            .MuiOutlinedInput-multiline {
                height: 100%;
                padding: 18.5px 14px 18.5px 14px;

                textarea {
                    height: 100%;
                }
            }
        }
    }

    .advanced-options-container {
        box-shadow: none;
        padding: 0;
        max-width: initial;
    }
}

#disclaimer-text {
    & > div {
        font-size: 12px;
        font-family: $fontFamily, sans-serif;
    }
}

.empty-statistics-users {
    min-height: 200px;
    display: flex;
    align-items: center;
    & > div {
        margin: 0 30px;
        text-align: center;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $text-grey;
            margin-bottom: 0;
        }
    }
}
.color-picker {
    .actions-container {
        justify-content: flex-start;
        button {
            padding-left: 0;
            padding-right: 0;
            margin-right: 20px;
            min-width: auto;
        }
    }
}
.branding-colors {
    .advanced-options-container {
        & > div {
            &:first-of-type {
                margin-bottom: 80px;
            }
        }
    }
}
#info-img {
    .MuiTooltip-tooltip {
        max-width: initial;
        background-color: white;
        padding: 0;
    }
    img {
        max-width: 300px;
    }
}
.img-container-preview {
    position: absolute !important;
    top: 0 !important;
    left: -100px;
}
/* new redesign partners and exhibitors */
.new-accordion-design {
    .advanced-options-container {
        padding: 30px 16px;
        .list-empty-text {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            color: $grey-dark;
        }
    }
    .actions-container {
        .add-button-container {
            padding: 10px 24px;
        }
    }
}
.accordion-title-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.accordion-wrapper-details {
    width: 100%;
}
.accordion-container {
    &.new-role-container {
        margin-bottom: 24px;
        & > div {
            padding: 26px 16px;
        }
        .accordion-wrapper-details {
            & > p {
                margin: 0;
                padding-top: 24px;
                font-size: 14px;
                font-weight: 600;
                color: $grey;
            }
        }
        .accordion-title-details {
            .title {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                text-transform: uppercase;
                color: $grey-dark;
            }
        }
        .MuiAccordionSummary-root {
            min-height: initial;
            padding: 0;
            &.Mui-expanded {
                min-height: initial;
            }
        }
        .MuiAccordionSummary-content {
            margin: 0;
            &.Mui-expanded {
                margin: 0;
            }
        }
        input {
            font-family: $fontFamily, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.87);
            padding-inline-end: 10px;
        }
        label {
            font-weight: 500;
            font-size: 16px;
            font-family: $fontFamily, sans-serif;
            &.Mui-focused {
                color: $secondary;
            }
            &.Mui-error {
                color: $error;
            }
        }
        legend {
            font-size: 14px;
        }
        form {
            width: 50%;
            button {
                border: 1px solid $disabled-text;
                padding: 9px 12px;
                font-size: 16px;
                font-weight: bold;
                font-family: $fontFamily, sans-serif;
                color: $dark;
                margin-top: 16px;
                svg {
                    fill: $dark;
                }
                &.Mui-disabled {
                    color: $grey;
                    svg {
                        fill: $grey;
                    }
                }
            }
        }
        h4 {
            font-weight: 500;
            font-size: 20px;
            text-transform: uppercase;
            color: $grey;
            margin: 0;
            padding-top: 40px;
        }
        .actions-container {
            justify-content: flex-start;
            margin-top: 24px;
            padding-bottom: 30px;
            button,
            label {
                margin-right: 20px;
            }
        }
        .options-container {
            margin: 0;
            align-items: flex-start;
            .input-description {
                padding-top: 93px;
            }
        }
        .manage-partner-logo {
            margin: 0;
        }
        .cover-img-container {
            width: 100%;
            max-width: initial;
            height: 264px;
            border-radius: 8px;
            border: 1px solid $disabled-text;
            background: $disabled-text;
            margin-bottom: 0;
            label {
                max-width: 360px;
                position: relative;
                display: flex;
                height: 100%;
                align-items: center;
                svg {
                    position: absolute;
                    left: 0;
                    top: 20px;
                }
            }
            span {
                font-weight: bold;
                font-size: 16px;
                color: $white;
                text-transform: uppercase;
                line-height: 20px;
                font-family: $fontFamily, sans-serif;
            }
        }
        .dialog-btn-uppercase {
            .button-content {
                color: $dark;
                display: flex;
                align-items: center;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
.single-banner-link {
    all: unset;
    cursor: pointer;
    color: $dark;
}
.textarea-wrapper {
    position: relative;
    textarea {
        min-height: 150px;
    }
    .MuiOutlinedInput-multiline {
        padding-bottom: 24px;
    }
    &.error-wrapper {
        .characters-counter {
            bottom: 35px;
        }
    }
    .characters-counter {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-weight: 500;
        font-size: 12px;
        color: $grey-dark;
    }
}
.branding-disable-login {
    .advanced-options-container {
        .action-container {
            text-align: left;
            button {
                border: 1px solid $grey;
                padding: 9px 14.5px;
                &.Mui-disabled {
                    color: $grey;
                }
            }
        }
    }
}
// remove arabic styles from online user box - statistics
html {
    &.lang-rtl {
        .organizer-dashboard-page {
            .is-offline {
                .participant-avatar {
                    &:after {
                        left: initial;
                        right: 0;
                    }
                }
            }
            .participant-button {
                &.open-chat {
                    left: initial;
                    right: 0;
                }
            }
            .StatisticUsers__userListHolder {
                .scrollbar,
                .box {
                    direction: ltr;
                }
                .box {
                    margin-right: -17px !important;
                    margin-left: 0 !important;
                }
            }
        }
    }
}
.create-session-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
}
.corg-gdpr-width {
    max-width: 900px !important;
}
// Platform custom scrollbars apply to
// react-wysiwyg editor textarea, all other textareas and
// ACCESS > Invitation Codes > Virtualized Table
.rdw-editor-main,
textarea,
.ReactVirtualized__Grid {
    // Rest of the browsers
    &::-webkit-scrollbar {
        width: 4.5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
        background: #777081;
        border: 1px solid #ffffff;
        border-radius: 8px;
    }
    // Firefox
    scrollbar-color: #777081 transparent;
    scrollbar-width: thin;
}
.core-copy-button {
    padding-right: 10px;
    padding-bottom: 8px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 0px 10px $secondary;
        }
    }
}
.subtab-settings-li {
    padding: 16px 6px !important;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 0px 10px $secondary;
        }
    }
}
.display-timezone-wrapper {
    display: inline-block;
    position: relative;
    border-radius: 6px;
    padding-left: 6px;
    left: -6px;

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 8px $secondary;
        }
    }
}
.organizer-dashboard-page .add-button-container:not(.statistics-main, .archive-main) {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 8px $secondary;
        }
    }
}
.organizer-dashboard-page .add-button-container.archive-main {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: none;
            background-color: $secondary-light;
        }
    }
}
@keyframes uparrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4;
    }
    100% {
        -webkit-transform: translateY(-0.2em);
        opacity: 0.9;
    }
}
@keyframes downarrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4;
    }
    100% {
        -webkit-transform: translateY(0.2em);
        opacity: 0.9;
    }
}
.upload-label.banner-upload {
    padding: 6px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
            svg {
                animation: uparrow 0.6s infinite alternate ease-in-out;
                -webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
                -o-animation: uparrow 0.6s infinite alternate ease-in-out;
                -moz-animation: uparrow 0.6s infinite alternate ease-in-out;
            }
        }
    }
}
.edit-label.banner-upload {
    padding: 3px 6px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.branding-lobby-content .MuiSvgIcon-root {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.timeslot-resources-container .link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    a {
        color: $secondary;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                font-weight: bold;
                border-bottom: 1px solid $secondary;
            }
        }
    }
}
.auditorium-text-switch {
    display: inline-block;
    padding: 6px 6px 0 6px;
    border-radius: 6px;
    margin-bottom: 8px;
    .MuiSwitch-root {
        margin-bottom: 4px;
    }
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.switch-section {
    display: flex;
    flex: 0 0 520px;
    align-items: center;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            & > div {
                border-radius: 6px;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
.booth-switch-wrapper {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.participants-dashboard .expand-container .MuiSvgIcon-root {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.participants-dashboard .filters-wrapper .MuiFormControlLabel-root,
.switch-wrapper .MuiFormControlLabel-root {
    padding-right: 12px;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-left: 0;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.statistics-wcag-li {
    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            background-color: $secondary-light;
        }
    }
}
.info-timeslot {
    svg {
        position: relative;
        margin-left: 10px;
        top: 3px;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    .MuiSvgIcon-root {
        border-radius: 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0 0 10px $secondary;
            }
        }
    }
}
#download-info svg {
    width: 30px;
    height: 20px;
    padding: 3px;
    border-radius: 50%;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 6px 8px $secondary;
        }
    }
}
.editor-wrapper .rdw-editor-main {
    padding: 0 14px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
    }
}
.single-filter-container.wcag-emails,
.single-option-container.wcag-emails {
    margin-top: 8px;
    display: inline-block;
    padding-right: 12px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.addEditAudiences-backBtn {
    padding: 12px 12px 6px 12px;
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
input[type='number'] {
    -moz-appearance: textfield; /*For FireFox*/
    &::-webkit-inner-spin-button {
        /*For Webkits like Chrome and Safari*/
        -webkit-appearance: none;
        margin: 0;
    }
}
.image-explain {
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark;
    margin-bottom: 10px !important;
}
.access-wcag-focus:last-child {
    margin-bottom: 12px;
}
.access-wcag-focus {
    border-radius: 6px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0 0 10px $secondary;
        }
    }
}
.container-22 {
    width: 22px;
    height: 22px;
}
.filters-wrapper {
    .MuiFormControlLabel-root {
        svg {
            display: none;
        }
        & > span {
            &:first-of-type {
                margin-inline-end: 12px;
                &:before {
                    content: '';
                    width: 22px;
                    height: 22px;
                    background: $white;
                    border: 2px solid $secondary;
                    border-radius: 50%;
                    position: absolute;
                }
                &.Mui-checked {
                    &:before {
                        content: '';
                        background-color: $primary;
                        border: 2px solid $secondary;
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                    }
                    &:after {
                        width: 12px;
                        height: 12px;
                        background-color: $secondary;
                        content: '';
                        border-radius: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
}

.matching-setup-dashboard {
    .spinner {
        margin-top: 150px !important;
    }
    .single-option-container {
        position: relative;
        .char-counter {
            font-size: 12px;
            &.title-counter {
                position: absolute;
                right: 4px;
                top: 40px;
            }
            &.description-counter {
                position: absolute;
                right: 4px;
                top: 98px;
            }
            &.matching-counter-safari-chrome-fix {
                top: 97px;
            }
            &.error {
                color: $error;
                border: none !important;
            }
        }
    }
    .advanced-options-container {
        margin-bottom: 0;
    }
    .options-container {
        margin-bottom: 30px !important;
    }
    .action-container {
        margin: 30px 0 0 0 !important;
    }
    .matching-actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .create-question {
            &:active,
            &:active:focus {
                background: $secondary !important;
                box-shadow: none !important;
                .MuiButton-label {
                    color: $white !important;
                }
            }
            @media (hover: hover) {
                &:hover {
                    background: $secondary-light !important;
                    box-shadow: none !important;
                    .MuiButton-label {
                        color: $secondary;
                    }
                }
                &:focus {
                    background: $white;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
        .switch-container {
            cursor: default;
        }
        .MuiSvgIcon-root {
            margin-right: 10px;
            border-radius: 6px;
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    box-shadow: 0 0 10px $secondary;
                }
            }
        }
        .MuiButtonBase-root.MuiButton-text.Mui-disabled {
            padding: 0;
        }
    }
    .questions-container {
        max-width: 1112px;
        .spinner {
            position: relative;
            margin-top: 100px !important;
            background-color: transparent;
        }
    }
    .bottom-actions {
        width: 100%;
        max-width: 1112px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 50px;
        .create-question {
            &:active,
            &:active:focus {
                background: $secondary !important;
                box-shadow: none !important;
                .MuiButton-label {
                    color: $white !important;
                }
            }
            @media (hover: hover) {
                &:hover {
                    background: $secondary-light !important;
                    box-shadow: none !important;
                    .MuiButton-label {
                        color: $secondary;
                    }
                }
                &:focus {
                    background: $white;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
    }
    .matching-form-title {
        margin: 0 0 30px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $dark;
    }
    .description-input {
        padding: 18.5px 3px 18.5px 14px;
    }
    .timeslot-title-details {
        @media screen and (max-width: 1280px) {
            display: block;
            .matching-actions-wrapper {
                position: relative;
                left: -4px;
                margin-top: 30px;
                justify-content: flex-start;
                .switch-container.isExpanded:first-child {
                    margin-left: 0 !important;
                    padding-right: 16px;
                }
            }
        }
        .matching-actions-left {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;
            svg {
                margin-right: 20px;
            }
            .matching-question {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: $dark;
                text-transform: uppercase;
            }
            [data-rbd-drag-handle-context-id] {
                margin-bottom: 0;
            }
        }
        .matching-actions-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media screen and (max-width: 1152px) {
                flex-wrap: wrap;
            }
            .switch-container {
                margin: 0 16px;
                &:nth-child(2) {
                    margin-right: 0;
                }
            }
            .switch-container.isExpanded:first-child {
                margin-left: 16px;
                padding-right: 16px;
                margin-right: 0;
            }
            .advanced-options-closed {
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.04em;
                color: $grey-dark;
            }
        }
    }

    .MuiPaper-root {
        border-radius: 6px !important;
        .MuiAccordionSummary-root {
            cursor: default !important;
            padding: 7px 16px;
        }
    }
    .MuiAccordion-root.Mui-expanded {
        margin: 0 0 40px 0;
    }
    .MuiAccordion-root::before {
        background-color: transparent;
    }

    .question-row {
        width: 100%;
        margin-bottom: 18px;
        .question-upper {
            width: 100%;
            display: flex;
            flex-direction: row;
            .question-left {
                width: 50.6%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .MuiFormControl-root {
                    margin-right: 25px;
                }
            }
            .question-right {
                width: 50.6%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .icon-closed-select {
                    color: $grey-dark;
                }
                .icon-open-select {
                    color: $primary;
                }
                .MuiFormControl-root:first-child {
                    margin-right: 25px;
                }
                .MuiFormControl-root {
                    width: 50%;
                }
                .Mui-focused {
                    border-radius: 0 !important;
                    border-top-right-radius: 4px !important;
                    border-top-left-radius: 4px !important;
                }
                #demo-simple-select-outlined,
                .tooltip-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: $fontFamily, sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $dark;
                    overflow: hidden;
                    &.question-type-select {
                        padding: 17px 14px;
                    }
                    svg {
                        height: 18px;
                        margin-right: 8px;
                    }
                    .type-info-icon {
                        height: 22px;
                    }
                    .arrow-wrapper {
                        &-blue {
                            height: 18px;
                            svg {
                                width: 18px;
                                border-radius: 50%;
                                background-color: #45b8fe;
                                transform: rotate(90deg);
                            }
                        }
                        &-yellow {
                            height: 18px;
                            svg {
                                width: 18px;
                                border-radius: 50%;
                                background-color: #ffc107;
                            }
                        }
                        &-red {
                            height: 18px;
                            svg {
                                width: 18px;
                                border-radius: 50%;
                                background-color: #ff601c;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }
        .single-answer {
            width: 100%;
            margin-bottom: 17px;
            .answer-wrapper {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                .answer-input {
                    width: 50.6%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    svg {
                        margin-right: 12px;
                    }
                    .MuiButton-root {
                        position: relative;
                        right: -13px;
                        min-width: 56px;
                        min-height: 56px;
                        border-radius: 4px;
                        border-right: 1px solid $grey;
                        svg {
                            margin: 0;
                        }
                        &:focus {
                            background-color: $disabled-text;
                        }
                    }
                    .clear-not-advanced {
                        border: none;
                    }
                    .advanced-remove {
                        border: none;
                        position: relative;
                        right: -13px;
                        min-width: 56px;
                        min-height: 56px;
                        background-color: $white-variant;
                        &:hover,
                        &:focus {
                            background-color: $disabled-text;
                        }
                    }
                    .MuiFormControl-root {
                        margin-right: 25px;
                        &.has-dash::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: -25px;
                            width: 25px;
                            height: 1px;
                            background-color: $grey;
                        }
                    }
                    [data-rbd-drag-handle-context-id] {
                        margin-bottom: 0;
                    }
                }
                .answer-actions {
                    counter-reset: chips-counter;
                    width: 50.6%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    // Matching with endAdorement width fix - commented out for now
                    // .MuiInputBase-input {
                    //     min-width: 97%;
                    // }
                    // count individual chips for each select input
                    .MuiChip-root {
                        counter-increment: chips-counter;
                        span {
                            font-family: $fontFamily, sans-serif;
                            max-height: 16px;
                        }
                    }
                    // display total number of selected chips at the end of the input
                    .icon-closed-select {
                        z-index: 11;
                    }
                    &.advanced-actions.min-one-label:after {
                        z-index: 11;
                        content: counter(chips-counter);
                        position: absolute;
                        right: 50px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 95.9%;
                        color: $primary;
                    }
                    .icon-closed-select {
                        color: $grey-dark;
                    }
                    .icon-open-select {
                        color: $primary;
                    }
                    .Mui-focused {
                        border-radius: 0 !important;
                        border-top-right-radius: 4px !important;
                        border-top-left-radius: 4px !important;
                    }
                    #demo-simple-select-outlined,
                    #select-matching-with,
                    .tooltip-wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: $fontFamily, sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $dark;
                        svg {
                            height: 18px;
                            margin-right: 8px;
                        }
                        .arrow-wrapper {
                            &-blue {
                                height: 18px;
                                svg {
                                    width: 18px;
                                    border-radius: 50%;
                                    background-color: #45b8fe;
                                    transform: rotate(90deg);
                                }
                            }
                            &-yellow {
                                height: 18px;
                                svg {
                                    width: 18px;
                                    border-radius: 50%;
                                    background-color: #ffc107;
                                }
                            }
                            &-red {
                                height: 18px;
                                svg {
                                    width: 18px;
                                    border-radius: 50%;
                                    background-color: #ff601c;
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                        &:focus {
                            .chips-wrapper-foreground {
                                background: linear-gradient(to right, rgba(255, 255, 255, 0) 10%, hsl(0, 0%, 94%) 30%);
                            }
                        }
                        .chips-wrapper-foreground {
                            z-index: 10;
                            position: absolute;
                            right: 2px;
                            width: 100%;
                            max-width: 118px;
                            min-height: 31.5px;
                            background: linear-gradient(to right, rgba(255, 255, 255, 0) 10%, white 30%);
                        }
                        .chips-wrapper {
                            width: 100%;
                            max-width: 458px;
                            overflow: hidden;
                            white-space: nowrap;
                            font-size: 30px;
                            color: $dark;
                        }
                    }
                    .changed-padding {
                        padding: 13px 14px 11px 14px;
                    }
                    .remove-answer {
                        padding: 4px 0;
                        span {
                            padding: 0;
                        }
                    }
                }
            }
        }
        .addOption {
            width: 50.6%;
            display: flex;
            align-items: center;
            .add-button-container {
                padding: 10px;
            }
            .addOptionLine {
                flex: 1 1 auto;
                border-top: 1px solid $grey;
                margin-right: 30px;
            }
        }
    }
    // question card save button states
    .action-container .restyled-save-button {
        &:active,
        &:active:focus {
            background: $secondary;
            box-shadow: none !important;
            span {
                color: $white !important;
                svg path {
                    fill: $white !important;
                }
            }
        }
        @media (hover: hover) {
            &:hover {
                border: 1px solid $secondary !important;
                box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25) !important;
                span {
                    color: $secondary;
                    svg path {
                        fill: $secondary;
                    }
                }
            }
            &:focus {
                border: 1px solid $grey;
                box-shadow: 0px 4px 10px $secondary;
            }
        }
    }
    .MuiAccordionDetails-root {
        flex-direction: column;
    }
}
.uploadfile-prop {
    margin-left: 10px;
}
.removefile-prop {
    margin-left: 10px !important;
}
.MuiButton-label.dialog-btn-uppercase.removefile-button {
    padding: 6px 8px !important;
    div {
        margin-right: 8px !important;
    }
}
.scroll-helper-gdpr {
    position: relative !important;
    svg {
        position: absolute !important;
        top: 20px !important;
        right: 20px !important;
    }
    textarea {
        padding-right: 50px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        padding-right: 0 !important;
    }
}
@media screen and (max-width: 1320px) {
    .organizer-dashboard-page .auditorium-page.dropdown-component,
    .organizer-dashboard-page .contacts-page.dropdown-component,
    .organizer-dashboard-page .chat-page.dropdown-component {
        top: 139px;
        position: fixed;
        height: calc(100vh - 139px);
    }
}

@media screen and (max-width: 1307px) {
    .organizer-dashboard-page {
        padding-top: 139px !important;
    }
}
@media screen and (min-width: 1308px) {
    .organizer-dashboard-page {
        padding-top: 121px !important;
    }
}

.advanced-options-title > .add-floor {
    display: flex;

    align-items: center;

    width: 152px;
    padding: 8px 12px;

    cursor: pointer;

    & > span {
        margin-left: 8px;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }
}