@import '../../../CSS/variables.module';

.side-buttons-outer {
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;

    .side-buttons-middle {
        position: relative;
        .side-buttons-inner {
            transform: rotate(-90deg);
            transform-origin: 100% 100%;
            display: flex;
            white-space: nowrap;
            flex-flow: row-reverse;
            position: absolute;
            top: -43px;
            right: 100%;

            @media screen and (max-height: 690px) {
                transform: scale(0.8) rotate(-90deg);
            }

            .side-button-container {
                position: relative;
                top: 0.5px;
                right: -1px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: auto;
                height: auto;
                background: #FEF6CD;
                color: $white;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                border-radius: 8px 8px 0 0;
                padding-top: 9.5px;
                padding-bottom: 9.5px;
                padding-inline-start: 8px;
                padding-inline-end: 8px;
                margin-inline-start: 4px;
                text-transform: uppercase;
                border-top: 1px solid $disabled-text;
                border-left: 1px solid $disabled-text;
                border-right: 1px solid $disabled-text;
                transform-origin: top right;
                cursor: pointer;
                transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                svg {
                    fill: black;
                }

                span {
                    padding-inline-end: 8px;
                    color: black;
                    width: auto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &.animate-in {
                    position: relative;
                    top: 0.5px;
                    transform: translate(0, 0);
                }

                &.animate-out {
                    opacity: 0;
                    position: absolute;
                    transform: translate(0, 50px);
                }

                &.active {
                    color: $black;
                    background: $primary;
                    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                    svg {
                        fill: $black;
                    }
                }
            }
        }
    }
}
