@import '../../../CSS/variables.module';
.invite-to-stage {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0 0;
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: $white;
        border: none;
        color: $secondary;
        padding: 0;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        font-family: $fontFamily, sans-serif;
        svg {
            margin-inline-end: 9px;
        }
        &.cancel-invitation-button{
            color: $grey-dark;
        }
        &.accept-request-button {
            color: $primary;
            margin-inline-end: 20px;
        }
        &:disabled{
            color: $grey-dark;
            cursor: initial;
            svg path{
                fill: $grey-dark;
            }
        }
    }
    .invitation-status {
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2;
        text-transform: capitalize;
        color: $secondary;
        padding-inline-end: 20px;
    }
}