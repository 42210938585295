@import 'variables.module';

#rooms-dropdown {
    top: initial;
    width: 100%;
    bottom: 56px;
    background-color: $white;
}
#rooms-dropdown .page-title {
    height: 48px;
    line-height: 48px;
    padding-inline-start: 16px;
    text-align: left;
    background-color: $primary;
    color: $black;
}
#rooms-dropdown .page-title h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
    text-align: start;
}
.rooms-container {
    padding: 0 25px;
    // overflow bug -- dubious code
    // https://trello.com/c/nA4FeuXs/1004-bug-mobile-rooms-menu-scroll-bar
    // max-height: 220px;
}
.rooms-container > div {
    display: flex;
    padding: 12px 0;
}
.rooms-container .active:not(.archive-link) svg path {
    fill: $primary;
}
.rooms-container p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: $text-grey;
    text-transform: uppercase;
    margin-inline-start: 17px;
}
.rooms-container .active p {
    color: $primary;
}
.rooms-container a {
    display: flex;
    color: $text-grey;
    align-items: center;
}
.rooms-dropdown.is-ios {
    bottom: 100px !important;
}
.submenu {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 56px;
    overflow: auto;
    z-index: 5;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}
.submenu-container {
    background-color: $white;
    &-title {
        height: 48px;
        line-height: 48px;
        padding-inline-start: 16px;
        text-align: left;
        background-color: $primary;
        color: $white;
        h3 {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            text-transform: uppercase;
            text-align: start;
        }
    }
    &-content {
        padding: 0 28px;
        .submenu-container-links {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: flex;
                align-items: center;
                padding: 12px 0;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: $text-grey;
                p {
                    padding-inline-start: 16px;
                    margin: 0;
                }
                &.active {
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                    color: $primary;
                }
            }
        }
    }
}
