.lobby-video-container {
    display: flex;
    flex-direction: column;
    height: 46vh;
    position: relative;
}

.lobby-video-player {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: default;
    border: 1px solid #898989;
    border-radius: 2px 2px 0 0;
}

.video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 800ms ease;
}

.video-thumbnail.show {
    opacity: 1;
}

.lobby-video-container .lobby-video-actions-container {
    display: flex;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: white;
    cursor: default;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.lobby-video-container:hover .lobby-video-actions-container {
    opacity: 1;
    visibility: visible;
}

.fullscreen-button {
    font-size: 30px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    padding: 0;
}

.no-video {
    cursor: initial !important;
}
