@import 'variables.module';

.wall-container {
    background-color: #f5f5f5;
    flex: 1 1;
    &.loading-on-demand {
        position: relative;
    }
    &.on-demand {
        background-color: white;
        border-left: 1px solid #dbdada;
        .on-demand-videos-container {
            height: calc(100vh - 158px);
            .on-demand-videos-list {
                height: 100%;
                padding: 20px 0;
            }
        }
    }
}
.wall-container .sort-container {
    display: flex;
    font-size: 13px;
}
.resources-container .links-container {
    padding: 0;
}
.wall-container .resources-container {
    text-align: left;
    margin: 0 -10px;
}
.wall-container .resources-container .title {
    color: $primary;
    font-weight: 600;
    text-transform: uppercase;
    padding: 24px 16px 8px 16px;
    font-size: 16px;
    margin: 0;
}
.wall-container .resources-container .description-text-wall {
    margin: 0;
    color: $dark;
    font-size: 16px;
    line-height: 20px;
    word-wrap: break-word;
    text-align: start;
    padding: 0 32px 10px 16px;

    ul,
    ol {
        margin: 0;
        padding: 0 0 0 14px;
    }

    p {
        margin: 0;
    }
}
.wall-container .resources-container ul {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
}
.wall-container .resources-container ul li {
    display: list-item;
    list-style: disc;
    padding: 0;
}
.wall-container .resources-container ul li a:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid $secondary;
}
.wall-container .resources-container ul li a {
    color: $secondary;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 16px;
    border-bottom: 1px solid $disabled-text;
}
.wall-container .resources-container ul li a span {
    color: $dark;
    padding-right: 16px;
}
.wall-container .resources-container ul li a:visited span {
    color: $dark;
}
.wall-container .resources-container ul li a:hover span {
    color: $secondary;
}
.wall-container .resources-container ul li a:active {
    color: #fff;
    background-color: $secondary;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.2);
}
.wall-container .resources-container ul li a:active span {
    color: #fff;
}
.wall-container .sort-container {
    flex-wrap: wrap;
    padding: 0 10px;
    min-height: 44px;
    align-items: center;
    span {
        padding-inline-end: 10px;
        color: $grey-dark;
        font-size: 13px;
        line-height: 13px;
    }
    .button {
        cursor: pointer;
        text-transform: uppercase;
        color: #000;
        &:last-of-type {
            padding-inline-end: 0;
        }
        &.active {
            color: $primary;
        }
    }
    .sorting-buttons {
        span {
            font-size: 16px !important;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        .button {
            margin-right: 4px;
            padding: 4px 8px 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 27px;
            &.active {
                background: #000000;
                border-radius: 26px;
                color: #fff;
            }

            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    font-weight: bold;
                    color: $secondary;
                }
            }
        }
    }

    #download-info {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        padding: 0;

        svg {
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }

        .button {
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }

        svg path {
            @media (hover: hover) {
                &:focus {
                    outline: none;
                }
                &.focus-visible {
                    outline: none;
                    box-shadow: 0px 4px 10px $secondary;
                }
            }
        }
    }
}

.add-new-post-container {
    z-index: 1;
    min-height: 43px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    position: relative;
    &.input-error {
        padding-bottom: 20px;
    }
    .error-text {
        position: absolute;
        bottom: -2px;
        left: 0;
        margin: 0 !important;
        padding: 5px 10px;
        text-align: start;
        font-size: 10px !important;
    }
    .edit-mode-container-mobile {
        display: none;
    }
}
.add-new-post-container > div:last-of-type {
    flex: 1;
    margin-inline-end: 25px;
    &.empty {
        margin-inline-end: 0;
        #newPost {
            min-height: 19px;
            height: initial !important;
        }
        #newPost::placeholder {
            line-height: 19px;
            @media screen and (max-width: 370px) {
                font-size: 14px;
            }
            @media screen and (max-width: 335px) {
                font-size: 12px;
            }
        }
    }
}
.edit-mode-wrapper .edit-mode-container {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    -webkit-filter: drop-shadow(0px 7px 32px rgba(32, 15, 54, 0.25));
    filter: drop-shadow(0px 7px 32px rgba(32, 15, 54, 0.25));
    .edit-mode-text {
        color: $dark;
        font-size: 12px;
        margin: 2px 0 2px 44px;
        .edit-mode-button {
            cursor: pointer;
            text-transform: lowercase;
            color: $secondary;
        }
    }
}
.edit-mode-container-comment {
    position: relative;
    left: -12px;
    width: calc(100% + 24px);
    background: $light-grey;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .edit-mode-text-comment {
        width: 100%;
        color: $dark;
        font-size: 12px;
        margin: 2px 0 2px 9px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .edit-close-comment {
            cursor: pointer;
            width: 30px;
            height: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 7px;
        }
    }
}
#newPost::-webkit-scrollbar,
.newComment textarea::-webkit-scrollbar,
.single-chat-container .box > div ul::-webkit-scrollbar,
.exhibitors-overlay .exhibitors-container .exhibitors-list::-webkit-scrollbar,
#newMessage::-webkit-scrollbar {
    width: 4px;
}

/* Track */
#newPost::-webkit-scrollbar-track,
.newComment textarea::-webkit-scrollbar-track,
.single-chat-container .box > div ul::-webkit-scrollbar-track,
.exhibitors-overlay .exhibitors-container .exhibitors-list::-webkit-scrollbar-track,
#newMessage::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
#newPost::-webkit-scrollbar-thumb,
.newComment textarea::-webkit-scrollbar-thumb,
.single-chat-container .box > div ul::-webkit-scrollbar-thumb,
.exhibitors-overlay .exhibitors-container .exhibitors-list::-webkit-scrollbar-thumb,
#newMessage::-webkit-scrollbar-thumb {
    background: #777081;
    border: 1px solid #ffffff;
    border-radius: 8px;
}

/* Handle on hover */
#newPost::-webkit-scrollbar-thumb:hover,
.newComment textarea::-webkit-scrollbar-thumb:hover,
.single-chat-container .box > div ul::-webkit-scrollbar-thumb:hover,
.exhibitors-overlay .exhibitors-container .exhibitors-list::-webkit-scrollbar-thumb:hover,
#newMessage::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#standard-search {
    padding-right: 0 !important;
}
#newPost,
.newComment textarea,
#standard-search,
#newMessage {
    flex: 1;
    border: 0;
    padding: 0 10px;
    outline: 0;
    font-family: $fontFamily, sans-serif;
    color: $black;
    font-size: 16px;
    line-height: 18px;
    height: 17px;
    -webkit-appearance: none;
}
#newPost::placeholder,
.newComment textarea::placeholder,
#standard-search::placeholder,
#newMessage::placeholder {
    transform: translate3d(0, 0, 0);
    font-size: 16px;
    line-height: 18px;
    color: $black;
    opacity: 1;
    font-family: $fontFamily, sans-serif;
}
#newMessage,
#newMessage::placeholder {
    font-size: 12px;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
.user-avatar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    background-size: cover;
    display: inline-block;
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: center;
}
.add-new-post {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    padding: 0;
}
.add-new-post svg path {
    fill: #fff;
}
.add-new-post:disabled {
    cursor: initial;
    opacity: 0.5;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
}
.add-new-post:disabled svg path {
    fill: $primary;
}
.custom-scroll-container {
    height: calc(100vh - 170px);
}
.custom-scroll-container > div {
    height: 100%;
}
.single-post-container {
    padding: 10px 10px 0 10px;
    background-color: #fff;
    margin: 10px 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: relative;
    text-align: left;
    border: 2px solid #ffffff;
    &.is-pinned {
        border: 2px solid $primary;
    }
}
.report-post-container {
    display: flex;
    align-items: center;
    .report-post-button {
        background: none;
        border: none;
        cursor: pointer;
    }
}
.single-post-container.highlight {
    box-shadow: 0 0 5px 3px $secondary-hover;
}
.single-post-container .post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.user-name {
    margin-inline-start: 8px;
    flex: 1;
}
.user-name p {
    margin: 0;
}
.user-name p:first-child {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 19px;
    font-family: $fontFamily, sans-serif;
    overflow-wrap: break-word;
    text-align: start;
}
.single-post-container .user-name p:first-child {
    max-width: calc(100% - 125px);
}
.user-name p:last-child {
    font-family: $fontFamily, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $grey-dark;
    text-align: start;
}
.single-post-container .postActions {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;

    .menu-actions {
        vertical-align: top;
        display: inline-block;
        cursor: pointer;
        position: relative;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
        }
        button {
            text-align: center;
        }
        svg {
            fill: $grey;
            &.active {
                path {
                    fill: $primary;
                }
            }
        }
        .actions-list {
            -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
            position: absolute;
            top: 0;
            right: -5px;
            z-index: 2;
            border-radius: 6px;
            &.prevent-direction {
                right: -100px;
            }
            ul {
                border: 1px solid $primary;
                background: $white;
                border-radius: 6px;
                padding: 0;
                margin: -2px;
                overflow: hidden;
                cursor: auto;
                li {
                    display: flex;
                    align-items: center;
                    padding: 11.5px 13.5px;
                    margin-bottom: 0 !important;
                    cursor: pointer;

                    @media (hover: hover) {
                        &:focus {
                            outline: none;
                        }
                        &.focus-visible {
                            outline: none;
                            background-color: $secondary-light;
                        }
                    }

                    &:hover {
                        background-color: $secondary-light;
                    }
                    &:active {
                        background-color: $secondary;
                        svg {
                            fill: $white;
                            path {
                                fill: $white;
                            }
                        }
                        span {
                            color: $white;
                        }
                    }
                    span {
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: uppercase;
                        margin-inline-start: 12px;
                    }
                    svg {
                        flex-basis: 22px;
                        path {
                            fill: $dark;
                        }
                        &.un-pin {
                            path {
                                fill: $error;
                            }
                            & ~ span {
                                color: $error;
                            }
                        }
                    }
                }
                .edit-disabled {
                    pointer-events: none;
                    svg {
                        fill: $muiDisabled !important;
                        path {
                            fill: $muiDisabled !important;
                        }
                    }
                    span {
                        color: $muiDisabled !important;
                    }
                    &:hover {
                        cursor: auto !important;
                    }
                }
            }
        }
    }
}
.post-container {
    padding-top: 7px;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding-bottom: 10px;
    text-align: start;
}
.post-container p {
    word-break: break-word;
    word-wrap: break-word;
    margin: 0;
    overflow: hidden;
    padding: 1.5px 0;
}
.comments-button,
.like-button,
.delete-button,
.more-button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: relative;
    padding-right: 10px;
    border-radius: 6px;
    padding: 0;
    margin: 0 8px;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
    }
}
.delete-button {
    &.single-button {
        padding-inline-end: 0;
    }
    svg {
        fill: $grey;
    }
}
.comments-button > div,
.like-button > div {
    width: 16px;
    height: 16px;
    position: absolute;
    border: 1px solid $secondary;
    color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -5px;
    left: 14px;
    background-color: $secondary;
}
.comments-button .comments-img {
    position: relative;
    top: 3px;
}
.comments-button > div span,
.like-button > div span {
    font-weight: 600;
    font-family: $fontFamily, sans-serif;
    max-height: 12px;
}
.like-button {
    &.restricted {
        cursor: initial;
    }
    svg {
        margin-bottom: 2px;
    }
}
.like-button:disabled {
    cursor: initial;
}
.post-comments,
.user-wrapper-separator {
    color: $dark;
}
.post-comments:before,
.comments-list-container:after,
.user-wrapper-separator:after {
    position: absolute;
    left: 0;
    content: ' ';
    display: block;
    border-bottom: 1px solid rgba(137, 137, 137, 0.5);
    width: 100%;
}
.post-comments .comments-list-container {
    padding-top: 13px;
    padding-inline-end: 0;
    padding-inline-start: 0;
}
.post-comments .comments-list-container:empty:after {
    border-bottom: 1px solid transparent;
}
.post-comments .comments-list-container .delete-button {
    padding-inline-end: 0;
}
.new-comment-container {
    height: auto;
    min-height: 43px;
    display: flex;
    align-items: center;
    position: relative;
    &.input-error {
        padding-bottom: 20px;
    }
    .error-text {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 !important;
        padding: 5px 0px;
        text-align: start;
        font-size: 10px !important;
    }
}
.new-comment-container .user-avatar {
    margin-inline-end: 10px;
}
.new-comment-container .add-new-post {
    height: 26px;
    padding: 0;
    background-color: $primary;
}
.new-comment-container .add-new-post:disabled {
    background-color: transparent;
}
.new-comment-container .newComment {
    margin-inline-end: 25px;
}
.new-comment-container .newComment textarea {
    padding-inline-start: 0;
}
.comment-header p {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.comments-list-container .comment-container {
    display: flex;
    flex-direction: column;

    //&.container-moderation {
    //    position: relative;
    //    .delete-button {
    //        position: absolute;
    //        right: 0;
    //        top: 0;
    //    }
    //}
    &.not-approved {
        position: relative;
        margin-bottom: 14px;
        .delete-button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.comments-list-container .comment-container .menu-actions {
    cursor: pointer;
    border-radius: 6px;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 4px 10px -4px $secondary;
        }
    }

    svg {
        fill: $grey;
        &.active {
            path {
                fill: $primary;
            }
        }
    }

    .actions-list {
        -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
        position: absolute;
        top: 0;
        right: -5px;
        background: $white;
        z-index: 1;
        border-radius: 6px;
        &.prevent-direction {
            right: -100px;
        }
        ul {
            border: 1px solid $primary;
            border-radius: 6px;
            padding: 0;
            margin: 0;
            cursor: auto;
            overflow: hidden;
            li {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 11.5px 13.5px;
                margin-bottom: 0;

                @media (hover: hover) {
                    &:focus {
                        outline: none;
                    }
                    &.focus-visible {
                        outline: none;
                        background-color: $secondary-light;
                    }
                }

                &:hover {
                    background-color: $secondary-light;
                }
                &:active {
                    background-color: $secondary;
                    svg {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                    span {
                        color: $white;
                    }
                }
                span {
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-inline-start: 12px;
                }
                svg {
                    flex-basis: 22px;
                    path {
                        fill: $dark;
                    }
                    &.un-pin {
                        path {
                            fill: $error;
                        }
                        & ~ span {
                            color: $error;
                        }
                    }
                }
            }
        }
        .edit-disabled {
            pointer-events: none;
            svg {
                fill: $muiDisabled !important;
                path {
                    fill: $muiDisabled !important;
                }
            }
            span {
                color: $muiDisabled !important;
            }
            &:hover {
                cursor: auto !important;
            }
        }
    }
}
.comments-list-container .comment-container p {
    text-align: start;
    overflow: hidden;
}
.comments-list-container .comment-container .user-full-name {
    display: block;
    padding-bottom: 5px;
    text-transform: capitalize;
}
.comment-header p.time-ago {
    color: $text-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
    padding-bottom: 2px;
}
.comment-header p span {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: normal;
}
.single-comment-container .comment-header {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.single-comment-container .user-avatar {
    margin-inline-end: 9px;
}
.single-comment-container .comment-container {
    flex: 1;
}
.comment-container p:not(.time-ago) {
    word-break: break-word;
    word-wrap: break-word;
    max-width: 200px;
    margin-inline-end: 20px;
    padding-bottom: 2px;
    letter-spacing: 0.5px;
}
.newComment {
    flex: 1;
    border: 0;
    outline: 0;
    padding: 0;
}
.wall[data-empty='true'] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.wall[data-empty='true'] .empty-wall {
    text-align: center;
    margin-top: -46px;
    padding: 10px;
}
.wall[data-empty='true'] .empty-wall p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark;
    margin-bottom: 0;
    text-align: center;
    white-space: pre-line;
}
.banner-moderation {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: $grey-dark;
    padding: 8px 12px;
    margin: 7px 8px 0;
    svg {
        fill: $white;
        margin-inline-end: 10px;
    }
    p {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        color: $white;
        text-align: left;
        line-height: 16px;
    }
}
.pending-approval {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 14px;
    span {
        color: $secondary;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-inline-end: 10px;
    }
}
.approve-button {
    margin: 6px auto 14px;
    display: flex;
}
@media screen and (min-width: 1025px) and (max-width: 1299px) and (max-height: 799px) {
    .add-new-post-container {
        &.input-error {
            padding-bottom: 30px;
        }
    }
    .new-comment-container {
        &.input-error {
            padding-bottom: 30px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .wall {
        padding-top: 0 !important;
    }
    .wall[data-empty='true'] .empty-wall {
        margin-top: -42px;
    }
    .wall-container .sort-container span {
        font-weight: 500;
        font-size: 16px;
    }
    .wall-container .sort-container span.button {
        font-weight: bold;
    }
    /* Safari browser */

    .add-new-post-container {
        position: fixed;
        width: calc(100% - 10px);
        bottom: 10px;
        min-height: initial;
        padding-top: 11px;
        padding-bottom: 11px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        border: 1px solid $disabled-text;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.08);
        .edit-mode-container-mobile {
            position: fixed;
            top: -35px;
            width: 100.5%;
            left: 50%;
            transform: translateX(-50%);
            background: $light-grey;
            border: 1px solid $disabled-text;
            border-radius: 4px 4px 0 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .edit-mode-text {
                color: $dark;
                font-size: 14px;
                margin: 2px 0 2px 7px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .edit-close-mobile {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-right: 7px;
                }
            }
        }
    }

    @media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse),
        (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: fine) {
        .add-new-post-container.ios-support:not(:focus-within) {
            bottom: 55px !important;
            position: absolute !important;
        }
    }
    .add-new-post-container .MuiInputBase-multiline {
        padding: 0;
    }
    .edit-mode-wrapper {
        display: none;
    }
    .single-post-container:last-of-type {
        margin-bottom: 76px;
    }
    .single-post-container .user-avatar {
        width: 40px;
        height: 40px;
    }
    .user-name p:first-child {
        font-size: 16px;
    }
    .post-container {
        font-size: 16px;
        font-weight: 500;
        p {
            line-height: 18px;
        }
    }
    .single-post-container .postActions img {
        width: 18px;
        height: 18px;
    }
    .postActions .delete-button img,
    .post-comments .comments-list-container .delete-button img {
        width: 20px;
        height: 20px;
    }
    .comments-button > div,
    .like-button > div {
        bottom: -5px;
    }
    .comment-header p {
        font-size: 16px;
    }
    .comment-header p span {
        font-size: 16px;
    }
    .comment-header p.time-ago {
        font-size: 12px;
    }
    .new-comment-container .user-avatar {
        width: 24px;
        height: 24px;
    }
    .new-comment-container input {
        font-size: 16px;
    }
    .new-comment-container input::placeholder {
        color: $text-grey;
        font-size: 16px;
    }
    .post-container {
        padding-top: 12px;
    }
    .new-comment-container .add-new-comment {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
        border: 0;
        border-radius: 50%;
        outline: 0;
        padding: 0;
    }
    .new-comment-container .add-new-comment svg path {
        fill: #fff;
    }
    .new-comment-container .add-new-comment:disabled {
        cursor: initial;
        opacity: 0.5;
        background-color: #fff;
    }
    .new-comment-container .add-new-comment:disabled svg path {
        fill: $primary;
    }
    #newPost::placeholder,
    .newComment textarea::placeholder {
        color: $text-grey;
        font-size: 16px;
    }

    .custom-scroll-container.networking-wrapper {
        border: 0;
    }
    #newPost,
    .newComment textarea,
    #standard-search {
        font-size: 16px;
    }
    .comment-container p:not(.time-ago) {
        max-width: initial;
        margin-inline-end: 30px;
    }
}
@media screen and (min-width: 1025px) and (max-height: 850px) {
    .wall-container {
        .sort-container {
            span {
                font-size: 10px;
            }
        }
    }
}
@media screen and (max-width: 374px) {
    .wall-container .sort-container span {
        font-size: 15px;
    }
}
@media screen and (max-width: 355px) {
    .wall-container .sort-container span {
        font-size: 14px;
    }
}
@media screen and (max-width: 330px) {
    .wall-container .sort-container span {
        font-size: 13px;
    }
}
