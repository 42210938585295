@import 'variables.module';

#header-mobile {
    height: 56px;
}
#header-mobile .main-menu-container {
    padding-inline-start: 0;
}
#header-mobile .left-header-wrapper {
    flex: 1;
    margin: 0;
}
#header-mobile #logo-mobile {
    margin-inline-start: 0;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    border: 0;
    flex: 1;
    justify-content: space-between;
    display: flex;
    align-items: center;
    a:not(.powered-by) {
        padding: 0;
        display: inline-flex;
        svg {
            width: 98px;
            height: 30px;
        }
    }
    .powered-by {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            font-size: 9px;
            text-transform: uppercase;
            color: $black;
            padding-bottom: 2px;
        }
    }
}
#header-mobile .main-menu-container .right-header-wrapper {
    border: 0;
    & > div:last-of-type {
        margin-inline-end: 16px;
        padding: 0;
        background-color: $white;
        &.active {
            svg {
                fill: $primary;
            }
        }
    }
}
#menu-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1199;
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid $disabled-text;
    display: flex;
}
#menu-bottom .menu-bottom-wrapper {
    width: 100%;
}
#menu-bottom .main-menu-container {
    height: 100%;
}
#menu-bottom .main-menu-container .main-header-wrapper {
    & > div {
        padding: 0;
        display: flex;
        cursor: pointer;
        text-align: center;
        line-height: 1;
        flex-flow: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        width: calc(100vw / 5);
        svg {
            fill: $secondary;
        }
        &.active {
            background-color: $primary;
        }
    }
    #contacts {
        svg {
            stroke: $secondary;
        }
    }
    #chats {
        & > span {
            position: relative;
        }
        div {
            height: 18px;
            width: 18px;
            position: absolute;
            left: 12px;
            bottom: -5px;
            border-radius: 50%;
            color: $secondary;
            background-color: $primary;
            font-weight: 500;
        }
    }
}
#menu-bottom .main-menu-container .main-header-wrapper > div.active p {
    font-weight: bold;
}
#menu-bottom .menu-bottom-wrapper p {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: -0.4px;
    padding-top: 6px;
}
#menu-bottom .menu-bottom-wrapper > div > div {
    display: flex;
    align-items: center;
    height: 100%;
}
.menu-slow-index {
    .menu {
        .top-container {
            z-index: 9;
        }
    }
}
.menu-bottom.is-ios {
    height: 100px !important;
    padding: 20px;
}
@media screen and (max-width: 1024px) {
    .event-page.hide #menu-bottom,
    .event-page.hide-new-comment #menu-bottom,
    .event-page.hide-new-chat-message #menu-bottom {
        display: none;
    }
    .event-page.hide-new-comment .add-new-post-container {
        display: none;
    }
    /*.chat-opened #chat-messages-container {*/
    /*    position: fixed;*/
    /*    bottom: 66px;*/
    /*    z-index: 1;*/
    /*}*/
    /*.chat-opened .chat-header-container {*/
    /*    position: relative;*/
    /*    z-index: 2;*/
    /*}*/
    /*.chat-opened #header-mobile .main-menu-container {*/
    /*    z-index: 2;*/
    /*}*/
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    #menu-bottom .menu-bottom-wrapper > div > div {
        justify-content: center;
    }
    #menu-bottom .main-menu-container .main-header-wrapper > div {
        width: auto;
        padding: 0 14px;
    }
    .main-menu-container .main-header-wrapper #chats > div {
        right: 15px;
        left: initial;
        bottom: 18px;
    }
}
@media screen and (max-width: 766px) {
    .main-menu-container .main-header-wrapper #chats > div {
        bottom: 18px;
        left: 50%;
    }
}
@media screen and (max-width: 480px) {
    #menu-bottom .menu-bottom-wrapper > div > div {
        justify-content: space-around;
    }
    #menu-bottom .main-menu-container .main-header-wrapper > div {
        width: auto;
        padding: 0 5px;
    }
}
@media screen and (max-width: 400px) {
    #menu-bottom .menu-bottom-wrapper p {
        font-size: 11px;
    }
}
@media screen and (max-width: 330px) {
    #menu-bottom .menu-bottom-wrapper p {
        font-size: 10px;
    }
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse),
    (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (pointer: fine) {
    .add-new-post-container.ios-support:not(:focus-within) {
        bottom: 55px !important;
        position: absolute !important;
    }
}
