#form-dialog-title-externalID {
    padding-bottom: 0;
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: #2b2b2b;
        text-align: start;
    }
}
h3.sub-title-external-id-popup {
    font-weight: normal;
}
