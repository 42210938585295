html.lang-rtl {
    direction: rtl;
    .arabic-rotate {
        transform: rotate(180deg);
    }
    .arabic-row-revers {
        display: flex;
        flex-flow: row-reverse;
    }
    .arabic-justify-end {
        display: flex;
        justify-content: flex-end;
    }
    .login-page .field-container,
    .edit-user-information .field-container,
    .register-form-wrapper .field-container,
    .recover-password-dialog .field-container,
    .change-password-dialog .field-container,
    .create-meeting .single-option-container {
        img {
            left: 10px;
            right: auto;
        }
        label {
            transform: translate(-14px, 20px) scale(1);
            right: 0;
            left: initial;
        }
        legend {
            text-align: right;
        }
        .MuiSelect-iconOutlined {
            left: 7px;
            right: initial;
        }
    }
    .register-form-wrapper,
    .login-page {
        .field-container {
            label {
                transform: translate(-14px, 20px) scale(1);
                &[data-shrink="true"] {
                    transform: translate(-14px, 10px) scale(1);
                }
            }
        }
    }
    .create-meeting .single-option-container .MuiOutlinedInput-input {
        text-align: right;
        &::-webkit-calendar-picker-indicator {
            left: 0;
            position: absolute;
            margin: 0;
            margin-left: 8px;
        }
    }
    .social-btn img {
        left: initial;
        right: 16px;
    }
    .homepage .partners-section .top-slider-container,
    .auditorium-page.dropdown-component,
    .auditoriums-popup {
        .slick-slider div {
            transition: none;
        }
        .slick-track {
            direction: ltr;
        }
        .slick-list {
            direction: ltr;
            .slick-slide {
                float: left;
            }
        }
        .slider-container {
            &.has-slider {
                .slick-arrow {
                    &.slick-next {
                        left: calc(100% + 40px);
                    }
                }
            }
        }
    }
    .auditoriums-popup {
        right: 50%;
        left: auto;
        transform: translateX(50%);
    }
    .exhibitors-overlay {
        .close-button {
            right: initial;
            left: 0;
            &.with-collapse-button {
                left: 32px;
            }
        }
        .search-exhibitor-container {
            .MuiOutlinedInput-adornedEnd {
                padding-right: 14px;
                padding-left: 14px;
            }
            label {
                transform: translate(-14px, 20px) scale(1);
                left: initial;
            }
            legend {
                text-align: right;
            }
        }
        .exhibitors-container {
            .exhibitors-list {
                direction: ltr;

                &[data-empty='0'] {
                    direction: rtl;
                }

                .exhibitors-list-inner-container {
                    direction: rtl;
                }
                .single-exhibitor {
                    direction: rtl;
                }
            }
        }
    }
    .exhibitors-overlay-mobile {
        .search-exhibitor-container {
            &.mobile {
                svg {
                    right: initial;
                    left: 10px;
                }
            }
        }
    }
    .main-menu-container .main-header-wrapper #back img {
        transform: rotate(180deg);
    }
    .login-page .email-wrapper img,
    .login-page .password-wrapper img {
        left: 10px;
        right: auto;
    }
    #my-events #account-drop {
        right: 0;
    }
    .top-container .close-component {
        left: initial;
        right: -40px;
        border-radius: 0 8px 8px 0;
    }
    .my-account-component.dropdown-component,
    .notifications-page.dropdown-component,
    .is-offline .participant-avatar:after,
    .is-offline .avatar:after,
    .is-online .participant-avatar:after,
    .is-online .avatar:after {
        left: 0;
        right: initial;
    }
    .program-entry .program-container,
    .event-actions-container.swipeable-drawer .drawer-buttons-container {
        flex-flow: row-reverse;
    }
    .speakers-name {
        text-align: right;
    }
    .participant-button.open-chat,
    .user-details .accept-request {
        right: initial;
        left: 0;
    }
    .user-details .reject-request {
        right: initial;
        left: 70px;
    }
    #notifications .not-read-notifications {
        left: initial;
        right: 8px;
    }
    .expanded .message .avatar-wrapper {
        order: 3;
    }
    .expanded .message .time-wrapper {
        order: -1;
    }
    .expanded .message.me .avatar-wrapper {
        order: 1;
    }
    .expanded .message.me .time-wrapper {
        order: 3;
    }
    .expanded .message.me .avatar-wrapper .user-avatar {
        margin-inline-end: 10px;
        margin-inline-start: 0;
    }
    .expanded .message .messages-wrappper {
        text-align: left;
        margin-inline-start: 14px;
        margin-inline-end: 0;
        p:last-child {
            text-align: left;
        }
    }
    .expanded .message.me .messages-wrappper {
        text-align: right;
        margin-inline-start: 0;
        margin-inline-end: 14px;
        p:last-child {
            text-align: right;
        }
    }
    .expanded .message .user-avatar {
        margin-inline-end: 0;
        margin-inline-start: 10px;
    }
    .single-post-container .postActions {
        right: initial;
        left: 0;
    }
    .networking-container .participant-avatar {
        order: 1;
        margin-inline-end: 0;
        margin-inline-start: 16px;
    }
    .networking-container .user-container .user-details {
        flex-flow: row-reverse;
        .user-name p {
            text-align: right;
        }
    }
    .participant-button.request-sent,
    .participant-button.connect-button,
    .participant-button.add-button,
    .new-meeting-button,
    .chatsPage .chat-page.edit,
    .left-header-wrapper #my-events span svg {
        left: 0;
        right: initial;
    }
    .chatsPage,
    .contacts-page {
        direction: rtl;
    }
    .video-actions-container button {
        transform: rotate(180deg);
    }
    .manage-booth-style label:before {
        left: 10px;
        right: initial !important;
    }
    .manage-booth-style label[data-checked='true']:after {
        left: 5px;
        right: initial !important;
    }
    .single-booth-participant-view,
    .organizer-navigation-container,
    .organizer-dashboard-page,
    .exhibitor-booth-view-page .booth-container,
    .showfloor-page .booth,
    .auditoriums-popup {
        direction: ltr;
    }
    .exhibitor-booth-view-page .booth-visitors-container,
    .exhibitor-booth-view-page .booth-statistics-container,
    .exhibitors-overlay {
        direction: rtl;
    }
    .booth-title-container {
        max-width: 1146px;
        margin-left: 0;
        margin-right: auto;
    }
    .login-page .email-wrapper,
    .login-page .password-wrapper {
        label {
            right: 0;
            left: initial;
            transform: translate(-14px, 20px) scale(1);
        }
    }
    .auditoriums-navigation {
        .button {
            svg {
                transform: rotate(180deg);
            }
        }
        .button-container {
            .auditorium-name {
                left: initial;
                right: 48px;
            }
        }
        .next {
            .button-container {
                .auditorium-name {
                    left: 48px;
                    right: initial;
                    padding-inline-start: 0;
                }
            }
        }
    }
    .booth-wall-container .booth-wall-header .side-buttons-inner {
        flex-flow: row;
    }
    .booth-wall-container .booth-wall-header .side-button-container {
        margin-inline-start: 0;
        margin-inline-end: 4px;
        flex-flow: row-reverse;
        span {
            padding-inline-end: 0;
            padding-inline-start: 15px;
        }
    }
    .gdpr-container {
        direction: ltr;
    }
    .new-comment-container .tooltip-container {
        left: 15px;
        right: initial;
        .triangle {
            left: -8px;
            right: initial;
        }
    }
    .meeting-button-wrapper .tooltip-container {
        left: 12px;
        right: initial;
    }
    .auditorium-page .program-wrapper {
        padding-right: 0;
        padding-left: 10px;
    }
    .auditorium-page.dropdown-component .auditorium-name {
        text-align: right;
        padding-right: 10px;
        padding-left: 0;
    }
    .exhibitor-dashboard-page,
    .search-here {
        .MuiInputLabel-formControl {
            left: initial;
            right: 0;
            transform: translate(-14px, 20px) scale(1);
        }
    }
    .exhibitor-representative-dialog .search-here {
        display: flex;
        justify-content: flex-start;
    }
    .register-participant-step-1 {
        .form-container {
            .left-form {
                border-radius: 0 6px 6px 0;
            }
            .right-form {
                border-radius: 6px 0 0 6px;
            }
        }
    }
    .register-participant-step-2 {
        .form-container {
            .left-form {
                border-radius: 0 0 6px 0;
            }
            .right-form {
                border-radius: 0 0 0 6px;
                .register-form-wrapper {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 6px;
                    form {
                        .select-validator {
                            label {
                                transform: translate(0, 20px) scale(1);
                            }
                            .field-container {
                                & > div {
                                    & > div {
                                        text-align: right;
                                        padding-right: 12px;
                                        padding-left: 32px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .main-menu-container .main-header-wrapper > div {
        flex: initial;
    }
    .main-menu-container .main-header-wrapper #chats span > div {
        left: initial;
        right: 50px;

        @media screen and (min-width: 1025px) and (max-width: 1299px) {
            right: 35px;
        }
    }
    .menu-bottom-wrapper .main-menu-container .main-header-wrapper #chats span > div {
        right: 20px;
    }
    .recover-password-dialog .MuiFormHelperText-root,
    .change-password-dialog .MuiFormHelperText-root {
        text-align: start;
    }
    #info-tab .user-container {
        display: flex;
        flex-flow: row-reverse;
        .user-details {
            flex-flow: row;
        }
        .participant-avatar {
            margin-inline-start: 0;
            margin-inline-end: 10px;
        }
    }
    .auditoriums-navigation-mobile .button.previous {
        transform: rotate(0);
    }
    .auditoriums-navigation-mobile .button {
        transform: rotate(180deg);
    }
    .auditorium-page.dropdown-component .page-title .arrow {
        &.next {
            left: 0;
            right: initial;
            svg {
                transform: rotate(0);
            }
        }
        &.prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .create-meeting-form .hours-container {
        flex-flow: row-reverse;
    }
    .back-to-live-button {
        left: calc(-100vw + 353px + var(--sidebar));
    }
    .on-demand-video-page.has-live-button {
        .slot-title {
            padding-right: 383px;
            padding-left: 240px;
        }
    }
    .edit-section-container {
        input,
        fieldset,
        div.MuiInputBase-root,
        legend {
            direction: rtl !important;
        }
        form {
            .field-container,
            .setting-input {
                label {
                    left: initial;
                    transform: translate(-14px, 20px) scale(1);
                    transform-origin: top right;
                    &.MuiInputLabel-shrink {
                        transform: translate(-12px, -6px) scale(0.75);
                        background-color: #fff;
                    }
                }
                svg {
                    right: initial;
                    left: 7px;
                }
                & > div {
                    &.Mui-error {
                        ~ p {
                            text-align: right;
                        }
                    }
                }
                fieldset {
                    legend {
                        width: 0 !important;
                    }
                }
                .MuiSelect-selectMenu {
                    padding-right: 14px;
                    padding-left: 32px;
                }
                &.account-sdgs {
                    #select-sdgs {
                        padding-right: 14px;
                        padding-left: 40px;
                        .chips-wrapper {
                            & > div {
                                margin-left: 8px;
                                margin-right: 0;
                                svg {
                                    margin-right: 0;
                                    margin-left: 4px;
                                }
                            }
                        }
                    }
                }
                &.select-validator {
                    label {
                        &.filled {
                            transform: translate(-12px, -6px) scale(0.75);
                            background-color: #fff;
                            width: auto;
                            padding: 0;
                        }
                    }
                    .MuiSelect-selectMenu {
                        padding-top: 18px;
                        padding-bottom: 18px;
                    }
                }
            }
            .registration-radio-group {
                .MuiRadio-root {
                    margin-right: 0 !important;
                    margin-left: 12px;
                }
            }
            input {
                text-align: right;
            }
        }
    }
    #my-account-mobile .edit-participant-info-container button {
        left: 16px;
        right: auto;
    }
    .group-chat-wrapper .live-wall,
    .private-chat-wrapper .live-wall {
        direction: ltr;
    }
    #chat-messages-container .message {
        direction: rtl;
    }
    .videoWrapper {
        &.grey-bar {
            .video-container {
                .progress-bar {
                    transform: scaleX(-1);
                }
            }
        }
    }
    .scholar-container {
        & > div {
            .MuiOutlinedInput-adornedEnd {
                padding-right: 14px;
                padding-left: 14px;
            }

            legend {
                text-align: right;
            }
        }
        .actions-wrapper {
            &.authors {
                button {
                    right: initial;
                    left: 0;
                }
            }
        }
    }
    .booth-manage {
        .list-style {
            li {
                padding-left: 32px;
                padding-right: 16px;
                button {
                    right: initial;
                    left: 10px;
                    min-width: initial;
                }
            }
        }
    }
    .scroll-left-container {
        direction: ltr;

        & > div {
            direction: rtl;
        }
    }
    .participants-search-wrapper {
        &[data-empty='true'] {
            & > p {
                text-align: right;
            }
        }

        .scrollbar .box {
            margin-right: -17px !important;
            margin-left: 0 !important;
        }
    }
    .showfloors-container {
        &.collapsed {
            & > div {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .login-page {
        .email-wrapper,
        .password-wrapper {
            svg {
                right: initial;
                left: 16px;
            }
        }
    }
    .form-container {
        .right-form {
            &.is-sign-up {
                .register-form-wrapper {
                    border-bottom-left-radius: 6px;
                    .field-wrapper {
                        & > div {
                            &:last-of-type {
                                svg {
                                    right: initial;
                                    left: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .login-tab {
                border-bottom-left-radius: 6px;
                .login-page {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 6px;
                }
            }
        }
        .form-header {
            &.sign-up,
            &.log-in {
                border-top-left-radius: 6px;
                border-top-right-radius: 0;
            }
        }
    }
    .recover-wrapper {
        & > div {
            text-align: right;
            &:last-of-type {
                text-align: left;
            }
        }
    }
    .exhibitor-booth-view-page {
        .StatisticUsers__userListHolder {
            .scrollbar {
                direction: rtl;
                li {
                    direction: rtl;
                }
            }
        }
    }
    .comments-list-container {
        .comment-container {
            &.container-moderation {
                .delete-button {
                    right: initial;
                    left: 0;
                }
            }
        }
    }
    .position-background {
        &.lobby-screen {
            direction: ltr;
            .wall-and-networking-container {
                direction: rtl;
            }
        }
    }
    .showfloor-page {
        direction: ltr;
        .showfloors-wrapper-page {
            direction: rtl;
        }
    }
    .exhibitor-booth-view-page,
    .exhibitor-dashboard-page {
        direction: ltr;
        .exhibitor-wrapper {
            direction: rtl;
        }
    }
    @media screen and (max-width: 1024px) {
        .networking-container .user-container .user-details .social-links-container {
            margin-left: auto;
            margin-right: -50px;
        }
        .exhibitors-overlay-mobile {
            h4 {
                & > div {
                    &:first-of-type {
                        & > svg {
                            &.arrow-up {
                                transform-origin: 50% 50%;
                                margin-inline-end: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .bottom-row {
        direction: rtl;
        padding-right: 72px !important;
        padding-left: 5px !important;
    }
    .match-level {
        left: unset !important;
        margin-right: unset !important;
        right: 5.5px;
        
    }
    .exhibitor-representative-dialog .save-file:disabled, .edit-user-information .update-button:disabled {
        margin-inline-end: 10px;
    }
    .edit-user-information .buttons-actions .update-button {
        margin-right: 0;
        margin-left: 10px;
    }
    .advanced-program-container {
        text-align: right;
        direction: rtl;
    }
    .single-session-item {
        & > div {
            direction: rtl;
        }
        .sdgs-wrapper {
            direction: rtl;
        }
        .status-button {
            span {
                &:before {
                    left: initial;
                    right: 0;
                }
                padding-left: 0;
                padding-right: 16px;
            }
            &.live-button {
                span {
                    padding-right: 21px;
                    padding-left: 11px;
                    &:before {
                        right: 8px;
                    }
                }
            }
        }
    }
    .smart-suggestions {
        & > p {
            padding-left: 0;
            padding-right: 16px;
        }
    }
    .network-filter {
        &__title-details {
            p {
                flex: initial;
            }
        }
        .MuiAccordionSummary-root {
            padding-left: 0;
            padding-right: 12px;
        }
        label {
            margin: 0;
        }
    }
    .left-sidebar-show {
        .left-sidebar {
            left: 0;
        }
    }
    .edit-mode-container-comment {
        left: 12px;
    }
    .my-account-page {
        direction: ltr;
        .aside-account-wrapper {
            padding: 24px 0 21px 24px;
            .my-events {
                direction: rtl;
            }
        }
    }
    .auditorium-archive-page {
        direction: ltr;
        .program-dashboard {
            direction: rtl;
        }
    }
}
