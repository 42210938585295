@import '../../../CSS/variables.module';


.email-code-confirmation{
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 60px 0 32px;
    min-height: calc(100vh - 265px);
    display: flex;
    flex-flow: column;
    align-items: center;
    .confirmation-container{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        padding-bottom: 80px;
        .confirmation-container-text{
            display: block;
        }
        .email-container{
            padding-top: 24px;
            display: flex;
            .content-container{
                border: 1px solid $grey;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                padding: 14px 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                overflow: hidden;
                span{
                    padding-left: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .spacer{
                flex-grow: 1;
            }
        }
    }
    .code-container{
        width: 100%;
        .enter-code-label{
            margin: 0 0 24px;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.0385em;
            span{
                display: block;
            }
        }
        .expiration-timer{
            margin-top: 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }
    }
    .resend-container{
        margin-top: auto;
        padding-top: 40px;
        .resend-text{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }
        .resend-button-container{
            margin-top: 16px;
            font-size: 14px;
            line-height: 17px;
            button{
                width: 100%;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                border-radius: 6px;
                background-color: transparent;
                align-items: center;
                text-align: center;
                letter-spacing: 0.0125em;
                color: #fff;
                text-transform: uppercase;
                padding: 11px;
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.0125em;
                font-family: $fontFamily, sans-serif;
                max-width: 360px;
            }
        }
        &.expired{
            margin-top: 0;
            padding-top: 20px;
            width: 100%;
            .resend-text{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }
            .resend-button-container {
                margin-top: 13px;
                button {
                    background: linear-gradient(156.39deg, #6624D1 48.1%, #9321C8 105%);
                    border-radius: 8px 8px 8px 0;
                    padding: 12px;
                    border: none;
                    font-family: $fontFamily, sans-serif;
                }
            }
        }
    }
    .code-input{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 328px;
        margin: auto;
        input{
            width: 48px;
            height: 56px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #fff;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: $dark;
        }
    }
    .error-container{
        display: flex;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid $red;
        border-radius: 4px;
        padding: 8px 10px;
        color: $red;
        position: relative;
        max-width: 315px;
        margin: 7px auto 0;
        p{
            margin: 0;
            color: $dark;
            font-weight: 500;
            font-size: 14px;
            text-align: start;
            padding-left: 10px;
            span{
                display: block;
            }
        }
        .triangle{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $red transparent;
        }
    }
    &.has-error{
        .code-input input{
            border: 1px solid $red;
        }
    }
    .user-created-confirmation-container{
        display: flex;
        align-items: center;
        flex-flow: column;
        flex: 1;
        justify-content: space-evenly;
        svg{
            max-width: 100%;
        }
        .check-icon{
            font-size: 80px;
            color: $primary;
        }
    }

    @media (max-width: 767.9px){
        padding: 24px 0 16px;
        min-height: calc(100vh - 295px);
        .confirmation-container{
            padding-bottom: 24px;
            .email-container{
                padding-top: 16px;
            }
        }
        .resend-container{
            width: 100%;
        }
    }
    .error{
        color: $red;
    }
}