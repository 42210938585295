@import '../../../../CSS/variables.module';

.add-to-external-calendar-dialog{
    .content-wrapper{
        padding: 24px;
        position: relative;
        max-width: 560px;
        @media screen and (max-width: 1024px) {
            max-width: 320px;
        }
    }
    .close-icon{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0;
        padding: 8px;
        cursor: pointer;
        color: $grey-dark;
        z-index: 2;
    }
    .title{
        margin: 0 0 12px;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        padding-inline-end: 20px;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .success-message{
        display: flex;
        color: $grey-dark;
        align-items: center;
        font-size: 18px;
        line-height: 27px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            color: rgba(0,0,0,0.6)
        }
        .success-icon{
            padding: 0;
            padding-inline-start: 6px;
            padding-inline-end: 16px;
            font-size: 0;
            line-height: 0;
            @media screen and (max-width: 1024px) {
                padding-inline-end: 14px;
            }
            svg path{
                fill: $blue
            }
        }

    }
    .calendar-links{
        margin-top: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
        }
        p{
            margin: 0 0 10px;
        }
        ul{
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            @media screen and (max-width: 1024px) {
                justify-content: center;
            }
            li a{
                display: flex;
                flex-flow: column;
                align-items: center;
                margin: 0 17px;
                span{
                    padding-top: 4px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: $dark;
                    letter-spacing: -0.4px;
                }
            }
        }
    }
}
.lang-rtl .add-to-external-calendar-dialog{
    .close-icon{
        right: auto;
        left: 0;
    }
}