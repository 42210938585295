@import 'variables.module';

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body.no-scroll {
    overflow: hidden;
}
body.no-scroll.show-all-page {
    overflow: auto;
}
body.no-scroll .sections-wrapper {
    height: 0;
    overflow: hidden;
}
body.no-scroll.show-all-page .sections-wrapper {
    height: auto;
    overflow: initial;
}
.green-rectangle-background,
.purple-rectangle-background,
.white-rectangle-background,
.highlight-rectangle-background {
    background-repeat: no-repeat;
    background-size: cover;
}
.white-rectangle-background {
    color: $primary !important;
    padding: 3px 5px;
}
.platform-homepage header {
    background-color: transparent;
    z-index: -1;
    opacity: 0;
}
.show-all-page.platform-homepage header {
    opacity: 1;
    z-index: 99;
    transition: opacity 1.5s;
}
.platform-homepage .container {
    max-width: 1284px;
    padding: 0 30px;
    margin: 0 auto;
}
.fullscreen-slider-wrapper {
    height: 100%;
    width: 100%;
    background-color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fullscreen-slider-container {
    display: block;
    overflow: hidden;
    padding: 44px 16px;
    position: relative;
    max-height: 100%;
    width: 100%;
}
.fullscreen-slider-container .slick-arrow {
    display: none !important;
}
.fullscreen-slider-container .close-slider-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
}
.fullscreen-slider-wrapper img {
    max-width: 100%;
    height: auto;
}
.fullscreen-slider-wrapper p {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}
.fullscreen-slider-wrapper.landscape p {
    display: none;
}
.landscape .fullscreen-slider-container {
    padding: 16px 120px 40px;
}
.landscape .fullscreen-slider-container .slick-arrow {
    display: block !important;
}
.landscape .fullscreen-slider-container .close-slider-button {
    top: 16px;
    right: 76px;
}
.landscape .fullscreen-slider-container .arrow {
    display: flex;
}
.fullscreen-slider-container .slick-arrow:before {
    display: none;
}
.fullscreen-slider-container .slick-next {
    right: -120px;
}
.fullscreen-slider-container .slick-prev {
    left: -120px;
}
.fullscreen-slider-container .arrow {
    background: $secondary !important;
}
.platform-homepage .slick-dots li {
    margin: 0;
}

.platform-homepage .slick-dots li button:before {
    font-size: 8px;
    opacity: 1;
    color: $secondary;
}
.platform-homepage .slick-dots li.slick-active button:before {
    color: $primary;
    opacity: 1;
}
.testimonial-wrapper .single-testimonial {
    display: flex;
}
.testimonial-wrapper .testimonial-container {
    display: flex;
    position: relative;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid $disabled-text;
    padding: 50px 65px 50px 50px;
    background-color: #fff;
    width: 70%;
}
.testimonial-wrapper .testimonial-container svg path {
    fill: $disabled-text;
}
.testimonial-wrapper:hover .testimonial-container svg path {
    fill: $primary;
}
.testimonial-wrapper .testimonial-container p {
    margin: 0;
    padding-left: 30px;
}
.testimonial-wrapper .client-avatar {
    width: 80px;
    height: 80px;
    border: 1px solid #ffffff;
    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    border-radius: 50%;
}
.testimonial-wrapper .testimonial-container .client-avatar {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(100%);
}
.testimonial-wrapper .client-details .client-avatar {
    display: none;
}
.testimonial-wrapper:hover .testimonial-container .client-avatar {
    filter: none;
}
.testimonial-wrapper .client-details {
    padding-left: 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.testimonial-wrapper .client-details .client-name {
    font-size: 20px;
    line-height: 30px;
    color: $dark;
    margin: 0;
}
.testimonial-wrapper .client-details .client-position {
    color: $text-grey;
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0 0 0;
}
.container-aifg {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-width: 576px) {
        max-width: 540px;
    }
    @media screen and (min-width: 768px) {
        max-width: 720px;
    }
    @media screen and (min-width: 992px) {
        max-width: 960px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 1140px;
    }
    @media screen and (min-width: 1300px) {
        max-width: 1300px;
    }
}
@media (max-width: 1023.9px) {
    body.no-scroll {
        overflow: auto !important;
    }
    body.no-scroll .sections-wrapper {
        height: auto;
        overflow: initial;
    }
    .platform-homepage header {
        opacity: 1 !important;
        z-index: 99;
    }
    .platform-homepage .animated-top-section {
        display: none;
    }
    .platform-homepage .top-section {
        display: block;
    }
    .platform-homepage .container,
    .onvent-testimonials-slider .testimonial-wrapper {
        padding: 0 84px;
    }
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 72px;
    }
    .onvent-look-slider .presentation-container {
        flex-flow: column;
    }
    .onvent-look-slider .presentation-container h4 {
        width: 100%;
        padding-bottom: 16px;
        padding-right: 0;
    }
    .onvent-look-slider .presentation-container p {
        border-left: none;
        padding-left: 0;
    }
    section.onvent-video-presentation-title h3 {
        max-width: 560px;
    }
    .testimonial-wrapper .single-testimonial {
        flex-flow: column;
        background: #ffffff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 50px 32px 50px 50px;
    }
    .testimonial-wrapper .testimonial-container {
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        box-shadow: none;
    }
    .testimonial-wrapper .testimonial-container svg path {
        fill: $primary;
    }
    .testimonial-wrapper .testimonial-container .client-avatar {
        display: none;
    }
    .testimonial-wrapper .client-details {
        position: relative;
        padding-left: 156px;
        margin-top: 32px;
    }
    .testimonial-wrapper .client-details .client-avatar {
        display: block;
        position: absolute;
        left: 52px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    section.onvent-cards .cards-container .single-card-container {
        width: 100%;
    }
    section.onvent-cards .cards-container .single-card-container .card {
        max-width: 392px;
        margin: auto;
    }
}

@media (max-width: 767.9px) {
    .platform-homepage .top-section .section-heading {
        justify-content: flex-start;
    }
    .platform-homepage .top-section .section-heading h1 {
        padding-top: 175px;
    }
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 34px;
        line-height: 34px;
    }
    .platform-homepage .top-section .section-heading h2 {
        margin: 0;
    }
    .platform-homepage h3 {
        font-weight: normal;
    }
    .platform-homepage .container,
    .onvent-testimonials-slider .testimonial-wrapper {
        padding: 0 16px;
    }
    .platform-homepage .arrow {
        display: none;
    }
    .onvent-look-slider .slider-image-container,
    .onvent-look-slider .presentation-container,
    section.onvent-video-presentation .container {
        padding-left: 44px;
        padding-right: 44px;
    }
    .onvent-look-slider .slider-image-container .image-overlay {
        display: flex;
    }
    .onvent-look-slider .presentation-container h4 {
        font-size: 34px;
        line-height: 34px;
    }
    section.onvent-testimonials h3 {
        padding-bottom: 47px;
    }
    .onvent-testimonials-slider .testimonial-wrapper {
        padding-bottom: 50px;
    }
    .onvent-testimonials-slider {
        padding-bottom: 220px;
    }
    .testimonial-wrapper .single-testimonial {
        padding: 24px 24px 50px 24px;
    }
    .testimonial-wrapper .testimonial-container {
        flex-flow: column;
    }
    .testimonial-wrapper .testimonial-container p {
        padding-left: 0;
        padding-top: 24px;
    }
    .testimonial-wrapper .client-details {
        padding-left: 0;
    }
    .testimonial-wrapper .client-details .client-avatar {
        bottom: -130px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .onvent-testimonials-slider .slick-dots {
        bottom: -50px;
    }
    .platform-footer-wrapper {
        padding-bottom: 120px;
    }
    .platform-footer-container .inner-links ul {
        flex-flow: column;
    }
    .platform-footer-container .inner-links ul li {
        padding: 12px 0;
    }
}
.read-more-platform {
    display: flex;
    justify-content: center;
    a {
        background-color: $white;
        border-radius: 30px;
        border: 2px solid $primary;
        color: $secondary;
        font-size: 16px;
        text-transform: uppercase;
        padding: 7px 20px 3px;
        line-height: 22px;
    }
}
@media (max-width: 349.9px) {
    .platform-homepage .top-section .section-heading h1,
    .platform-homepage .top-section .section-heading h2 {
        font-size: 30px;
    }
}
@media screen and (orientation: landscape) {
}
