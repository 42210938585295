@import '../../../CSS/variables.module';

.capture-controls-bar-wrapper{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-inline-end: 323px;
    font-size: 14px;
    text-align: left;
    z-index: 10;
    @media screen and (max-height: 859px) {
        padding-inline-end: 22%;
    }
    button{
        cursor: pointer;
    }
    .info-container{
        background-color: $secondary;
        color: #fff;
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding: 3px 16px;
        border-top: 1px solid $grey;
        @media screen and (max-height: 859px) {
            position: relative;
            z-index: 10;
        }
        p{
            margin: 0;
        }
    }
    .one-row-banner {
        min-height: 100px;
        padding: 16px;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        background-color: $secondary;
        color: #fff;
        border-top: 1px solid $grey;
        p{
            margin: 0;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.005em;
            &.title{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.0015em;
                margin-bottom: 2px;
            }
        }
        .buttons-container{
            justify-self: flex-end;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            padding: 0;
            button{
                background-color: #F5F5F5;
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                min-height: 52px;
                padding-left: 22px;
                padding-right: 22px;
                svg{
                    font-size: 20px;
                }
            }
        }
        @media screen and (max-width: 1365px) {
            min-height: 50px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    .actions-container{
        min-height: 100px;
        padding: 16px;
        background-color: #fff;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-height: 859px) {
            position: relative;
            z-index: 10;
        }

        .buttons-container{
            justify-self: flex-end;
            display: flex;
            justify-content: flex-end;
            padding: 0;
        }

        @media screen and (max-width: 1365px) {
            min-height: 50px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .personal-stream-controls{
        display: flex;
        align-items: center;
        button{
            padding: 14px 12px;
            background-color: $white-variant;
            color: $dark;
            border: 1px solid $dark;
            border-radius: 6px;
            margin-right: 12px;
            cursor: pointer;
            font-family: $fontFamily, sans-serif;
            font-weight: 600;
            font-size: 14px;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    padding-left: 12px;
                    text-transform: uppercase;
                }
            }
            &:disabled{
                background-color: #c0c0c0;
                cursor: initial;
            }
            &.is-sharing{
                border: 1px solid $secondary;
                background-color: $secondary;
                color: #fff;
                svg path{
                    fill: #fff;
                }
            }
            @media screen and (max-width: 1365px) {
                padding: 7px 6px;
            }
        }
    }

    .personal-details-container{
        padding-left: 12px;
        border-left: 1px solid #EFEEF1;
        display: flex;
        flex: 1;
        @media screen and (max-width: 1450px) {
            display: none;
        }
        .session-video-avatar{
            width: 54px;
            height: 54px;
            flex: 0 0 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #777081;
            color: #fff;
            margin-inline-end: 12px;
            span{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }
            @media screen and (max-width: 1365px) {
                width: 36px;
                height: 36px;
                flex: 0 0 36px;
                span{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        .personal-details{
            .name{
                font-size: 16px;
                line-height: 26px;
                color: $dark
            }
            .mic-input-volume{
                max-width: 110px;
            }
        }
    }

    .buttons-container{
        button{
            border: 1px solid #CACBD0;
            border-radius: 6px;
            margin-left: 12px;
            background-color: #fff;
            text-transform: uppercase;
            padding: 14px 12px;
            font-weight: 600;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $fontFamily, sans-serif;
            svg{
                margin-right: 8px;
            }
            span{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $black;
            }
            &.button-cancel{
                span {
                    color: $red;
                }
            }
            &.button-leave-session{
                background-color: $red;
                span {
                    color: $white;
                }
                &.not-ready{
                    background-color: #fff;
                    color: $red;
                    span{
                        color: $red;
                    }
                }

            }
            &.button-ready{
                background-color: $primary;
                span {
                    color: $white;
                }
            }
            @media screen and (max-width: 1365px) {
                padding: 8px 6px;
            }
        }
    }
}